import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { deepCopy } from "app/util"
import AmountEditable from "components/AmountEditable"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import validate from "./validate"


const EditAccount = ({open, cancel, submit, title, account: inputAccount}) => {
  const theme = useTheme()
  const [account, updateAccount] = useState(deepCopy(inputAccount))
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(()=>{
    updateAccount({
      ...deepCopy(inputAccount)
    })
  }, [inputAccount, updateAccount])

  const submitHandler = (values, { resetForm, setSubmitting, setErrors }) => {
    const submitValue = {
      id: values.id,
      name: values.name,
      startingBalance: (inputAccount.startingBalance !== values.startingBalance) ? values.startingBalance : undefined,
      buffer: (inputAccount.buffer !== values.buffer) ? values.buffer : undefined,
      defaultAccount: values.defaultAccount,
      active: values.active,
      type: values.type,
    }

    submit(submitValue).then(() => {
      resetForm()
    }).catch(ex => {
      setSubmitting(false)
      setErrors({"global": ex})
    })
  }

  return (
<Formik
  initialValues={account}
  enableReinitialize
  validate={validate}
  onSubmit={submitHandler}
>
  {({values, errors, touched, isSubmitting, isValid, setFieldValue, setFieldError, setFieldTouched, resetForm, handleSubmit, handleChange, handleBlur}) => (
    <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
      <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <FormControl color="secondary" margin="normal">
              <InputLabel id="account-label">Type</InputLabel>
              <Select
                name="type"
                labelId="type-label"
                disabled={isSubmitting || values.defaultAccount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.type && touched.type}
                value={values.type}>
                <MenuItem value={"SPENDING"}>Checking/Spending</MenuItem>
                <MenuItem value={"SAVINGS"}>Savings</MenuItem>
                <MenuItem value={"CREDIT"}>Credit Card</MenuItem>
                <MenuItem value={"UNCLASSIFIED"}>Other (Money Market, etc.)</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box mb={2}>
            <TextField
              name="name"
              label="Name"
              color="secondary"
              fullWidth
              margin="normal"
              disabled={isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name}
              helperText={touched.name && errors.name}
            />
          </Box>
          <Box mb={2} width="100%">
            <AmountEditable
              label="Starting Balance"
              emptyStart
              amount={values.startingBalance}
              margin="normal"
              disabled={isSubmitting}
              style={{width: theme.spacing(20)}}
              onBlur={() => setFieldTouched("startingBalance")}
              onChange={x => { 
                setFieldValue("startingBalance", x)
                setTimeout(() => setFieldTouched("startingBalance"), 2)
              }}
              error={errors.startingBalance && touched.startingBalance}
              helperText={touched.startingBalance && errors.startingBalance}
            />
          </Box>

          {values.type === "SPENDING" &&
            <Box mb={2} width="100%">
              <AmountEditable
                label="Buffer"
                emptyStart
                amount={values.buffer}
                margin="normal"
                disabled={isSubmitting}
                style={{width: theme.spacing(20)}}
                onBlur={() => setFieldTouched("buffer")}
                onChange={x => { 
                  setFieldValue("buffer", x)
                  setTimeout(() => setFieldTouched("buffer"), 2)
                }}
                error={errors.buffer && touched.buffer}
                helperText={touched.buffer && errors.buffer}
              />
            </Box>
          }

          <Box hidden={!errors.global || (errors.global === "")} >
            <Alert severity="error" onClose={() => setFieldError("global", null)}>
              {errors.global}
            </Alert>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box m={{xs: 2, md:2}} mb={{xs:8,md:2}}>
          <Button onClick={() => {resetForm(); cancel()}} color="secondary">
            Cancel 
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting || !isValid}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )}
</Formik>
)}

export default EditAccount
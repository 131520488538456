export const trackingQuery = `query transactions($payPeriod: String, $account: String){
  transactions(payPeriod: $payPeriod, account: $account) {
    avatars {
      enteredBy
      avatar
      alt
      fullName
    }
    transactions {
      id
      payPeriod
      account
      date
      description
      amount
      amounts {
        category
      }
      direction
      cleared
      tags
      enteredBy
    }
  }
}`

export const updateClearedMutation = `mutation updateTransactionCleared($input: UpdateTransactionInput!) {
  updateTransaction(input: $input) {
    id
    payPeriod
    account
    cleared
  }
}`

export const updateTransactionMutation = `mutation updateTransaction($input: UpdateTransactionInput!) {
  updateTransaction(input: $input) {
    id
    payPeriod
    account
    date
    description
    amount
    amounts {
      category
    }
    direction
    cleared
    tags
  }
}`

export const removeMutation = `mutation removeTransaction($id: String!) {
  removeTransaction(id: $id)
}`

export const removeMutationBulk = `mutation removeTransactionBulk($ids: [String!]!) {
  removeTransactionBulk(ids: $ids)
}`

export const queryTransaction = `query transaction($id: String!){
  accounts {
    name
    type
  }
  payPeriods {
    name
    isCurrent
  }
  typeAhead {
    description
    categories
    tags
    direction
  }
  categories {
    name
    categories {
      name
    }
  }
  settings {
  	tags
  }
  transaction(id: $id) {
    id
    payPeriod
    account
    date
    description
    amount
    amounts {
      category
      amount
    }
    direction
    cleared
    tags
  }
}`

import { firstBy } from "thenby"

const DEFAULT_STATE = {
  products: [],
  error: "",
  snack: { type: "", message: "" }  
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "PRODUCTS_FULFILLED": {
      return {
        ...state,
        products: action.payload.products.productList.sort(firstBy("ordinal")),
        subscriptionStatus: action.payload.products.subscriptionStatus,
        error: ""
      }
    }
    case "PRODUCTS_REJECTED": {
      return {
        ...state,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const SmallAvatar = withStyles(() => ({
  root: {
    width: 32,
    height: 32,
  },
}))(Avatar);

const BadgeAvatars = ({ alt, loginAvatar, profileAvatar }) => {
  if (!profileAvatar) {
    return <Avatar alt={alt} src={loginAvatar} />;
  } else {
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={<SmallAvatar alt={alt} src={profileAvatar} />}
      >
        <Avatar alt={alt} src={loginAvatar} />
      </Badge>
    );
  }
};

export default BadgeAvatars;

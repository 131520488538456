import { reducer as snackReducer } from "components/SnackMessage";

const DEFAULT_STATE = {
  settings: {
    avatar: "/images/blank-avatar.jpeg",
    budgetFrequency: "MONTHLY",
    defaultSavingsCategory: "",
    complete: true,
    tags: [],
  },
  settingsError: null,
  sharing: [],
  sharingError: null,
  profiles: [],
  selectedProfile: null,
  returnPage: "/",
  categories: [],
  sharingReloading: false,
  snack: { type: "", message: "" },
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SELECT_PROFILE": {
      return {
        ...state,
        selectedProfile: action.payload
      }
    }
    case "RETURN_PAGE": {
      return {
        ...state,
        returnPage: action.payload,
      };
    }
    case "AVATAR_SETTINGS_FULFILLED": {
      return {
        ...state,
        avatar: action?.payload?.data,
      };
    }
    case "SHARING_PENDING": {
      return {
        ...state,
        sharing: action?.payload?.sharing.shared,
        sharingError: null,
        sharingReloading: true
      };
    }
    case "SHARING_FULFILLED": {
      return {
        ...state,
        sharing: action?.payload?.sharing.shared,
        sharingError: null,
        sharingReloading: false
      };
    }
    case "SHARING_REJECTED": {
      return {
        ...state,
        sharing: [],
        sharingError: action?.payload,
        sharingReloading: false
      };
    }
    case "CREATE_SHARING_REQUEST_PENDING": {
      return {
        ...state,
        sharingReloading: true,
      }
    }
    case "CREATE_SHARING_REQUEST_FULFILLED": {
      return {
        ...state,
        sharing: action?.payload?.createSharingRequest,
        sharingReloading: false,
        snack: {
          type: "success",
          message: "Complete",
        },
      };
    }
    case "CREATE_SHARING_REQUEST_REJECTED": {
      return {
        ...state,
        sharingReloading: false
      };
    }
    case "REMOVE_SHARING_PENDING": {
      return {
        ...state,
        sharingReloading: true,
      }
    }
    case "REMOVE_SHARING_FULFILLED": {
      return {
        ...state,
        sharing: action?.payload?.removeSharing || action?.payload?.removeSharingRequest,
        sharingReloading: false,
        snack: {
          type: "success",
          message: "Complete",
        },
      };
    }
    case "REMOVE_SHARING_REJECTED": {
      return {
        ...state,
        sharingReloading: false,
        snack: {
          type: "error",
          message: "Unable to remove sharing",
        },
      };
    }
    case "SETTINGS_FULFILLED": {
      return {
        ...state,
        settings: action?.payload?.settings,
        settingsError: null,
        categories: action?.payload?.categories,
        setup: action?.payload?.setup,
        profiles: action?.payload?.profiles?.myProfiles || []
      };
    }
    case "SETTINGS_REJECTED": {
      return {
        ...state,
        settingsError: action?.payload
      };
    }
    default:
      return snackReducer(state, action);
  }
};

import { Box, Container, useTheme } from "@material-ui/core";
import React from "react";

import Sidebar from "components/Sidebar";

import Navigation from "./Navigation";

const SidebarLayout = ({
  title,
  children,
  addPlannedEvent,
  addSavingsGoal,
  addSelectedEvents,
  showAddSelectedEvents,
}) => {
  const theme = useTheme();

  return (
    <Navigation title={title}>
      <Container disableGutters maxWidth="lg">
        <Box display="flex" mt={{ xs: 8, md: 11 }} flexWrap={{ xs: "wrap", md: "nowrap" }}>
          <Box minWidth={{ xs: "100%", md: theme.spacing(50) }}>
            <Box
              width={{ xs: "100%", md: theme.spacing(40) }}
              position={{ xs: "inherit", md: "fixed" }}
              ml={{ xs: 0, md: 3 }}
            >
              <Sidebar
                addPlannedEvent={addPlannedEvent}
                addSavingsGoal={addSavingsGoal}
                addSelectedEvents={addSelectedEvents}
                showAddSelectedEvents={showAddSelectedEvents}
              />
            </Box>
          </Box>
          <Box width="100%" flexGrow={1} mt={{ xs: 3, md: 0 }} mb={3} mr={{ xs: 0, md: 3 }}>
            {children}
          </Box>
        </Box>
      </Container>
    </Navigation>
  );
};

export default SidebarLayout;

import { validPassword } from "@budgetocity-internal/validation-library";
import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { goBack } from "app/util";
import { changePassword } from "auth";

const ManagePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => ({ ...state.stateManager.settings, ...state.settings }));

  const submitPasswordChange = (values, { resetForm, setSubmitting, setErrors }) => {
    changePassword(values.currentPassword, values.newPassword)
      .then(() => {
        resetForm();
        dispatch({ type: "SET_SNACK", success: "Complete" });
        goBack(history, state.returnPage);
      })
      .catch((ex) => {
        setSubmitting(false);
        setErrors({ global: ex?.response?.data?.message || ex?.message || "Failed to update password" });
      });
  };

  const validatePasswordChange = (values) => {
    var errors = {};
    const currentPasswordCheck = validPassword(values.currentPassword);
    const newPasswordCheck = validPassword(values.newPassword);
    if (!currentPasswordCheck.passed) {
      errors["currentPassword"] = currentPasswordCheck.reason;
    }

    if (!newPasswordCheck.passed) {
      errors["newPassword"] = newPasswordCheck.reason;
    }

    if (values.newPassword !== values.checkPassword) {
      errors["checkPassword"] = "Please ensure passwords match";
    }

    return errors;
  };

  return (
    <Paper>
      <Formik
        initialValues={{ currentPassword: "", newPassword: "", checkPassword: "" }}
        validate={validatePasswordChange}
        onSubmit={submitPasswordChange}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          resetForm,
          setFieldError,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Box mx={8} mb={3} display="flex" flexDirection="column" justifyContent="center">
            <Box mt={3}>
              <Typography variant="h5">Change Password</Typography>
            </Box>
            <Box mx="auto" mt={1} mb={3}>
              <TextField
                fullWidth
                type="password"
                margin="normal"
                name="currentPassword"
                label="Current Password"
                value={values.currentPassword}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                error={errors.currentPassword && touched.currentPassword}
                helperText={touched.currentPassword && errors.currentPassword}
              />
              <TextField
                fullWidth
                type="password"
                margin="normal"
                name="newPassword"
                label="New Password"
                value={values.newPassword}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                error={errors.newPassword && touched.newPassword}
                helperText={touched.newPassword && errors.newPassword}
              />
              <TextField
                fullWidth
                type="password"
                margin="normal"
                name="checkPassword"
                label="Re-type New Password"
                value={values.checkPassword}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                error={errors.checkPassword && touched.checkPassword}
                helperText={touched.checkPassword && errors.checkPassword}
              />
            </Box>

            <Box hidden={!errors.global || errors.global === ""}>
              <Alert severity="error" variant="filled" onClose={() => setFieldError("global", "")}>
                {errors.global}
              </Alert>
            </Box>

            <Box ml="auto" mt={3} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  goBack(history, state.returnPage);
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting}>
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </Paper>
  );
};

export default ManagePassword;

import { Box, Typography } from "@material-ui/core"
import { grey } from '@material-ui/core/colors'
import React from "react"
import ReactMinimalPieChart from "react-minimal-pie-chart"
import styled from "styled-components"

import { defaultGreen, defaultRed, defaultYellow } from 'components/Theme'

import Amount from "./Amount"

//import { formatAmount } from "./Amount"

const InnerText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 40%;
  text-align: center;
`

const DonutChart = ({category, amount, remaining, percentage}) => {
  var itemColor = defaultRed
  if (amount === 0) {
    itemColor = grey[600]
  } else if (percentage > 30) {
    itemColor = defaultGreen
  } else if (percentage > 10) {
    itemColor = defaultYellow
  }

  //const displayAmount = formatAmount(amount)

  return (
    <Box width="150px" mx={2} mb={3}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          <Typography variant="h6" align="center">{category}</Typography>
        </Box>
        <Box m={1}>
          <ReactMinimalPieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            data={[{
              label: "Remaining",
              value: 100,
              color: itemColor
            }]}
            reveal={percentage > 0 ? percentage : 100}
            background={grey[600]}
            label={false}
            totalValue={100}
            lineWidth={30}
            startAngle={270}
            paddingAngle={0}
            radius={50}
            style={{position: "relative"}}
            cx={50}
            cy={50}
            viewBoxSize={[100, 100]}>
            <InnerText>
              <Box><Amount amount={remaining}/></Box>
              <Box>{percentage}%</Box>
            </InnerText>
          </ReactMinimalPieChart>
        </Box>
        <Box>
          <Typography align="center" variant="body1" component="div">...out of <Amount amount={amount}/></Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default DonutChart
import { reducer as snackReducer } from "components/SnackMessage";

import filterAndSortTransactions from "./filterAndSortTransactions";

const DEFAULT_STATE = {
  rawTransactions: [],
  transactions: [],
  filteredAmount: 0,
  filteredCount: 0,
  pendingFilteredAmount: 0,
  pendingFilteredCount: 0,
  filter: "",
  avatars: {},
  error: "",
  snack: { type: "", message: "" },
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "TRACKING_FULFILLED": {
      const avatars = action?.payload?.transactions?.avatars || []
      return {
        ...state,
        accounts: action?.payload?.accounts,
        payPeriods: action?.payload?.payPeriods,
        avatars: avatars.reduce((p,c) => ({...p, [c.enteredBy]: c}), {}),
        rawTransactions: action?.payload?.transactions?.transactions,
        ...filterAndSortTransactions(action?.payload?.transactions?.transactions, state.filter),
      };
    }
    case "TRACKING_REJECTED": {
      return {
        ...state,
        error: action?.payload,
      };
    }
    case "UPDATE_TRACKING_FULFILLED": {
      const update = action?.payload?.updateTransaction;

      const rawTransactions = state.transactions
        .filter((t) => {
          if (t.id === update.id) {
            return t.payPeriod === update.payPeriod && t.account === update.account;
          }
          return true;
        })
        .map((t) => {
          if (t.id === update.id) {
            return {
              ...t,
              ...update,
            };
          }
          return t;
        });

      return {
        ...state,
        rawTransactions,
        ...filterAndSortTransactions(rawTransactions, state.filter),
      };
    }
    case "UPDATE_TRACKING_REJECTED":
    case "REMOVE_TRACKING_REJECTED":
    case "REMOVEBULK_TRACKING_REJECTED": {
      return {
        ...state,
        snack: { type: "error", message: action?.payload },
      };
    }
    case "REMOVE_TRACKING_FULFILLED": {
      const id = action?.payload?.removeTransaction;
      const rawTransactions = state.rawTransactions.filter((t) => t.id !== id);

      return {
        ...state,
        rawTransactions,
        ...filterAndSortTransactions(rawTransactions, state.filter),
      };
    }
    case "REMOVEBULK_TRACKING_FULFILLED": {
      const ids = action?.payload?.removeTransactionBulk;
      const rawTransactions = state.rawTransactions.filter((t) => !ids.includes(t.id));

      return {
        ...state,
        rawTransactions,
        ...filterAndSortTransactions(rawTransactions, state.filter),
      };
    }
    case "NEW_FILTER": {
      return {
        ...state,
        ...filterAndSortTransactions(state.rawTransactions, action.payload),
        filter: action.payload,
      };
    }
    default:
      return snackReducer(state, action);
  }
};

import { reducer as snackReducer } from "components/SnackMessage";

const DEFAULT_STATE = {
  myProfiles: [],
  otherProfiles: [],
  updateLoading: false,
  returnPage: "/",
  error: "",
  snack: { type: "", message: "" },
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "RETURN_PAGE": {
      return {
        ...state,
        returnPage: action.payload,
      };
    }
    case "PROFILES_FULFILLED": {
      return {
        ...state,
        ...action.payload?.profiles,
        error: "",
      };
    }
    case "PROFILES_REJECTED": {
      return {
        ...state,
        error: action.payload,
      };
    }
    case "REMOVE_SHARING_WITH_ME_PENDING": {
      return {
        ...state,
        updateLoading: true,
      }
    }
    case "REMOVE_SHARING_WITH_ME_FULFILLED": {
      return {
        ...state,
        updateLoading: false,
      }
    }
    case "REMOVE_SHARING_WITH_ME_REJECTED": {
      return {
        ...state,
        updateLoading: false,
        snack: {
          type: "error",
          message: "Unable to remove sharing",
        },
      };
    }
    case "UPDATE_PROFILE_SHORTCUT_PENDING": {
      return {
        ...state,
        updateLoading: true,
      }
    }
    case "UPDATE_PROFILE_SHORTCUT_FULFILLED": {
      return {
        ...state,
        updateLoading: false,
        otherProfiles: state.otherProfiles.map((p) => {
          if (p.profile === action.payload?.updateProfileShortCut?.profile) {
            return {
              ...p,
              shortCut: action.payload?.updateProfileShortCut?.shortCut,
            };
          }
          return p;
        }),
        snack: {
          type: "success",
          message: "Complete",
        },
      };
    }
    case "UPDATE_PROFILE_SHORTCUT_REJECTED": {
      return {
        ...state,
        updateLoading: false,
        snack: {
          type: "error",
          message: "Unable to update favorite",
        },
      };
    }
    default:
      return snackReducer(state, action);
  }
};

import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ChevronLeft,
  EventAvailableOutlined,
  MonetizationOnOutlined,
  PieChartOutlined,
  ReceiptOutlined,
  SearchOutlined,
} from "@material-ui/icons";
import { baseUrl } from "app/api";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  const drawerOpenWidth = theme.spacing(32);
  const drawerClosedWidth = theme.spacing(8);
  return {
    drawerOpened: {
      width: drawerOpenWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClosed: {
      width: drawerClosedWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
  };
});

const MenuNavItem = ({ text, to, icon }) => {
  const fontWeight = to === window.location.pathname ? "bolder" : "light";
  return (
    <ListItem button key={text} component={NavLink} to={to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ style: { fontWeight } }} />
    </ListItem>
  );
};

const NavSidebar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const header = useSelector((state) => state.header);
  const smallFormat = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumFormat = useMediaQuery(theme.breakpoints.down("md"));
  const largeFormat = useMediaQuery(theme.breakpoints.up("lg"));

  const sidebarWidth = !mediumFormat && header.sidebarOpen ? theme.spacing(32) : theme.spacing(8);
  const variant = largeFormat || (!smallFormat && !header.sidebarOpen) ? "permanent" : "temporary";

  return (
    <Box minWidth={smallFormat ? 0 : sidebarWidth}>
      <SwipeableDrawer
        open={!smallFormat || header.sidebarOpen}
        variant={variant}
        anchor="left"
        onOpen={() => dispatch({ type: "OPEN_NAV_SIDEBAR" })}
        onClose={() => dispatch({ type: "CLOSE_NAV_SIDEBAR" })}
      >
        <List className={header.sidebarOpen ? classes.drawerOpened : classes.drawerClosed}>
          <ListItem key="budgetocity" style={{ height: theme.spacing(7) }} button component="a" href={baseUrl.web}>
            <ListItemAvatar>
              <Avatar alt="Budgetocity™" src="/images/budgetocity-circle-tm-40.png" variant="rounded" />
            </ListItemAvatar>
            <ListItemText primary="Budgetocity™" />
            <ListItemSecondaryAction>
              <Box hidden={largeFormat || !header.sidebarOpen}>
                <IconButton
                  edge="end"
                  aria-label="Collapse Sidebar"
                  onClick={() => dispatch({ type: "TOGGLE_NAV_SIDEBAR" })}
                >
                  <ChevronLeft />
                </IconButton>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <MenuNavItem
            text="Summary"
            to="/"
            icon={
              <Tooltip title="Summary" placement="right" arrow disableHoverListener={header.sidebarOpen}>
                <PieChartOutlined />
              </Tooltip>
            }
          />
          <MenuNavItem
            text="Planner"
            to="/planner"
            icon={
              <Tooltip title="Planner" placement="right" arrow disableHoverListener={header.sidebarOpen}>
                <EventAvailableOutlined />
              </Tooltip>
            }
          />
          <MenuNavItem
            text="Budget"
            to="/budget"
            icon={
              <Tooltip title="Summary" placement="right" arrow disableHoverListener={header.sidebarOpen}>
                <MonetizationOnOutlined />
              </Tooltip>
            }
          />
          <MenuNavItem
            text="Tracking"
            to="/tracking"
            icon={
              <Tooltip title="Tracking" placement="right" arrow disableHoverListener={header.sidebarOpen}>
                <ReceiptOutlined />
              </Tooltip>
            }
          />
          {header.showSavings && (
            <MenuNavItem
              text="Savings"
              to="/savings"
              icon={
                <Tooltip title="Savings" placement="right" arrow disableHoverListener={header.sidebarOpen}>
                  <span>
                    <FontAwesomeIcon icon={faPiggyBank} size="lg" />
                  </span>
                </Tooltip>
              }
            />
          )}
          <MenuNavItem
            text="Search"
            to="/search"
            icon={
              <Tooltip title="Search" placement="right" arrow disableHoverListener={header.sidebarOpen}>
                <SearchOutlined />
              </Tooltip>
            }
          />
          {/*<MenuNavItem text="Import" to="/import" icon={<ImportExportOutlined/>} />*/}
        </List>
      </SwipeableDrawer>
    </Box>
  );
};

export default NavSidebar;

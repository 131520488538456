import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uid from "tiny-uid";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  Add,
  AddCircle,
  ArrowDropDown,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  Delete,
  DoneAll,
  Edit,
} from "@material-ui/icons";
import { Alert, Autocomplete, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Formik } from "formik";
import PopupState, { bindMenu, bindPopover, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@material-ui/pickers";

import { query } from "app/api";
import { useAuthorization } from "auth";
import Amount from "components/Amount";
import AmountEditable from "components/AmountEditable";
import Confirmation from "components/Confirmation";
import { EMPTY_TRANSACTION } from "components/Sidebar";
import { newTransactionMutation, newTransactionQuery, updateAccountBalances } from "components/Sidebar/queries";
import SingleLayout from "components/SingleLayout";
import SnackMessage from "components/SnackMessage";
import EditTransaction from "pages/product/Tracking/EditTransaction";

import { updateSavingsBalances } from "../Savings/queries";
import {
  queryTransaction,
  removeMutation,
  removeMutationBulk,
  updateClearedMutation,
  updateTransactionMutation,
} from "../Tracking/queries";
import {
  searchCriteriaQuery,
  searchCriteriaQueryWithLink,
  searchQuery,
  searchQueryWithLink,
  tagTransactions,
  untagTransactions,
} from "./queries";

const Search = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const auth = useSelector((state) => state.auth);
  const payPeriod = useSelector((state) => state.auth.payPeriod);
  const account = useSelector((state) => state.auth.account);

  const accountLinkFeatureAvailable = useSelector((state) => state.auth?.features?.accountLink);
  const accountLinkAuthorizationAvailable = useAuthorization("AccountLink", "Partner");
  const accountLinkAvailable = accountLinkFeatureAvailable && accountLinkAuthorizationAvailable;

  const accountLinkSearchAvailable = useSelector((state) => state.searchcriteria?.accountLinkAvailable);

  const balanceType = useSelector((state) => state.searchresults?.balanceType);

  const searchCriteria = useSelector((state) => ({
    ...state.stateManager.searchcriteria,
    ...state.searchcriteria,
    criteria: {
      ...state.searchcriteria.criteria,
      account: state?.searchcriteria?.criteria?.account || account,
    },
  }));

  const searchResults = useSelector((state) => ({
    ...state.stateManager.searchresults,
    ...state.searchresults,
  }));

  const updateTags = (accountId, setFieldValue) => {
    const tags = searchCriteria?.accountLinks?.find((a) => a.id === accountId)?.tags;
    setFieldValue("tags", tags);
  };

  // @TODO Validate search criteria
  const validate = () => {};

  useEffect(() => {
    if (!searchCriteria.isFetching && searchCriteria.isDirty) {
      dispatch({
        type: "SEARCHCRITERIA",
        payload: query(auth, accountLinkAvailable ? searchCriteriaQueryWithLink : searchCriteriaQuery),
      });
    }
  }, [dispatch, auth, searchCriteria, accountLinkAvailable]);

  const issueSearch = useCallback(
    (values) => {
      const input = values ? values : searchCriteria.criteria;

      dispatch({
        type: "SEARCHRESULTS",
        payload: query(auth, accountLinkSearchAvailable ? searchQueryWithLink : searchQuery, {
          ...input,
          account: input.account === "" ? null : input.account,
          accountLinkId: (accountLinkSearchAvailable && input.accountLink) || null,
          category: input.category === "" ? null : input.category,
          direction: input.direction === "" ? null : input.direction,
          cleared: input.cleared === "" ? null : input.cleared === "TRUE" ? true : false,
        }),
      });
    },
    [auth, dispatch, searchCriteria.criteria, accountLinkSearchAvailable]
  );

  useEffect(() => {
    if (searchResults.count > 0 && !searchResults.isFetching && searchResults.isDirty) {
      issueSearch();
    }
  }, [issueSearch, searchResults.count, searchResults.isFetching, searchResults.isDirty]);

  const updateSearch = (values) => {
    dispatch({ type: "SEARCHCRITERIA_UPDATE", payload: values });
    issueSearch(values);
  };

  const SearchCriteria = () => (
    <Formik initialValues={searchCriteria.criteria} validate={validate} onSubmit={updateSearch} enableReinitialize>
      {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Paper elevation={3}>
          <Box p={3}>
            <Typography gutterBottom variant="h5">
              Search
            </Typography>

            <Box display="flex" mt={2} mb={2}>
              <FormControl fullWidth>
                <InputLabel shrink id="label-account">
                  Account
                </InputLabel>
                <Select
                  name="account"
                  labelId="label-account"
                  displayEmpty
                  onChange={handleChange}
                  disabled={isSubmitting}
                  onBlur={handleBlur}
                  value={values.account}
                >
                  <MenuItem value={""}>Any Account</MenuItem>
                  {searchCriteria.accounts.map((a, i) => (
                    <MenuItem key={`account-${i}`} value={a}>
                      {a}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {accountLinkAvailable && (
              <Box display="flex" mb={2}>
                <FormControl fullWidth>
                  <InputLabel shrink id="label-account-link">
                    Account Link
                  </InputLabel>
                  <Select
                    name="accountLink"
                    labelId="label-account-link"
                    displayEmpty
                    onChange={(e) => {
                      setFieldValue("accountLink", e.target.value);
                      updateTags(e.target.value, setFieldValue);
                    }}
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    value={values.accountLink}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {searchCriteria.accountLinks.map((a, i) => (
                      <MenuItem key={`account-link-${i}`} value={a.id}>
                        {a.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            <Box display="flex" mb={2}>
              <DatePicker
                label="Start Date"
                value={values.startDate}
                format={"MMM DD, YYYY"}
                margin="dense"
                fullWidth
                autoOk
                onChange={(date) => {
                  setFieldValue("startDate", date.format("YYYY-MM-DD"));
                }}
                variant="inline"
                disabled={isSubmitting}
                error={errors.startDate && touched.startDate}
                helperText={touched.startDate && errors.startDate}
              />
            </Box>

            <Box display="flex" mb={2}>
              <DatePicker
                label="End Date"
                value={values.endDate}
                format={"MMM DD, YYYY"}
                margin="dense"
                fullWidth
                autoOk
                onChange={(date) => {
                  setFieldValue("endDate", date.format("YYYY-MM-DD"));
                }}
                variant="inline"
                disabled={isSubmitting}
                error={errors.endDate && touched.endDate}
                helperText={touched.endDate && errors.endDate}
              />
            </Box>

            <Box display="flex" mb={2}>
              <FormControl fullWidth>
                <InputLabel shrink id="label-cleared">
                  Cleared
                </InputLabel>
                <Select
                  name="cleared"
                  labelId="label-cleared"
                  onChange={handleChange}
                  displayEmpty
                  disabled={isSubmitting}
                  onBlur={handleBlur}
                  value={values.cleared}
                >
                  <MenuItem value={""}>Both (Pending & Cleared)</MenuItem>
                  <MenuItem value={"FALSE"}>Pending</MenuItem>
                  <MenuItem value={"TRUE"}>Cleared</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" mb={2}>
              <FormControl fullWidth>
                <InputLabel shrink id="label-direction">
                  Direction
                </InputLabel>
                <Select
                  name="direction"
                  labelId="label-direction"
                  onChange={handleChange}
                  displayEmpty
                  disabled={isSubmitting}
                  onBlur={handleBlur}
                  value={values.direction}
                >
                  <MenuItem value={""}>Any</MenuItem>
                  <MenuItem value={"CREDIT"}>Income</MenuItem>
                  <MenuItem value={"DEBIT"}>Spending</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box flexGrow={1} mb={2}>
              <Autocomplete
                multiple
                options={searchCriteria?.allTags || []}
                value={values.tags || []}
                freeSolo
                onChange={(_, t) => {
                  setFieldValue("tags", t);
                }}
                renderTags={(value, getTagProps) =>
                  value.map
                    ? value.map((option, index) => (
                        <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    : [value]
                }
                renderInput={(x) => (
                  <TextField
                    {...x}
                    name="tags"
                    label="Tags"
                    fullWidth
                    color="secondary"
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    error={errors.tags && touched.tags}
                    helperText={touched.tags && errors.tags}
                  />
                )}
              />
            </Box>

            <Box display="flex">
              <Box flexGrow={1}></Box>
              <Button onClick={handleSubmit} color="primary" variant="contained">
                Search
              </Button>
            </Box>
          </Box>
        </Paper>
      )}
    </Formik>
  );

  const [newTransactionWaiting, setNewTransactionWaiting] = useState(false);
  const [transactionOpen, setTransactionOpen] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(EMPTY_TRANSACTION);
  const [submitTransaction, setSubmitTransaction] = useState(() => {});
  const [removalConfirmationOpen, setRemovalConfirmationOpen] = useState(false);
  const [removalConfirmationSubmit, setRemovalConfirmationSubmit] = useState(() => {});
  const [removeTransactionsConfirmationOpen, setRemoveTransactionsConfirmationOpen] = useState(false);

  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [tagDialogTitle, setTagDialogTitle] = useState("");
  const [tagDialogDescription, setTagDialogDescription] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [tagging, setTagging] = useState(true);
  const [tagError, setTagError] = useState("");

  const tagOpen = () => {
    setTagValue("");
    setTagError("");

    setTagDialogTitle("Tag transactions");
    setTagDialogDescription("Please enter the tag you would like to attach to each of the selected transactions.");

    setTagging(true);
    setTagDialogOpen(true);
  };

  const untagOpen = () => {
    setTagValue("");
    setTagError("");

    setTagDialogTitle("Untag transactions");
    setTagDialogDescription("Please enter the tag you would like to remove from each of the selected transactions.");

    setTagging(false);
    setTagDialogOpen(true);
  };

  const tagSubmit = () => {
    tag([tagValue])
      .then(() => {
        dispatch({ type: "SET_SNACK", success: "Complete" });
        setTagDialogOpen(false);
      })
      .catch((ex) => {
        setTagError(ex);
      });
  };

  const untagSubmit = () => {
    untag(tagValue)
      .then(() => {
        dispatch({ type: "SET_SNACK", success: "Complete" });
        setTagDialogOpen(false);
      })
      .catch((ex) => {
        setTagError(ex);
      });
  };

  const tag = (tags) => {
    query(auth, tagTransactions, {
      ids: searchResults.selectedIds,
      tags,
    }).then(() => {
      dispatch({ type: "SIDEBAR_DIRTY" });
      issueSearch();
    });
  };

  const tagPayment = () => {
    tag(["Payment", uid(5)]);
  };

  const untag = (tag) =>
    query(auth, untagTransactions, {
      ids: searchResults.selectedIds,
      tag,
      alsoClear: false,
    }).then(() => {
      dispatch({ type: "SIDEBAR_DIRTY" });
      issueSearch();
    });

  const clearTransactions = () => {
    query(auth, untagTransactions, {
      ids: searchResults.selectedIds,
      tag: "Payment",
      alsoClear: true,
    }).then(() => {
      dispatch({ type: "SIDEBAR_DIRTY" });
      dispatch({
        type: "UPDATEBALANCES",
        payload: query(auth, updateAccountBalances, {
          account,
          payPeriod: searchResults.firstSelectedPayPeriod,
        }),
      });
      issueSearch();
    });
  };

  const updateCleared = (id, payPeriod, cleared) => {
    dispatch({
      type: "UPDATE_TRACKING",
      payload: query(auth, updateClearedMutation, {
        input: {
          id,
          cleared,
        },
      }),
    }).then(() => {
      dispatch({
        type: "UPDATEBALANCES",
        payload: query(auth, updateAccountBalances, {
          account,
          payPeriod,
        }),
      });
    });
  };

  const editTransaction = (popupState, id) => {
    popupState.close();
    setNewTransactionWaiting(true);
    setSubmitTransaction(submitEditTransaction);
    query(auth, queryTransaction, { id })
      .then((t) => {
        setTransactionToEdit(t);
        setTransactionOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setNewTransactionWaiting(false));
  };

  const submitEditTransaction = () => (transaction) => {
    const { amount, ...submitTransaction } = transaction;

    const tAccount = transaction.account;
    const tPayPeriod = transaction.payPeriod;

    const updatePayPeriod = tPayPeriod < payPeriod ? tPayPeriod : payPeriod;

    return query(auth, updateTransactionMutation, {
      input: submitTransaction,
    }).then(async (_) => {
      try {
        query(auth, updateSavingsBalances, {});

        await query(auth, updateAccountBalances, {
          account,
          payPeriod: updatePayPeriod,
        });

        if (tAccount !== account) {
          // Runs in the background
          query(auth, updateAccountBalances, {
            account: tAccount,
            payPeriod: updatePayPeriod,
          });
        }

        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SEARCHRESULTS_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
        setTransactionOpen(false);
      } catch (ex) {
        dispatch({ type: "SET_SNACK", error: ex });
        setTransactionOpen(false);
      }
    });
  };

  const newTransaction = (popupState, date, description, plaidAmount, tags) => {
    if (popupState?.close) {
      popupState.close();
    }

    const amount = plaidAmount ? plaidAmount : EMPTY_TRANSACTION.transaction.amount;
    const direction = amount < 0 ? "CREDIT" : "DEBIT";

    setNewTransactionWaiting(true);
    setSubmitTransaction(submitNewTransaction);
    query(auth, newTransactionQuery)
      .then((t) => {
        setTransactionToEdit({
          ...t,
          transaction: {
            ...EMPTY_TRANSACTION.transaction,
            account: searchCriteria.criteria.account,
            payPeriod: t.payPeriods.find((p) => p.isCurrent)?.name,
            date: date ? date : moment().format("YYYY-MM-DD"),
            description: description ? description : EMPTY_TRANSACTION.transaction.description,
            amount: Math.abs(amount),
            amounts: amount ? [{ category: "", amount: Math.abs(amount) }] : EMPTY_TRANSACTION.transaction.amounts,
            direction,
            tags: tags ? tags : EMPTY_TRANSACTION.transaction.tags,
          },
        });
        setTransactionOpen(true);
      })
      .finally(() => setNewTransactionWaiting(false));
  };

  const submitNewTransaction = () => (transaction) => {
    const finalTransaction = {
      payPeriod: transaction.payPeriod,
      account: transaction.account,
      date: transaction.date,
      description: transaction.description,
      amounts: transaction.amounts,
      direction: transaction.direction,
      cleared: transaction.cleared,
      tags: transaction.tags,
    };

    const tAccount = transaction.account;
    const tPayPeriod = transaction.payPeriod;

    const updateAccount = account || tAccount;
    const updatePayPeriod = (tPayPeriod < payPeriod ? tPayPeriod : payPeriod) || tPayPeriod;

    // console.log(`tPayPeriod:      '${tPayPeriod}'`);
    // console.log(`payPeriod:       '${payPeriod}'`);
    // console.log(`updatePayPeriod: '${updatePayPeriod}'`);
    // console.log(`tAccount:        '${tAccount}'`);
    // console.log(`updateAccount:   '${updateAccount}'`);

    return query(auth, newTransactionMutation, {
      input: finalTransaction,
    }).then(async (_) => {
      try {
        query(auth, updateSavingsBalances, {});

        await query(auth, updateAccountBalances, {
          updateAccount,
          payPeriod: updatePayPeriod,
        });

        if (tAccount !== updateAccount) {
          // Runs in the background
          query(auth, updateAccountBalances, {
            account: tAccount,
            payPeriod: updatePayPeriod,
          });
        }

        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SEARCHRESULTS_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
        issueSearch();
        setTransactionOpen(false);
      } catch (ex) {
        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SET_SNACK", error: ex });
        setTransactionOpen(false);
      }
    });
  };

  const removeTransactionsConfirmation = () => {
    setRemoveTransactionsConfirmationOpen(true);
  };

  const removeTransactionsConfirmationSubmit = () => {
    query(auth, removeMutationBulk, {
      ids: searchResults.selectedIds,
    })
      .then(() => {
        setRemoveTransactionsConfirmationOpen(false);
        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
        dispatch({
          type: "UPDATEBALANCES",
          payload: query(auth, updateAccountBalances, {
            account,
            payPeriod: searchResults.firstSelectedPayPeriod,
          }),
        });
        issueSearch();
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
        setRemoveTransactionsConfirmationOpen(false);
      });
  };

  const removalConfirmation = (popupState, id) => {
    popupState.close();
    setRemovalConfirmationOpen(true);
    setRemovalConfirmationSubmit(() => removeTransaction(id));
  };

  const removeTransaction = (id) => async () => {
    setRemovalConfirmationOpen(false);

    await dispatch({
      type: "REMOVE_TRACKING",
      payload: query(auth, removeMutation, {
        id,
      }),
    });

    query(auth, updateSavingsBalances, {});

    await query(auth, updateAccountBalances, {
      account,
      payPeriod,
    });

    dispatch({ type: "SIDEBAR_DIRTY" });
    dispatch({ type: "SEARCHRESULTS_DIRTY" });
    dispatch({ type: "SET_SNACK", success: "Complete" });
    issueSearch();
  };

  const manageSelection = (id, value) => {
    dispatch({ type: "SEARCHRESULTS_SELECT", payload: { id, value } });
  };

  const updateStatementBalance = (amount) => {
    dispatch({ type: "SEARCHRESULTS_NEWBALANCE", payload: amount });
  };

  const SearchResultsLink = () => (
    <TableContainer className="sticky" component={Paper} key="searchResultsLink">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="transaction sticky2 select">
              <IconButton onClick={() => {}}>
                <DoneAll />
              </IconButton>
            </TableCell>
            <TableCell className="transaction sticky2 ">Date</TableCell>
            <TableCell className="transaction sticky2 description">Description</TableCell>
            <TableCell className="transaction sticky2 amount">Amount</TableCell>
            <TableCell className="transaction sticky2 cleared">Cleared</TableCell>
            <TableCell className="transaction sticky2 menu"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchResults.accountLinkTransactions.map((t, i) => (
            <TableRow
              key={`result-${i}`}
              className={
                (!t.pending ? "cleared" : "uncleared") +
                (!t.matched && !t.category.includes("Payment") ? " unmatched" : "")
              }
              hover
            >
              <TableCell className="transaction select">
                <Checkbox onChange={() => {}} checked={t.selected} />
              </TableCell>
              <TableCell className="transaction ">{t.date}</TableCell>
              <TableCell className="transaction description">
                <Box>
                  {t.merchantName} {t.matched && <FontAwesomeIcon size="sm" icon={faLink} />}
                </Box>
                <Box fontSize="0.9em">{t.category.join(", ")}</Box>
              </TableCell>
              <TableCell className="transaction amount">
                <Amount amount={Math.abs(t.amount)} greenGood={t.amount < 0} bold />
              </TableCell>
              <TableCell className="transaction cleared">
                <Icon disabled>{!t.pending ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}</Icon>
              </TableCell>
              <TableCell className="transactionMenu">
                <IconButton
                  onClick={() => newTransaction(null, t.date, t.merchantName, t.amount, searchCriteria.criteria.tags)}
                >
                  <AddCircle />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const SearchResults = () => (
    <TableContainer className="sticky" component={Paper} key="searchResults">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="transaction sticky2 select">
              <IconButton onClick={() => dispatch({ type: "SEARCHRESULTS_SELECTALL" })}>
                <DoneAll />
              </IconButton>
            </TableCell>
            <TableCell className="transaction sticky2 ">Date</TableCell>
            <TableCell className="transaction sticky2 description">Description</TableCell>
            <TableCell className="transaction sticky2 period">Budget Period</TableCell>
            <TableCell className="transaction sticky2 amount">Amount</TableCell>
            <TableCell className="transaction sticky2 cleared">Cleared</TableCell>
            <TableCell className="transaction sticky2 menu"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchResults.transactions.map((t, i) => (
            <TableRow key={`result-${i}`} className={t.cleared ? "cleared" : "uncleared"} hover>
              <TableCell className="transaction select">
                <Checkbox onChange={() => manageSelection(t.id, !t.selected)} checked={t.selected} />
              </TableCell>
              <TableCell className="transaction ">{t.date}</TableCell>
              <TableCell className="transaction description">
                <Box>
                  {t.description} {t.tags.length > 0 && `[${t.tags.join(", ")}]`}{" "}
                  {t.matched && <FontAwesomeIcon size="sm" icon={faLink} color={t.matchCleared ? "green" : "grey"} />}
                </Box>
                <Box fontSize="0.9em">{t.amounts.map((y) => y.category).join(", ")}</Box>
              </TableCell>
              <TableCell className="transaction period">{t.payPeriod}</TableCell>
              <TableCell className="transaction amount">
                <Amount amount={t.amount} greenGood={t.direction === "CREDIT"} bold />
              </TableCell>
              <TableCell className="transaction cleared">
                <Icon disabled>{t.cleared ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}</Icon>
              </TableCell>
              <TableCell className="transactionMenu">
                <PopupState variant="popover" popupId={`tracking-menu-${i}`}>
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                        <ArrowDropDown />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => editTransaction(popupState, t.id)}>
                          <ListItemIcon>
                            <Edit />
                          </ListItemIcon>
                          <ListItemText primary="Edit" />
                        </MenuItem>
                        <MenuItem onClick={() => removalConfirmation(popupState, t.id)}>
                          <ListItemIcon>
                            <Delete />
                          </ListItemIcon>
                          <ListItemText primary="Remove" />
                        </MenuItem>
                        {t.cleared && (
                          <MenuItem
                            onClick={() => {
                              updateCleared(t.id, t.payPeriod, !t.cleared);
                              popupState.close();
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox />
                            </ListItemIcon>
                            <ListItemText primary="Pending" />
                          </MenuItem>
                        )}
                        {!t.cleared && (
                          <MenuItem
                            onClick={() => {
                              updateCleared(t.id, t.payPeriod, !t.cleared);
                              popupState.close();
                            }}
                          >
                            <ListItemIcon>
                              <CheckBoxOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Clear" />
                          </MenuItem>
                        )}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const SearchTotals = () => (
    <Paper elevation={3}>
      <Box p={3}>
        <Typography gutterBottom variant="h5">
          Search Totals
        </Typography>

        {accountLinkAvailable && (
          <React.Fragment>
            <Box display="flex" mt={3} mb={2}>
              <Box flexGrow="1">
                <Typography variant="body1">Account Type:</Typography>
              </Box>
              <Select
                onChange={({ target: { value } }) => {
                  dispatch({ type: "UPDATE_BALANCE_TYPE", payload: value });
                }}
                value={balanceType}
              >
                <MenuItem value={"DEPOSIT"}>Checking or Savings</MenuItem>
                <MenuItem value={"CREDIT"}>Credit Card</MenuItem>
              </Select>
            </Box>

            <Divider />
          </React.Fragment>
        )}

        <Box display="flex" mt={2}>
          <Box flexGrow="1">
            <Typography gutterBottom variant="body1">
              Count:
            </Typography>
          </Box>
          <Typography gutterBottom variant="body1">
            {searchResults.count}
          </Typography>
        </Box>

        <Box display="flex" mb={2}>
          <Box flexGrow="1">
            <Typography gutterBottom variant="body1">
              Total:
            </Typography>
          </Box>
          <Amount
            redBad={balanceType === "DEPOSIT"}
            amount={(balanceType === "DEPOSIT" ? 1 : -1) * searchResults.total}
          />
        </Box>

        <Divider />

        <Box mb={2}>
          <Box display="flex" mt={3}>
            <Box flexGrow="1">
              <Typography gutterBottom variant="body1">
                Selected Count:
              </Typography>
            </Box>
            <Typography gutterBottom variant="body1">
              {searchResults.selectedCount}
            </Typography>
          </Box>

          <Box display="flex" mb={2}>
            <Box flexGrow="1">
              <Typography gutterBottom variant="body1">
                Selected Total:
              </Typography>
            </Box>
            <Amount
              redBad={balanceType === "DEPOSIT"}
              amount={(balanceType === "DEPOSIT" ? 1 : -1) * searchResults.selectedTotal}
            />
          </Box>
        </Box>

        <Divider />

        <Box display="flex" mt={3} mb={2}>
          <Box flexGrow="1">
            <Typography variant="body1">Statement Balance:</Typography>
          </Box>
          <AmountEditable
            id={`statement-balance`}
            emptyStart
            amount={searchResults.statementBalance}
            onChange={(amount) => updateStatementBalance(amount)}
          />
        </Box>

        <Box display="flex" mb={2}>
          <Box flexGrow="1">
            <Typography gutterBottom variant="body1">
              Difference:
            </Typography>
          </Box>
          <Amount
            amount={
              (balanceType === "DEPOSIT" ? searchResults.bankBalance : 0) +
              searchResults.selectedTotal -
              (balanceType === "DEPOSIT" ? -1 : 1) * searchResults.statementBalance
            }
          />
        </Box>

        <Divider />

        <Box display="flex" mt={3} mb={3} justifyContent="center">
          <Button color="primary" variant="contained" startIcon={<Add />} onClick={newTransaction}>
            New Transaction
          </Button>
        </Box>

        <Divider />

        <Box display="flex" mt={3}>
          <Box flexGrow={1}></Box>
          <Button onClick={() => dispatch({ type: "SEARCHCRITERIA_SHOW" })} color="secondary" variant="contained">
            Back
          </Button>
        </Box>
      </Box>
    </Paper>
  );

  const [selectedTab, setSelectedTab] = useState("1");
  const ActionButtons = () => (
    <PopupState variant="popover" popupId={`search-action`}>
      {(popupState) => (
        <React.Fragment>
          <Button
            color="primary"
            disabled={searchResults.selectedCount === 0}
            variant="contained"
            endIcon={<ArrowDropDown />}
            {...bindTrigger(popupState)}
          >
            Actions
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  tagOpen();
                }}
              >
                <ListItemText primary="Tag..." />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  untagOpen();
                }}
              >
                <ListItemText primary={"Untag..."} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  tagPayment();
                }}
              >
                <ListItemText primary="Tag Payment" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  clearTransactions();
                }}
              >
                <ListItemText primary={'Mark "Cleared"'} />
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  popupState.close();
                  removeTransactionsConfirmation();
                }}
              >
                <ListItemText primary={"Remove Transactions"} />
              </MenuItem>
            </Menu>
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  );

  return (
    <SingleLayout
      title="Search"
      maxWidth="xl"
      loading={newTransactionWaiting || searchCriteria.isDirty || searchCriteria.isFetching || searchResults.isFetching}
    >
      <Box minWidth={{ xs: "100%", md: theme.spacing(50) }} zIndex={10}>
        <Box
          width={{ xs: "100%", md: theme.spacing(40) }}
          pb={11}
          position="fixed"
          overflow="auto"
          height="100%"
          ml={{ xs: 0, md: 3 }}
        >
          {searchCriteria.viewCriteria ? <SearchCriteria /> : <SearchTotals />}
        </Box>
      </Box>
      <Box flexGrow={1} mt={{ xs: theme.spacing(9.5), md: 0 }} mb={3} mr={{ xs: 0, md: 3 }} zIndex="15">
        {searchCriteria.viewCriteria &&
          searchResults.count === 0 &&
          searchResults.accountLinkTransactions.length === 0 && (
            <Box mb={1}>
              <Alert severity="info">Click "Submit" to begin searching...</Alert>
            </Box>
          )}

        {searchResults.error !== "" && <Alert severity="error">{searchResults.error}</Alert>}

        {!searchCriteria.viewCriteria &&
          searchResults.count === 0 &&
          searchResults.accountLinkTransactions.length === 0 && <Alert severity="warning">No results</Alert>}

        {!searchCriteria.viewCriteria && searchResults.count > 0 && searchResults.accountLinkTransactions.length === 0 && (
          <React.Fragment>
            <Box position="sticky" top="64px" zIndex="20">
              <Paper className="stickyTopButtons">
                <Box py={2} px={{ xs: 2, md: 0 }} display="flex">
                  <ActionButtons />
                </Box>
              </Paper>
            </Box>
            <SearchResults />
          </React.Fragment>
        )}

        {!searchCriteria.viewCriteria && searchResults.accountLinkTransactions.length > 0 && (
          <TabContext value={selectedTab}>
            <Box position="sticky" top="64px" zIndex="20">
              <Paper className="stickyTopButtons">
                <Box py={2} px={{ xs: 2, md: 0 }} display="flex">
                  <Box flexGrow={1}>
                    <TabList onChange={(_, tab) => setSelectedTab(tab)} aria-label="Search Results">
                      <Tab label={searchCriteria.criteria.account} value="1" />
                      <Tab label="Linked Account" value="2" />
                    </TabList>
                  </Box>
                  <ActionButtons />
                </Box>
              </Paper>
            </Box>
            <TabPanel value="1" style={{ padding: 0 }}>
              {searchResults.count === 0 ? <Alert severity="warning">No results</Alert> : <SearchResults />}
            </TabPanel>
            {searchResults.accountLinkTransactions.length > 0 && (
              <TabPanel value="2" style={{ padding: 0 }}>
                <Box p={2} bgcolor="background.default">
                  Last Updated: {moment(searchResults.accountLinkLastUpdated + "Z").format("MMMM Do YYYY, h:mm:ss a")}
                </Box>
                <SearchResultsLink />
              </TabPanel>
            )}
          </TabContext>
        )}
      </Box>

      <Confirmation
        open={removalConfirmationOpen}
        cancel={() => setRemovalConfirmationOpen(false)}
        submit={removalConfirmationSubmit}
        message="Are you sure you want to remove this transaction?"
      />

      <Confirmation
        open={removeTransactionsConfirmationOpen}
        cancel={() => setRemoveTransactionsConfirmationOpen(false)}
        submit={removeTransactionsConfirmationSubmit}
        message="Are you sure you want to remove these transactions?"
      />

      <EditTransaction
        open={transactionOpen}
        cancel={() => setTransactionOpen(false)}
        submit={submitTransaction}
        accounts={transactionToEdit.accounts}
        payPeriods={transactionToEdit.payPeriods}
        transaction={transactionToEdit.transaction}
        typeAhead={transactionToEdit.typeAhead}
        categories={transactionToEdit.categories}
        tags={transactionToEdit.settings.tags}
        title="New Transaction"
      />

      <Dialog open={tagDialogOpen} aria-labelledby="tag-dialog-title" aria-describedby="tag-dialog-description">
        <DialogTitle id="tag-dialog-title">{tagDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="tag-dialog-description">{tagDialogDescription}</DialogContentText>
          <TextField autoFocus fullWidth={false} onChange={(t) => setTagValue(t.target.value)} label="Tag" />
          <Box my={2} hidden={tagError === ""}>
            <Alert variant="filled" severity="error">
              {tagError}
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTagDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => (tagging ? tagSubmit() : untagSubmit())}
            color="primary"
            variant="contained"
            disabled={tagValue === ""}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <SnackMessage dispatch={dispatch} snack={searchCriteria.snack} />
    </SingleLayout>
  );
};

export default Search;

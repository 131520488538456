import { reducer as snackReducer } from 'components/SnackMessage'
import moment from 'moment/moment'
import { firstBy } from 'thenby'

const DEFAULT_STATE = {
  summary: {
    budgetRemaining: [],
    alerts: []
  },
  accountBalances: [],
  budgetBalance: {
    difference: 0
  },
  plannedEvents: [],
  error: "",
  snack: { type: "", message: "" }
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SUMMARY_FULFILLED": {
      return {
        ...state,
        ...action.payload,
        error: ""
      }
    }
    case "SUMMARY_REJECTED": {
      return { 
        ...state,
        error: action.payload || "Unable to retrieve summary"
      }
    }
    case "BALANCES_FULFILLED": {
      return {
        ...state,
        ...action.payload,
        error: ""
      }
    }
    case "BALANCES_REJECTED": {
      return { 
        ...state,
        error: action.payload || "Unable to retrieve balances"
      }
    }
    case "SUMMARYPLANNER_FULFILLED": {
      const today = moment().format("YYYY-MM-DD")
      return {
        ...state,
        plannedEvents: action.payload?.planner?.events?.sort(firstBy("date"))?.filter(x=> (x.date >= today)).filter((_,i) => i < 5)
      }
    }
    default:
      return snackReducer(state, action)
  }
}
import { Box, Button, CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { AccountBalanceOutlined, Add, CategoryTwoTone, Delete, Edit, MoreVert, Refresh, Settings } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { query } from "app/api";
import { useAuthorizationWrite } from "auth";
import Amount, { formatAmount } from "components/Amount";
import Confirmation from "components/Confirmation";
import Loading from "components/Loading";
import SingleLayout from "components/SingleLayout";
import SnackMessage from "components/SnackMessage";

import EditGoal from "./EditGoal";
import { createGoalMutation, queryGoal, queryGoalCategories, removeSavingsGoal, savingsQuery, updateGoalMutation, updateSavingsBalances } from "./queries";

export const EMPTY_GOAL = {
  parentCategory: {
    categories: [],
  },
  savingsGoal: {
    category: "",
    amount: "",
    by: null,
  },
};

const Savings = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();

  const state = useSelector((state) => ({ ...state.stateManager.savings, ...state.savings }));

  const displayable = state.savings.funds !== null;

  const canUpdateSavings = useAuthorizationWrite("Savings");

  const [removalConfirmationOpen, setRemovalConfirmationOpen] = useState(false);
  const [removalConfirmationSubmit, setRemovalConfirmationSubmit] = useState(() => {});

  const [editGoalOpen, setEditGoalOpen] = useState(false);
  const [editGoalWaiting, setEditGoalWaiting] = useState(false);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [goalToEdit, setGoalToEdit] = useState(EMPTY_GOAL);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    if (!state.isFetching && state.isDirty) {
      dispatch({ type: "SAVINGS", payload: query(auth, savingsQuery, { }) });
    }
  }, [dispatch, auth, state]);

  const removalConfirmation = (popupState, category) => {
    popupState.close();
    setRemovalConfirmationOpen(true);
    setRemovalConfirmationSubmit(() => removeGoal(category));
  };

  const removeGoal = (category) => async () => {
    setRemovalConfirmationOpen(false);
    setEditGoalWaiting(true);

    try {
      await query(auth, removeSavingsGoal, { category });

      dispatch({ type: "SAVINGS_DIRTY" });
      dispatch({ type: "SET_SNACK", success: "Complete" });
    } catch (ex) {
      dispatch({ type: "SET_SNACK", error: ex });
    } finally {
      setEditGoalWaiting(false);
    }
  };

  const refreshSavingsBalances = async () => {
    try {
      await query(auth, updateSavingsBalances);
      dispatch({ type: "SAVINGS_DIRTY" });
      dispatch({ type: "SET_SNACK", success: "Complete" });
    } catch (ex) {
      dispatch({ type: "SET_SNACK", error: ex });
    }
  };

  const navigateWithReturn = (destination) => {
    dispatch({ type: "RETURN_PAGE", payload: history.location.pathname });
    history.push(destination);
  };

  const editGoal = (popupState, category) => {
    setAdding(false);
    popupState.close();
    setEditGoalWaiting(true);
    query(auth, queryGoal, { category })
      .then((t) => {
        setAvailableCategories([category]);
        setGoalToEdit(t.savingsGoal);
        setEditGoalOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setEditGoalWaiting(false));
  };

  const submitEditGoal = (goal) => {
    return query(auth, updateGoalMutation, {
      input: goal,
    }).then((_) => {
      dispatch({ type: "SAVINGS_DIRTY" });
      dispatch({ type: "SET_SNACK", success: "Complete" });
      setEditGoalOpen(false);
    });
  };

  const addSavingsGoal = () => {
    setAdding(true);
    setEditGoalWaiting(true);
    query(auth, queryGoalCategories)
      .then((t) => {
        setGoalToEdit(EMPTY_GOAL.savingsGoal);
        setAvailableCategories(t.parentCategory.categories.map((c) => c.name));
        setEditGoalOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setEditGoalWaiting(false));
  };

  const submitCreateGoal = (goal) => {
    return query(auth, createGoalMutation, {
      input: goal,
    }).then((_) => {
      dispatch({ type: "SAVINGS_DIRTY" });
      dispatch({ type: "SET_SNACK", success: "Complete" });
      setEditGoalOpen(false);
    });
  };

  const ListGoals = () => (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        <Box display="flex" m={{ xs: 2, md: 0 }}>
          <Box flexGrow={1} textAlign="center" ml={3}>
            Savings Goals
          </Box>
          {canUpdateSavings && (
            <IconButton variant="contained" size="small" onClick={() => refreshSavingsBalances()}>
              <Refresh />
            </IconButton>
          )}
        </Box>
      </Typography>

      {state.savings.funds.length === 0 && (
        <Alert severity="info">
          You have not added any savings goals, yet. Would you like to add a goal?
          <Box mt={2} py={1} display="flex" flexDirection="row">
            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  addSavingsGoal();
                }}
              >
                Add Savings Goal
              </Button>
            </Box>
          </Box>
        </Alert>
      )}

      {state.savings.funds.length > 0 && (
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          {state.savings.funds.map((fund, i) => {
            const progressRaw = Math.round((fund.balance / fund.goal) * 100);
            const progress = progressRaw > 100 ? 100 : progressRaw;
            return (
              <Box key={`fund${i}`} width={{ xs: "100%", lg: "375px" }} m={2}>
                <Paper>
                  <Box p={2} textAlign="center" height="245px">
                    <Box mb={1} display="flex">
                      <Box flexGrow={1} ml={3}>
                        <Typography variant="h5">{fund.category}</Typography>
                      </Box>
                      <Box>
                        <PopupState variant="popover" popupId={`goals-menu-${i}`}>
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                <MoreVert />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => editGoal(popupState, fund.category)}>
                                  <ListItemIcon>
                                    <Edit />
                                  </ListItemIcon>
                                  <ListItemText primary="Edit" />
                                </MenuItem>
                                <MenuItem onClick={() => removalConfirmation(popupState, fund.category)}>
                                  <ListItemIcon>
                                    <Delete />
                                  </ListItemIcon>
                                  <ListItemText primary="Remove" />
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </Box>
                    </Box>
                    <Box mb={fund.by ? 1 : 5}>
                      <Amount bold amount={fund.balance} style={{ fontSize: "200%" }} />
                    </Box>
                    {fund.by && <Box mb={2}>By {moment(fund.by).format("MMM D, YYYY")}</Box>}
                    <Box display="flex" justifyContent="center">
                      <Box mt={2} width="33%">
                        Goal
                        <br />
                        <Amount amount={fund.goal} />
                      </Box>
                      <Box width="33%">
                        <Box position="relative" display="inline-flex" width="80px" height="80px">
                          <CircularProgress
                            variant="determinate"
                            value={progress}
                            style={{ width: "80px", height: "80px" }}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="80px"
                          >
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >{`${progress}%`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      {fund.recommendation !== null && (
                        <Box mt={2} width="33%">
                          Suggested
                          <br />
                          <Amount amount={fund.recommendation} />
                        </Box>
                      )}
                      {fund.recommendation === null && (
                        <Box mt={2} width="33%">
                          Remaining
                          <br />
                          <Amount amount={fund.difference < 0 ? fund.difference : 0} redBad />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Box>
            );
          })}
          {addSavingsGoal && (
            <Box width={{ xs: "100%", lg: "375px" }} m={2}>
              <Paper>
                <Box p={2} display="flex" alignItems="center" justifyContent="center" height="245px">
                  <Box width="100%" textAlign="center">
                    <Typography variant="h5">Add Goal</Typography>
                    <IconButton style={{ width: "175px", height: "175px" }} onClick={() => addSavingsGoal()}>
                      <Add style={{ width: "175px", height: "175px" }} />
                    </IconButton>
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
      )}
    </React.Fragment>
  );

  const SavingsAccountsAndCategories = ({ account, showTitle }) => (
    <Box mb={3}>
      {showTitle && (
        <Box display="flex" mx={{ xs: 2, md: 0 }}>
          <Typography variant="h6" gutterBottom>
            Account: {account.name}
          </Typography>
        </Box>
      )}
      {account.active && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell align="right">Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {account.categories.map((c, i) => (
                <TableRow key={`account-${account.name}-category-${i}`}>
                  <TableCell>{c.category}</TableCell>
                  <TableCell align="right">
                    <Amount amount={c.balance} redBad />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Account Balance</TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="right">
                  <Amount amount={account.balance} bold redBad />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!account.active && (
        <Box display="flex" mx={{ xs: 2, md: 0 }}>
          Inactive with a balance of {formatAmount(account.balance)}.
        </Box>
      )}
    </Box>
  );

  return (
    <SingleLayout title="Savings" fullWidth maxWidth="md">
      <Loading loading={editGoalWaiting || state.isDirty || state.isFetching} hard={!displayable}>
        {state.error && (
          <Alert severity="error" variant="filled">
            {state.error}
          </Alert>
        )}

        {state.isFetching && state.savings.accounts.length === 0 && (
          <>
            <Skeleton width={240} height={70} />
            <Skeleton variant="rect" height={150} />
            <Skeleton width={240} height={70} style={{ marginTop: "32px" }} />
            <Skeleton variant="rect" height={150} />
          </>
        )}

        {!state.error && state.savings.accounts.length > 0 && (
          <React.Fragment>
            <Box mb={5}>
              <ListGoals />
            </Box>
            <Box mb={5}>
              <Box display="flex" mx={{ xs: 2, md: 0 }}>
                <Box flexGrow={1}>
                  <Typography variant="h4" gutterBottom>
                    Account Balances
                  </Typography>
                </Box>
                {canUpdateSavings && (
                  <Box>
                    <PopupState variant="popover" popupId={`savings-settings`}>
                      {(popupState) => (
                        <React.Fragment>
                          <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                            <Settings />
                          </IconButton>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => navigateWithReturn("/categories")}>
                              <ListItemIcon>
                                <CategoryTwoTone />
                              </ListItemIcon>
                              <ListItemText primary="Edit Categories" />
                            </MenuItem>
                            <MenuItem onClick={() => navigateWithReturn("/accounts")}>
                              <ListItemIcon>
                                <AccountBalanceOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Edit Accounts" />
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </Box>
                )}
              </Box>

              {state.savings.accounts.map((a, i) => (
                <SavingsAccountsAndCategories
                  account={a}
                  key={`savings-account-${i}`}
                  showTitle={state.savings.accounts.length > 1}
                />
              ))}
            </Box>
            {state.savings.accounts.length > 1 && (
              <Box display="flex" mx={{ xs: 2, md: 0 }}>
                <Typography variant="h6" gutterBottom>
                  Total Savings: <Amount bold amount={state.savings.total} />
                </Typography>
              </Box>
            )}
          </React.Fragment>
        )}
      </Loading>

      <EditGoal
        open={editGoalOpen}
        cancel={() => setEditGoalOpen(false)}
        submit={adding ? submitCreateGoal : submitEditGoal}
        goal={goalToEdit}
        isEditing={!adding}
        categories={availableCategories}
        title={adding ? "Adding Goal" : "Edit Goal"}
      />

      <Confirmation
        open={removalConfirmationOpen}
        cancel={() => setRemovalConfirmationOpen(false)}
        submit={removalConfirmationSubmit}
        message="Are you sure you want to remove this savings goal?"
      />

      <SnackMessage dispatch={dispatch} snack={state.snack} />
    </SingleLayout>
  );
};

export default Savings;

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Calendar from "react-calendar"



const AddCustomPayPeriod = ({open, cancel, submit, nextPayPeriodStartDate}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [error, setError] = useState(null)
  const [nextPayPeriodEndDate, setNextPayPeriodEndDate] = useState(null)
  const [calendarValue, setCalendarValue] = useState(new Date())

  const [duration, setDuration] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(()=>{
    setCalendarValue(nextPayPeriodStartDate)
  }, [nextPayPeriodStartDate])

  useEffect(()=>{
    setCalendarValue([moment(nextPayPeriodStartDate).toDate(), moment(nextPayPeriodEndDate).toDate()])
    if (nextPayPeriodEndDate) {
      setDuration(moment(nextPayPeriodEndDate).diff(moment(nextPayPeriodStartDate), "days") + 1)
    }
  }, [nextPayPeriodStartDate, nextPayPeriodEndDate])

  const submitHandler = () => {
    setIsSubmitting(true)
    submit(nextPayPeriodEndDate).then(() => {
      setIsSubmitting(false)
    }).catch(ex => {
      setIsSubmitting(false)
      setError(ex)
    })
  }

  return (
    <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
      <DialogTitle id="edit-dialog-title">Adding a Custom Budget Pay Period</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" component="p" gutterBottom>
            You're next budget pay period will start on {moment(nextPayPeriodStartDate).format("MMM Do, YYYY")}.  When you would like it to end?
          </Typography>

          <Typography variant="body1" component="p" gutterBottom>
            <strong>Example</strong>: It should end the <u>day before</u> your next expected payday.
          </Typography>

          <Box textAlign="center" margin="16px auto">
            <Calendar 
              value={calendarValue}
              allowPartialRange
              defaultActiveStartDate={moment(nextPayPeriodStartDate).toDate()}              
              onChange={newEndDate => {
                setNextPayPeriodEndDate(moment(newEndDate).format("YYYY-MM-DD"))
              }}
              minDate={moment(nextPayPeriodStartDate).toDate()}
              maxDate={moment(nextPayPeriodStartDate).add(89, "day").toDate()}
              calendarType="US"
            />
            {(duration !== null) && <Box>{duration} Day{duration > 1 && "s"}</Box>}
          </Box>

          <Box hidden={!error || (error === "")} >
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box m={{xs: 2, md:2}} mb={{xs:8,md:2}}>
          <Button onClick={() => {cancel()}} color="secondary">
            Cancel 
          </Button>
          <Button onClick={submitHandler} color="primary" variant="contained" disabled={isSubmitting || !duration || (duration < 2)}>
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default AddCustomPayPeriod
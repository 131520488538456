import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

import Loading from "components/Loading";
import Navigation from "components/Navigation";

const SingleLayout = ({ title, loading, fullWidth, children, maxWidth = "sm" }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Navigation title={title}>
      <Container disableGutters={fullScreen} maxWidth={maxWidth}>
        <Loading loading={loading}>
          <Box display="flex" mt={{ xs: 8, md: 11 }} flexWrap={{ xs: "wrap", md: "nowrap" }}>
            {fullWidth && (
              <Box width="100%" flexGrow={1} mt={0} mb={3}>
                {children}
              </Box>
            )}
            {!fullWidth && children}
          </Box>
        </Loading>
      </Container>
    </Navigation>
  );
};

export default SingleLayout;

import { Backdrop, Box, CircularProgress, createMuiTheme, CssBaseline, ThemeProvider, Typography } from '@material-ui/core'
import React from 'react'

const theme = createMuiTheme({
  palette: {
    type: "dark"
  },
})

const CallbackPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop open={true}>
        <Box mr={2}><CircularProgress color="primary"/></Box>
        <Typography color="primary" variant="h5">Loading...</Typography>
      </Backdrop>
    </ThemeProvider>
  )
}

export default CallbackPage
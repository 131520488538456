import firstBy from 'thenby'

const addValue = (oldValue, amount) => {
  if (oldValue === undefined) {
    oldValue = 0
  }

  return oldValue + amount
}

const filterAndSortTransactions = (rawTransactions, filterRaw) => {
  const filter = filterRaw.trim()
  var filteredAmount = undefined
  var filteredCount = undefined
  var pendingFilteredAmount = undefined
  var pendingFilteredCount = undefined

  var filteredTransactions = 
    (!filter) ? 
      rawTransactions :
      rawTransactions.filter(function (a) {
        var split = filter.split(":")
        if (split.length > 1) {
          if (split[0] === "category") {
            return a.amounts.filter(({category}) => {
              var found = category.toLowerCase() === split[1].toLowerCase()
              if (found) {
                if (!a.cleared) {
                  pendingFilteredCount = addValue(pendingFilteredCount, 1)
                  pendingFilteredAmount = addValue(pendingFilteredAmount, a.amount * ((a.direction === "debit")?-1:1))
                }
                filteredCount = addValue(filteredCount, 1)
                filteredAmount = addValue(filteredAmount, a.amount * ((a.direction === "debit")?-1:1))
              }
              return found
            }).length > 0
          }
          
          if (split[0] === "tag") {
            return a.tags.filter(function (tag) {
              var found = tag.toLowerCase().indexOf(split[1].toLowerCase()) !== -1
              if (found) {
                if (!a.cleared) {
                  pendingFilteredCount = addValue(pendingFilteredCount, 1)
                  pendingFilteredAmount = addValue(pendingFilteredAmount, a.amount * ((a.direction === "Debit")?-1:1))
                }
                filteredCount = addValue(filteredCount, 1)
                filteredAmount = addValue(filteredAmount, a.amount * ((a.direction === "Debit")?-1:1))
              }
              return found
            }).length > 0
          }            
        }

        var description = (a.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        var category = a.amounts.filter(({category}) => {
          return (category.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        }).length > 0
        var amount = ("$" + (a.amount/100).toFixed(2)).indexOf(filter) !== -1
        return description || category || amount            
        
      })

  var transactions = filteredTransactions.sort(
    firstBy("cleared")
    .thenBy("date", -1)
    .thenBy("description")
  )

  return {
    transactions,
    filteredAmount,
    filteredCount,
    pendingFilteredAmount,
    pendingFilteredCount
  }
}

export default filterAndSortTransactions
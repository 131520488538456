import { validEmail } from "@budgetocity-internal/validation-library";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";
import React from "react";

const CreateSharingRequest = ({ open, cancel, submitShare, submitPartner }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const submitHandler = (values, { resetForm, setSubmitting, setErrors }) => {
    submitShare({
      email: values.email.trim(),
    })
      .then(() => {
        resetForm();
      })
      .catch((ex) => {
        setSubmitting(false);
        setErrors({ global: ex });
      });
  };

  const validate = (values) => {
    var errors = {};

    const newEmail = values.email.trim();

    if (!validEmail(newEmail).passed) {
      errors = { ...errors, name: "Please enter a valid email address" };
    }

    return errors;
  };

  return (
    <Formik initialValues={{ email: "" }} enableReinitialize validate={validate} onSubmit={submitHandler}>
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldError,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
          <DialogTitle id="edit-dialog-title">Share Your Profile</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box display="flex" mb={2}>
                <TextField
                  name="email"
                  label="Email Address"
                  color="secondary"
                  type="email"
                  fullWidth
                  margin="normal"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box hidden={!errors.global || errors.global === ""}>
                <Alert severity="error" onClose={() => setFieldError("global", null)}>
                  {errors.global}
                </Alert>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  cancel();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting || !isValid}>
                Submit
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default CreateSharingRequest;

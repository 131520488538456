import { validAmountNormalized } from '@budgetocity-internal/validation-library'
import { Box, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@material-ui/core'
import { Exposure } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

const deepCopyAmount = (emptyStart, amount) => 
  (emptyStart && (amount === null || amount === undefined || amount === "")) ? "" : (amount / 100.0).toFixed(2)

const userAgent = navigator?.userAgent || ""

const AmountEditable = ({id, amount: inputAmount, label, ariaLabel, onChange, style, fullWidth, variant, margin, disabled, onBlur, emptyStart, negatable, error = null, helperText = null}) => {
  const inputRef = React.useRef()
  const theme = useTheme()
  const useMobileEntry = useMediaQuery(theme.breakpoints.down('sm')) && !userAgent.includes("Android")
  const [amount, setAmount] = useState(deepCopyAmount(emptyStart, inputAmount))

  useEffect(() => {
    setAmount(deepCopyAmount(emptyStart, inputAmount))
  }, [emptyStart, inputAmount])

  const save = () => {
    const newValue = Math.round(inputRef.current.value * 100)
    if (onChange && newValue !== Number(inputAmount)) {
      onChange(newValue)
    }   
    inputRef.current.blur()
    onBlur && onBlur()
  }

  const cancel = () => {
    setAmount((inputAmount / 100.0).toFixed(2))
    inputRef.current.blur()
    onBlur && onBlur()
  }

  const negate = () => {
    inputRef.current.value = inputRef.current.value * -1
    save()
  }

  return (
    <Box>
      <TextField
        id={id}
        inputRef={inputRef}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: negatable && 
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="toggle negative balance"
                onClick={() => negate()}
              >
                <Exposure/>
              </IconButton>
            </InputAdornment>
        }}
        onFocus={ ev => {
          if (useMobileEntry) {
            ev.preventDefault()
            const value = inputRef.current.value
            setAmount("")
            setTimeout( () => setAmount(value), 10 )
          }
        }}
        onClick={ ev => {
          if (useMobileEntry) {
            ev.preventDefault()
            const value = inputRef.current.value
            setAmount("")
            setTimeout( () => setAmount(value), 1 )
          }
        }}
        onBlur={ () => save() }
        label={label?label:null}
        onPaste={ev => {
          const data = ev?.clipboardData?.getData("text/plain")
          if (data) {
            const result = data.replace(/([$,])/g, "")
            if (result.match(validAmountNormalized)) {
              setAmount(result)
            }
          }
        }}
        onKeyUpCapture={ev => {
          if (ev.key === 'Escape') {
            cancel()
            ev.preventDefault()
            return
          }
          if (ev.key === 'Enter') {
            save()
            ev.preventDefault()
            return
          }

          if (useMobileEntry) {
            ev.preventDefault()
            if (ev.key === "Backspace") {
              const value = (Math.floor(inputRef.current.value * 10) / 100.0).toFixed(2)
              setAmount(value)
              return
            }
            
            if (!isNaN(Number(ev.key))) {
              if (inputRef.current.value) {
                setAmount(((Math.round(inputRef.current.value * 100) * 10 + Number(ev.key)) / 100.0).toFixed(2))
              } else {
                setAmount((Number(ev.key) / 100.0).toFixed(2))
              }
              return
            }
          }
        }}
        inputProps={{
          style: {textAlign: "right"},
          "aria-label": ariaLabel?ariaLabel:label,
          inputMode: useMobileEntry ? "numeric" : "decimal"
        }}
        onChange={({target}) => {
          if (!useMobileEntry && target.value.match(validAmountNormalized)) {
            setAmount(target.value)
          }
        }}
        fullWidth
        margin={margin}
        disabled={disabled}
        placeholder="0.00"
        style={fullWidth ? null : (style?style:{width: theme.spacing(11)})}
        value={amount}
        variant={variant}
        error={error}
        helperText={helperText}
      />
    </Box>
  )
}

export default AmountEditable
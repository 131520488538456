import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { ArrowDropDown, CheckBoxOutlined, Delete, Edit, LibraryAddCheck } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import PopupState, { bindMenu, bindPopover, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { query } from "app/api";
import { mergeArrays } from "app/util";
import { useAuthorizationWrite } from "auth";
import Amount, { formatAmount } from "components/Amount";
import Confirmation from "components/Confirmation";
import Loading from "components/Loading";
import { EMPTY_TRANSACTION } from "components/Sidebar";
import { updateAccountBalances } from "components/Sidebar/queries";
import SidebarLayout from "components/SidebarLayout";
import SnackMessage from "components/SnackMessage";

import { updateSavingsBalances } from "../Savings/queries";
import EditTransaction from "./EditTransaction";
import { queryTransaction, removeMutation, trackingQuery, updateClearedMutation, updateTransactionMutation } from "./queries";
import FetchingProgress from "components/FetchingProgress";

export const mapDirectionTypes = (accountType) => {
  switch (accountType) {
    case "SAVINGS": {
      return {
        CREDIT: "Deposit",
        DEBIT: "Withdraw"
      };
    }
    case "CREDIT": {
      return {
        CREDIT: "Payment/Refund",
        DEBIT: "Spending"
      };
    }
    default: {
      return {
        CREDIT: "Income",
        DEBIT: "Spending"
      };
    }
  }
};

const Tracking = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const extraSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const canUpdateTransaction = useAuthorizationWrite("Transaction");

  const auth = useSelector((state) => state.auth);
  const payPeriod = useSelector((state) => state.auth.payPeriod);
  const account = useSelector((state) => state.auth.account);
  const accounts = useSelector((state) => state.sidebar.accounts);
  const tracking = useSelector((state) => ({ ...state.stateManager.tracking, ...state.tracking }));

  const displayable = tracking.transactions;
  const hideEnteredBy = Object.keys(tracking.avatars).length === 0;

  const [removalConfirmationOpen, setRemovalConfirmationOpen] = useState(false);
  const [removalConfirmationSubmit, setRemovalConfirmationSubmit] = useState(() => {});

  const [editOpen, setEditOpen] = useState(false);
  const [editTransactionWaiting, setEditTransactionWaiting] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(EMPTY_TRANSACTION);

  const [directionMap, setDirectionMap] = useState(mapDirectionTypes(""));

  useEffect(() => {
    setDirectionMap(mapDirectionTypes(accounts.find((a) => a.name === account)?.type));
  }, [accounts, account]);

  useEffect(() => {
    if (payPeriod && !tracking.isFetching && tracking.isDirty) {
      dispatch({ type: "TRACKING", payload: query(auth, trackingQuery, { payPeriod, account }) });
    }
  }, [dispatch, auth, tracking, payPeriod, account]);

  const updateCleared = (id, cleared) => {
    dispatch({
      type: "UPDATE_TRACKING",
      payload: query(auth, updateClearedMutation, {
        input: {
          id,
          cleared
        }
      })
    }).then(() => {
      dispatch({
        type: "UPDATEBALANCES",
        payload: query(auth, updateAccountBalances, {
          account,
          payPeriod
        })
      });
    });
  };

  const editTransaction = (popupState, id) => {
    popupState.close();
    setEditTransactionWaiting(true);
    query(auth, queryTransaction, { id })
      .then((t) => {
        setTransactionToEdit(t);
        setEditOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setEditTransactionWaiting(false));
  };

  const submitTransaction = (transaction) => {
    const { amount, ...submitTransaction } = transaction;

    const tAccount = transaction.account;
    const tPayPeriod = transaction.payPeriod;

    const updatePayPeriod = tPayPeriod < payPeriod ? tPayPeriod : payPeriod;

    return query(auth, updateTransactionMutation, {
      input: submitTransaction
    }).then(async (_) => {
      try {
        await query(auth, updateAccountBalances, {
          account,
          payPeriod: updatePayPeriod
        });

        query(auth, updateSavingsBalances, {});

        if (tAccount !== account) {
          // Runs in the background
          query(auth, updateAccountBalances, {
            account: tAccount,
            payPeriod: updatePayPeriod
          });
        }

        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
        setEditOpen(false);
      } catch (ex) {
        dispatch({ type: "SET_SNACK", error: ex });
        setEditOpen(false);
      }
    });
  };

  const copyCSV = () => {
    const value = tracking.transactions.map((x) => {
      return `${x.date},${x.description},${formatAmount(x.amount * (x.direction === "CREDIT" ? 1 : -1)).replace(",", "")}\n,${x.amounts
        .map((y) => y.category)
        .join("; ")},\n`;
    }).join("");

    navigator.clipboard.writeText(value);
  };

  const removalConfirmation = (popupState, id) => {
    popupState.close();
    setRemovalConfirmationOpen(true);
    setRemovalConfirmationSubmit(() => removeTransaction(id));
  };

  const removeTransaction = (id) => async () => {
    setRemovalConfirmationOpen(false);

    await dispatch({
      type: "REMOVE_TRACKING",
      payload: query(auth, removeMutation, {
        id
      })
    });

    query(auth, updateSavingsBalances, {});

    await query(auth, updateAccountBalances, {
      account,
      payPeriod
    });

    dispatch({ type: "SIDEBAR_DIRTY" });
  };

  return (
    <SidebarLayout title="Tracking">
      <Loading loading={editTransactionWaiting} error={tracking.error} hard={!displayable}>
        {tracking.isFetching && tracking.transactions.length === 0 && (
          <>
            <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
            <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
            <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
            <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
            <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
            <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
          </>
        )}

        <Box hidden={tracking.error !== "" || tracking.transactions.length === 0}>
          <Box mb={2}>
            <PopupState variant="popover" popupId={`search-action`}>
              {(popupState) => (
                <React.Fragment>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    endIcon={<ArrowDropDown />}
                    {...bindTrigger(popupState)}
                  >
                    Actions
                  </Button>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          copyCSV();
                        }}
                      >
                        <ListItemText primary="Copy (CSV)" />
                      </MenuItem>
                    </Menu>
                  </Popover>
                </React.Fragment>
              )}
            </PopupState>
          </Box>
          <TableContainer component={Paper}>
            <Table className="sticky">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} className="sticky" style={{ padding: 0, margin: 0, zIndex: 999 }}>
                    <FetchingProgress pad={false} isFetching={tracking.isFetching} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="transaction sticky date">Date</TableCell>
                  <TableCell className="transaction sticky description">Description</TableCell>
                  <TableCell className="transaction sticky type">Type</TableCell>
                  <TableCell className="transaction sticky amount">Amount</TableCell>
                  {!extraSmall && (
                    <TableCell className="transaction sticky cleared">
                      <Box hidden={extraSmall}>Cleared</Box>
                      <Box hidden={!extraSmall}>
                        <LibraryAddCheck />
                      </Box>
                    </TableCell>
                  )}
                  {!extraSmall && !hideEnteredBy && <TableCell className="transaction sticky enteredBy"></TableCell>}
                  {canUpdateTransaction && <TableCell className="transaction sticky menu"></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {tracking.transactions.map((x, i) => (
                  <TableRow key={`transaction${i}`} className={x.cleared ? "cleared" : "uncleared"} hover>
                    <TableCell className="transaction date">{moment(x.date).format("MMM D YYYY")}</TableCell>
                    <TableCell className="transaction description">
                      <Box>
                        {x.description} {x.tags.length > 0 && `[${x.tags.join(", ")}]`}
                      </Box>
                      <Box fontSize="0.9em">{x.amounts.map((y) => y.category).join(", ")}</Box>
                    </TableCell>
                    <TableCell className="transaction type">{directionMap[x.direction]}</TableCell>
                    <TableCell className="transaction amount">
                      <Amount greenGood={x.direction === "CREDIT"} amount={x.amount} bold />
                    </TableCell>
                    {!extraSmall && (
                      <TableCell className="transaction cleared">
                        <Checkbox
                          checked={x.cleared}
                          onChange={() => {
                            updateCleared(x.id, !x.cleared);
                          }}
                          disabled={!canUpdateTransaction}
                          color="default"
                          inputProps={{ "aria-label": x.cleared ? "Transaction Cleared" : "Transaction Pending" }}
                        />
                      </TableCell>
                    )}
                    {!extraSmall && !hideEnteredBy && (
                      <TableCell className="transaction enteredBy">
                        <Tooltip title={tracking?.avatars[x.enteredBy]?.fullName}>
                          <Avatar src={tracking?.avatars[x.enteredBy]?.avatar} alt={tracking?.avatars[x.enteredBy]?.fullName}>
                            {tracking?.avatars[x.enteredBy]?.alt}
                          </Avatar>
                        </Tooltip>
                      </TableCell>
                    )}
                    {canUpdateTransaction && (
                      <TableCell className="transactionMenu">
                        <PopupState variant="popover" popupId={`tracking-menu-${i}`}>
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                <ArrowDropDown />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => editTransaction(popupState, x.id)}>
                                  <ListItemIcon>
                                    <Edit />
                                  </ListItemIcon>
                                  <ListItemText primary="Edit" />
                                </MenuItem>
                                <MenuItem onClick={() => removalConfirmation(popupState, x.id)}>
                                  <ListItemIcon>
                                    <Delete />
                                  </ListItemIcon>
                                  <ListItemText primary="Remove" />
                                </MenuItem>
                                {x.cleared && (
                                  <MenuItem
                                    onClick={() => {
                                      updateCleared(x.id, !x.cleared);
                                      popupState.close();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Checkbox />
                                    </ListItemIcon>
                                    <ListItemText primary="Pending" />
                                  </MenuItem>
                                )}
                                {!x.cleared && (
                                  <MenuItem
                                    onClick={() => {
                                      updateCleared(x.id, !x.cleared);
                                      popupState.close();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <CheckBoxOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Clear" />
                                  </MenuItem>
                                )}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box hidden={tracking.isDirty || tracking.isFetching || tracking.error !== "" || tracking.transactions.length > 0}>
          <Alert severity="info">No transactions in this pay period, yet...</Alert>
        </Box>

        <Confirmation
          open={removalConfirmationOpen}
          cancel={() => setRemovalConfirmationOpen(false)}
          submit={removalConfirmationSubmit}
          message="Are you sure you want to remove this transaction?"
        />

        <EditTransaction
          open={editOpen}
          cancel={() => setEditOpen(false)}
          submit={submitTransaction}
          accounts={transactionToEdit.accounts}
          payPeriods={transactionToEdit.payPeriods}
          transaction={transactionToEdit.transaction}
          typeAhead={transactionToEdit.typeAhead}
          categories={transactionToEdit.categories}
          tags={mergeArrays(transactionToEdit.settings.tags, transactionToEdit.transaction.tags)}
          title="Edit Transaction"
        />

        <SnackMessage dispatch={dispatch} snack={tracking.snack} />
      </Loading>
    </SidebarLayout>
  );
};

export default Tracking;

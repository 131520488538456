import { validDate, validDescription } from '@budgetocity-internal/validation-library'

const validate = values => {
  var errors = {}

  if (!validDate(values.date).passed) {
    errors = {...errors, date: "Please enter a valid date"}
  }

  if (values?.amount === "") {
    errors = {...errors, amount: "Invalid amount"}
  }

  if (!validDescription(values.description.trim()).passed) {
    errors = {...errors, description: "Please enter a description"}
  }

  if (values.amounts) {
    for (const [i, a] of values.amounts.entries()) {
      if (a?.amount === "") {
        errors = {...errors, [`amount${i}`]: "Invalid amount"}
      }

      if (!a.category) {
        errors = {...errors, [`category${i}`]: "Please choose a category"}
      }
    }
  }
  return errors
}

export default validate
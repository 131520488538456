import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
} from "@aws-sdk/client-cognito-identity";
import Analytics from "analytics";
import awsPinpointPlugin from "@analytics/aws-pinpoint";
import { baseUrl } from "./api";
import { storage } from "auth";

var credentials;

const getCredentials = async () => {
  const login = storage.getItem(`${baseUrl.cookieScope}.login`)
  if (credentials && (credentials.Expiration?.getDate() || Number.MAX_SAFE_INTEGER) < Date.now()) {
    return {
      accessKeyId: credentials.AccessKeyId,
      secretAccessKey: credentials.SecretKey,
      sessionToken: credentials.SessionToken,
    };
  } else {
    try {
      const client = new CognitoIdentityClient({
        apiVersion: "latest",
        region: "us-east-1",
      });

      var cognitoId = storage.getItem(`${login}:cid`);
      if (!cognitoId) {
        const idResult = await client.send(
          new GetIdCommand({
            IdentityPoolId: baseUrl.identityPool,
            Logins: {
              [baseUrl.cognitoPool]: storage.getItem(`${baseUrl.cookieScope}.id_token`),
            },
          })
        );
        cognitoId = idResult.IdentityId;
        storage.setItem(`${login}:cid`, cognitoId);
      }

      var result = await client.send(
        new GetCredentialsForIdentityCommand({
          IdentityId: cognitoId,
          Logins: {
            [baseUrl.cognitoPool]: storage.getItem(`${baseUrl.cookieScope}.id_token`),
          },
        })
      );
      credentials = result.Credentials || null;
      return {
        accessKeyId: result.Credentials?.AccessKeyId,
        secretAccessKey: result.Credentials?.SecretKey,
        sessionToken: result.Credentials?.SessionToken,
      };
    } catch (error) {
      console.log(error);
    }
  }
};

export const analytics = Analytics({
  app: "budgetocity-webapp",
  plugins: [
    awsPinpointPlugin({
      pinpointAppId: baseUrl.pinPoint,
      getCredentials: async () => await getCredentials(),
    }),
  ],
});

const useAnalytics = () => analytics;

export default useAnalytics;

import { reducer as snackReducer } from 'components/SnackMessage'

const DEFAULT_STATE = {
  accounts: [],
  accountLinks: [],
  tags: [],
  error: "",
  returnPage: "/",
  snack: { type: "", message: "" }
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "ACCOUNTS_FULFILLED": {
      return {
        ...state,
        tags: action?.payload?.settings?.tags,
        accounts: action?.payload?.accounts,
        accountLinks: action?.payload?.accountLinks?.map(a => {
          var status = "Connected"
          var loggedOut = false
          if (a?.status !== "NO_ERROR") {
            if (a?.error?.code === "ITEM_LOGIN_REQUIRED") {
              status = "Logged Out"
              loggedOut = true
            } else {
              status = `Error (${a?.error?.code || "unknown"})`
            }
          }
          return {
            ...a,
            status,
            loggedOut
          }
        })
      }
    }
    case "ACCOUNTS_REJECTED": {
      return {
        ...state,
        error: action.payload
      }
    }
    case "RETURN_PAGE": {
      return {
        ...state,
        returnPage: action.payload
      }
    }
    case "UPDATE_ACCOUNT": {
      return {
        ...state,
        accounts: action.payload
      }
    }
    default:
      return snackReducer(state, action)
  }
}
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Link
} from "@material-ui/core";
import { Add, ArrowDropDown, CategoryTwoTone, Edit, Settings } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { lazy, Suspense, useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { baseUrl, query } from "app/api";
import { storage, useAuthorizationWrite } from "auth";
import Amount from "components/Amount";
import CategoryDonutChart from "components/CategoryDonutChart";
import Loading from "components/Loading";
import { EMPTY_TRANSACTION } from "components/Sidebar";
import { newTransactionMutation, newTransactionQuery, updateAccountBalances } from "components/Sidebar/queries";
import SingleLayout from "components/SingleLayout";
import SnackMessage from "components/SnackMessage";

import { Balance } from "../Budget/common";
import { updateBudgetItemMutation } from "../Budget/queries";
import { updateSavingsBalances } from "../Savings/queries";
import EditTransaction from "../Tracking/EditTransaction";
import { plannerQuery } from "../Planner/queries";
import { balancesQuery, querySummaryForEdit, summaryQuery, updateSummaryMutation } from "./queries";

const EditSummary = lazy(() => import("./EditSummary"));

const Summary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useSelector((state) => state.auth);
  const state = useSelector((state) => ({ ...state.stateManager.summary, ...state.summary }));

  const canUpdateSummary = useAuthorizationWrite("Summary");

  const [editOpen, setEditOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [summaryToEdit, setSummaryToEdit] = useState({ summary: { budgetRemaining: [] }, categories: [] });

  const [newOpen, setNewOpen] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(EMPTY_TRANSACTION);

  const canUpdateTransaction = useAuthorizationWrite("Transaction");

  useEffect(() => {
    if (!state.isFetching && state.isDirty) {
      dispatch({ type: "SUMMARY", payload: query(auth, summaryQuery, {}) });
      dispatch({ type: "BALANCES", payload: query(auth, balancesQuery, {}) });
      dispatch({ type: "SUMMARYPLANNER", payload: query(auth, plannerQuery, {}) });
    }
  }, [dispatch, auth, state]);

  const editSummary = (popupState) => {
    popupState.close();
    setProcessing(true);
    query(auth, querySummaryForEdit)
      .then((s) => {
        setSummaryToEdit(s);
        setEditOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setProcessing(false));
  };

  const submitSummary = (summary) => {
    return query(auth, updateSummaryMutation, {
      summary
    }).then((_) => {
      dispatch({ type: "SET_SNACK", success: "Complete" });
      dispatch({ type: "SUMMARY_DIRTY" });
      setEditOpen(false);
    });
  };

  const newTransaction = () => {
    setProcessing(true);
    query(auth, newTransactionQuery)
      .then((t) => {
        setTransactionToEdit({
          ...t,
          transaction: {
            ...EMPTY_TRANSACTION.transaction,
            date: moment().format("YYYY-MM-DD"),
            account: t.accounts.find((a) => a.defaultAccount)?.name,
            payPeriod: t.payPeriods.find((p) => p.isCurrent)?.name
          }
        });
        setNewOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setProcessing(false));
  };

  const submitTransaction = (transaction) => {
    const finalTransaction = {
      payPeriod: transaction.payPeriod,
      account: transaction.account,
      date: transaction.date,
      description: transaction.description,
      amounts: transaction.amounts,
      direction: transaction.direction,
      cleared: transaction.cleared,
      tags: transaction.tags
    };

    return query(auth, newTransactionMutation, { input: finalTransaction }).then(async () => {
      try {
        query(auth, updateSavingsBalances, {});

        await query(auth, updateAccountBalances, { account: transaction.account, payPeriod: transaction.payPeriod });

        dispatch({ type: "SET_SNACK", success: "Complete" });
        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SUMMARY_DIRTY" });
        setNewOpen(false);
      } catch (ex) {
        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SET_SNACK", error: ex });
        setNewOpen(false);
      }
    });
  };

  const navigateWithReturn = (destination) => {
    dispatch({ type: "RETURN_PAGE", payload: history.location.pathname });
    history.push(destination);
  };

  const listTransactions = (category) => {
    dispatch({ type: "NEW_FILTER", payload: `category:${category}` });
    history.push("/tracking");
  };

  const updateBudgetItem = (category, amount, ignore) => {
    setProcessing(true);
    if (amount !== undefined) {
      dispatch({
        type: "OPTIMISTIC_BUDGET_UPDATE",
        payload: {
          category,
          amount
        }
      });
    }

    dispatch({
      type: "UPDATE_BUDGET",
      payload: query(auth, updateBudgetItemMutation, {
        payPeriod: "",
        category,
        amount: amount !== undefined ? Number(amount) : undefined,
        ignore
      })
    })
      .then(() => {
        dispatch({ type: "SET_SNACK", success: "Complete" });
        dispatch({ type: "SUMMARY_DIRTY" });
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleToBeta = () => {
    storage.setItem(`${baseUrl.cookieScope}.beta`, "true");
    window.location.href = baseUrl.altDomain;
  };

  return (
    <SingleLayout title="Summary" fullWidth maxWidth="md">
      <Loading loading={processing || state.isDirty || state.isFetching} error={state.error}>
        {(state.isDirty || state.isFetching) && state?.summary?.budgetRemaining?.length === 0 && (
          <Paper>
            <Box display="flex" justifyContent="flex-end" py={2} mr={2}></Box>
            <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="flex-end" px={{ xs: 0, sm: 2 }} py={2} pb={4}>
              <Skeleton variant="circle" width={150} height={150} style={{ margin: "16px" }} />
              <Skeleton variant="circle" width={150} height={150} style={{ margin: "16px" }} />
              <Skeleton variant="circle" width={150} height={150} style={{ margin: "16px" }} />
              <Skeleton variant="circle" width={150} height={150} style={{ margin: "16px" }} />
            </Box>
          </Paper>
        )}

        {((!state.isDirty && !state.isFetching) || state?.summary?.budgetRemaining?.length !== 0) && (
          <>
            <Box mb={2} alignItems="center">
              <Alert severity="info">
                <Typography>
                  The <strong>Beta</strong> version of Budgetocity is available! To try it out, click{" "}
                  <Link onClick={handleToBeta}>here</Link>.
                </Typography>
                <Typography variant="body2">
                  If something isn't working as expected, you can always return to the stable version.
                </Typography>
              </Alert>
            </Box>
            <Grid container spacing={2} justify="flex-start">
              <Grid item xs={12}>
                {canUpdateTransaction && fullScreen && (
                  <Tooltip title="New Transaction" placement="left" arrow>
                    <Fab
                      variant="extended"
                      color="primary"
                      aria-label="New Transaction"
                      style={{ position: "fixed", right: theme.spacing(6), bottom: theme.spacing(8), zIndex: 190 }}
                      onClick={() => newTransaction()}
                    >
                      <Add style={{ marginRight: theme.spacing(1) }} />
                      Transaction
                    </Fab>
                  </Tooltip>
                )}
                <Paper>
                  <Box display="flex" justifyContent="flex-end" py={2} mr={2}>
                    {canUpdateSummary && (
                      <PopupState variant="popover" popupId={`summary-settings`}>
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                              <Settings />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  editSummary(popupState);
                                }}
                              >
                                <ListItemIcon>
                                  <Edit />
                                </ListItemIcon>
                                <ListItemText primary="Edit Summary" />
                              </MenuItem>
                              <MenuItem onClick={() => navigateWithReturn("/categories")}>
                                <ListItemIcon>
                                  <CategoryTwoTone />
                                </ListItemIcon>
                                <ListItemText primary="Edit Categories" />
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    )}
                  </Box>
                  {state?.summary?.budgetRemaining?.length === 0 && (
                    <Box p={3}>
                      <Alert severity="info">
                        You have no categories selected for your summary. You can click the "settings" icon above to add
                        categories to your summary screen.
                      </Alert>
                    </Box>
                  )}

                  {state?.summary?.budgetRemaining?.length > 0 && (
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      alignItems="flex-end"
                      px={{ xs: 0, sm: 2 }}
                      py={3}
                    >
                      {state.summary.budgetRemaining.map((x, i) => (
                        <CategoryDonutChart
                          key={`summary${i}`}
                          category={x.category}
                          amount={x.amount}
                          remaining={x.remaining}
                          percentage={x.percentage}
                        />
                      ))}
                    </Box>
                  )}
                </Paper>
              </Grid>
              {state?.summary?.alerts?.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Paper>
                    <Box p={3}>
                      <Typography variant="h5" gutterBottom>
                        Budget Alerts
                      </Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="spendingDescription">Category</TableCell>
                            <TableCell className="spendingAmount">Budget</TableCell>
                            <TableCell className="spendingRemaining">Remaining</TableCell>
                            <TableCell className="spendingMenu"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state?.summary?.alerts?.map((row, i) => (
                            <TableRow key={`alert${i}`}>
                              <TableCell className="spendingDescription">{row.category}</TableCell>
                              <TableCell className="spendingAmount">
                                <Amount amount={row.amount} />
                              </TableCell>
                              <TableCell className={`spendingRemaining`}>
                                <Amount amount={row.remaining} redBad />
                              </TableCell>
                              <TableCell className="spendingMenu">
                                <PopupState variant="popover" popupId={`spending-menu-${i}`}>
                                  {(popupState) => (
                                    <React.Fragment>
                                      <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                        <ArrowDropDown />
                                      </IconButton>
                                      <Menu {...bindMenu(popupState)}>
                                        <MenuItem
                                          onClick={() => {
                                            popupState.close();
                                            listTransactions(row.category);
                                          }}
                                        >
                                          <ListItemIcon>
                                            <FontAwesomeIcon icon={faFilter} />
                                          </ListItemIcon>
                                          <ListItemText primary="List Transactions" />
                                        </MenuItem>
                                        <Balance
                                          popupState={popupState}
                                          category={row.category}
                                          amount={row.amount - row.remaining}
                                          updateBudgetItem={updateBudgetItem}
                                        />
                                      </Menu>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Paper>
                </Grid>
              )}
              {state?.plannedEvents?.length > 0 && (
                <Grid item xs={12} md={state?.summary?.alerts?.length > 0 ? 6 : 12}>
                  <Paper>
                    <Box p={3}>
                      <Typography variant="h5" gutterBottom>
                        Upcoming Plan
                      </Typography>
                      <Table>
                        <TableBody>
                          {state?.plannedEvents?.map((row, i) => (
                            <TableRow key={`events${i}`}>
                              <TableCell className="eventDate">{moment(row.date).format("MMM DD")}</TableCell>
                              <TableCell className="eventDescription">{row.description}</TableCell>
                              <TableCell className="amount" align="right">
                                <Amount amount={row.amounts[0].amount} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center">
                      <Button color="primary" variant="contained" onClick={() => history.push("/planner")}>
                        Go To Planner
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              )}
              {state?.accountBalances?.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Paper>
                    <Box p={3}>
                      <Typography variant="h5" gutterBottom>
                        Account Balances
                      </Typography>
                      <Table>
                        <TableBody>
                          {state?.accountBalances?.map((row, i) => (
                            <TableRow key={`balance${i}`}>
                              <TableCell className="spendingDescription">{row.account}</TableCell>
                              <TableCell className="spendingAmount">
                                <Amount amount={row.currentBalance} redBad />
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell className="spendingDescription">Budget Balance</TableCell>
                            <TableCell className="spendingAmount">
                              <Amount amount={state?.budgetBalance?.difference} bold greenGood redBad />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    {canUpdateTransaction && !fullScreen && (
                      <Box p={2} display="flex" justifyContent="center">
                        <Button color="primary" variant="contained" startIcon={<Add />} onClick={newTransaction}>
                          New Transaction
                        </Button>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Carousel interval={15000}>
                  <Card>
                    <CardActionArea onClick={() => history.push("/settings")}>
                      <CardMedia style={{ height: 140 }} image="/images/image-from-rawpixel-id-3820989-v1.jpg" />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Partner Up
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                          Invite your spouse, your signifigant other, or your budget partner to log into your account! Simply
                          share your account with them!
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Click to add a share your account!
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card>
                    <CardActionArea onClick={() => history.push("/planner")}>
                      <CardMedia style={{ height: 140 }} image="/images/image-from-rawpixel-id-2311398.jpg" />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Start with the Plan
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                          The key to an easy budget starts with a good plan! Grab your paystubs and your bills and start entering
                          them in as <strong>Events</strong> in the planner!
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Click to work on your plan!
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card>
                    <CardActionArea onClick={() => history.push("/budget")}>
                      <CardMedia style={{ height: 140 }} image="/images/image-from-rawpixel-id-378660.jpg" />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          No Dollar Left Behind™
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                          Tell every dollar you earn where to go! Save first, then spend. A <strong>budget blance</strong> of{" "}
                          <strong>$0</strong> means you've successfully told every dollar where to go!
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Click to work on your budget!
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Carousel>
              </Grid>
            </Grid>
          </>
        )}

        <EditTransaction
          open={newOpen}
          cancel={() => setNewOpen(false)}
          submit={submitTransaction}
          accounts={transactionToEdit.accounts}
          payPeriods={transactionToEdit.payPeriods}
          transaction={transactionToEdit.transaction}
          typeAhead={transactionToEdit.typeAhead}
          categories={transactionToEdit.categories}
          tags={transactionToEdit.settings.tags}
          title="New Transaction"
        />

        {editOpen && (
          <Suspense>
            <EditSummary
              open={editOpen}
              cancel={() => setEditOpen(false)}
              submit={submitSummary}
              budgetRemaining={summaryToEdit.summary.budgetRemaining}
              categories={summaryToEdit.categories}
            />
          </Suspense>
        )}

        <SnackMessage dispatch={dispatch} snack={state.snack} />
      </Loading>
    </SingleLayout>
  );
};

export default Summary;

import React from "react"

const mapping = {
  "BROKERAGE": "Brokerage",
  "CASH_ISA": "Cash ISA",
  "EDUCATION_SAVINGS_ACCOUNT": "Education Savings Account",
  "HEALTH_REIMBURSEMENT_ARRANGEMENT": "Health Reimbursement Arrangement",
  "NON-TAXABLE_BROKERAGE_ACCOUNT": "Non-Taxable Brokerage Account",
  "OTHER": "Other",
  "PENSION": "Pension",
  "PROFIT_SHARING_PLAN": "Profit Sharing Plan",
  "RETIREMENT": "Retirement",
  "ROTH": "Roth",
  "ROTH_401K": "Roth 401K",
  "SEP_IRA": "SEP IRA",
  "SIMPLE_IRA": "Simple IRA",
  "STOCK_PLAN": "Stock Plan",
  "THRIFT_SAVINGS_PLAN": "Thrift Savings Plan",
  "TRUST": "Trust",
  "VARIABLE_ANNUITY": "Variable Annuity",
  "CREDIT_CARD": "Credit Card",
  "PAYPAL": "PayPal",
  "CHECKING": "Checking",
  "SAVINGS": "Savings",
  "MONEY_MARKET": "Money Market",
  "PREPAID": "Prepaid",
  "AUTO": "Auto",
  "COMMERCIAL": "Commercial",
  "CONSTRUCTION": "Construction",
  "CONSUMER": "Consumer",
  "HOME": "Home",
  "HOME_EQUITY": "Home Equity",
  "LOAN": "Loan",
  "MORTGAGE": "Mortgage",
  "OVERDRAFT": "Overdraft",
  "LINE_OF_CREDIT": "Line of Credit",
  "STUDENT": "Student",
  "CASH_MANAGEMENT": "Cash Management",
  "MUTUAL_FUND": "Mutual Fund",
  "RECURRING": "Recurring",
  "REWARDS": "Rewards",
  "SAFE_DEPOSIT": "Safe Deposit"
}

const TypeFormat = ({children}) => (
  <div>{mapping[children]?mapping[children]:children}</div>
)

export default TypeFormat
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { deepCopy } from "app/util";
import AmountEditable from "components/AmountEditable";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import validate from "./validate";

const mapCategories = (groups) => {
  return groups.reduce((p, c) => [...p, ...c.categories.map((y) => ({ group: c.name, name: y.name }))], []);
};

const EditEvent = ({ open, cancel, submit, title, event: inputEvent, categories, tags }) => {
  const theme = useTheme();
  const [event, updateEvent] = useState(deepCopy(inputEvent));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    updateEvent(deepCopy(inputEvent));
  }, [inputEvent, updateEvent]);

  const submitHandler = (values, { resetForm, setSubmitting, setErrors }) => {
    const submitValue = {
      id: values.id,
      type: values.type,
      startDate: values.startDate,
      description: values.description.trim(),
      direction: values.direction,
      day: values.day,
      amounts: values.amounts,
      tags: values.tags,
      link: values.link,
    };

    submit(submitValue)
      .then(() => {
        resetForm();
      })
      .catch((ex) => {
        setSubmitting(false);
        setErrors({ global: ex });
      });
  };

  return (
    <Formik initialValues={event} enableReinitialize validate={validate} onSubmit={submitHandler}>
      {({
        values,
        errors,
        touched,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="sm" fullWidth aria-labelledby="edit-dialog-title">
          <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box display="flex" mb={2} mr={4}>
                <Box width="30%">
                  <FormControl color="secondary" margin="normal">
                    <InputLabel id="account-label">Repeats</InputLabel>
                    <Select
                      name="type"
                      labelId="type-label"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.type && touched.type}
                      value={values.type}
                    >
                      <MenuItem value={"BUDGET"}>Every Budget</MenuItem>
                      <MenuItem value={"WEEK"}>Every Week</MenuItem>
                      <MenuItem value={"BIWEEK"}>Every Two Weeks</MenuItem>
                      <MenuItem value={"QUADWEEK"}>Every Four Weeks</MenuItem>
                      <MenuItem value={"MONTH"}>Every Month</MenuItem>
                      <MenuItem value={"BIMONTH"}>Every Two Months</MenuItem>
                      <MenuItem value={"QUARTER"}>Quarterly</MenuItem>
                      <MenuItem value={"TRIANNUAL"}>Every 4 Months</MenuItem>
                      <MenuItem value={"BIANNUAL"}>Every 6 Months</MenuItem>
                      <MenuItem value={"YEAR"}>Once a Year</MenuItem>
                      <MenuItem value={"EOM"}>End of Each Month</MenuItem>
                      <MenuItem value={"DAY"}>Every "X" Days</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box width="5%"></Box>

                <Box width="30%">
                  <Box hidden={values.type !== "DAY"}>
                    <TextField
                      name="day"
                      label="Days"
                      type="number"
                      color="secondary"
                      margin="normal"
                      style={{width: "50px"}}
                      inputProps={{
                        min: 1,
                        max: 90,
                      }}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.day}
                      error={errors.day && touched.day}
                      helperText={touched.day && errors.day}
                    />
                  </Box>
                </Box>

                <Box width="5%"></Box>

                <Box width="20%" hidden={values.type === "EOM" || values.type === "BUDGET"}>
                  <DatePicker
                    label="Start Date"
                    value={values.startDate}
                    format={"MMM DD, YYYY"}
                    margin="normal"
                    autoOk
                    onChange={(date) => {
                      setFieldValue("startDate", date.format("YYYY-MM-DD"));
                    }}
                    variant="inline"
                    disabled={isSubmitting}
                    error={errors.startDate && touched.startDate}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Box>
              </Box>

              <Box display="flex" mb={2} mr={4}>
                <Box width="65%">
                  <TextField
                    name="description"
                    label="Description"
                    color="secondary"
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    error={errors.description && touched.description}
                    helperText={touched.description && errors.description}
                  />
                </Box>
                <Box width="5%" />
                <Box width="30%">
                  <AmountEditable
                    label="Amount"
                    emptyStart
                    amount={values.amounts[0].amount}
                    margin="normal"
                    disabled={isSubmitting}
                    fullWidth
                    onBlur={() => {
                      setFieldTouched("amount");
                      setFieldTouched(`amounts0`);
                    }}
                    onChange={(x) => {
                      var amounts = values.amounts;
                      amounts[0].amount = x;
                      setFieldValue("amounts", amounts);
                      setTimeout(() => {
                        setFieldTouched(`amounts0`);
                      }, 2);
                    }}
                    error={errors.amount && touched.amount}
                    helperText={touched.amount && errors.amount}
                  />
                </Box>
              </Box>

              <Box display="flex" mb={2} mr={4}>
                <Box flexGrow={1}>
                  <Autocomplete
                    options={mapCategories(categories)}
                    groupBy={(c) => c.group}
                    disableClearable
                    autoHighlight
                    getOptionLabel={(c) => (c.name ? c.name : c)}
                    getOptionSelected={(o, v) => o.name === v}
                    value={values.amounts[0].category}
                    onChange={(_, ac) => {
                      var amounts = values.amounts;
                      amounts[0].category = ac ? ac.name : "";
                      setFieldValue("amounts", amounts);

                      setFieldValue("direction", ac.group === "Income" ? "CREDIT" : "DEBIT");
                    }}
                    renderInput={(x) => (
                      <TextField
                        {...x}
                        name={`category`}
                        label="Category"
                        margin="normal"
                        fullWidth
                        color="secondary"
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.category && touched.category}
                        helperText={touched.category && errors.category}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box display="flex" mb={2} mr={4}>
                <Box mr={3}>
                  <FormControl color="secondary" margin="normal">
                    <InputLabel id="direction-label">Direction</InputLabel>
                    <Select
                      name="direction"
                      labelId="direction-label"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.direction && touched.direction}
                      value={values.direction}
                    >
                      <MenuItem value="CREDIT">Income</MenuItem>
                      <MenuItem value="DEBIT">Spending</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box flexGrow={1}>
                  <Autocomplete
                    multiple
                    options={tags}
                    value={values.tags}
                    freeSolo
                    fullWidth
                    onChange={(_, t) => {
                      setFieldValue("tags", t);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map
                        ? value.map((option, index) => (
                            <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        : [value]
                    }
                    renderInput={(x) => (
                      <TextField
                        {...x}
                        name="tags"
                        label="Tags"
                        color="secondary"
                        margin="normal"
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        error={errors.tags && touched.tags}
                        helperText={touched.tags && errors.tags}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box flexGrow={1}>
                <TextField
                  name="link"
                  label="Link"
                  color="secondary"
                  fullWidth
                  margin="normal"
                  disabled={isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.link}
                  error={errors.link && touched.link}
                  helperText={touched.link && errors.link}
                />
              </Box>

              <Box hidden={!errors.global || errors.global === ""}>
                <Alert severity="error" onClose={() => setFieldError("global", "")}>
                  {errors.global}
                </Alert>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  cancel();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={!isValid || isSubmitting}>
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default EditEvent;

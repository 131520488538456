import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown, CategoryTwoTone, ErrorOutline, FlagTwoTone, Settings } from "@material-ui/icons";
import { useAuthorizationWrite } from "auth";
import Amount from "components/Amount";
import AmountEditable from "components/AmountEditable";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import { Balance, CarryOver, Clear, UsePlan } from "./common";

const TableIncomeSection = ({ budget, updateBudgetItem, listTransactions, isCurrent, navigateWithReturn }) => {
  const canUpdateBudget = useAuthorizationWrite("Budget");

  return (
    <>
      {budget.income[0] && (
        <>
          <Box ml={1} display="flex" hidden={budget.income.length === 0}>
            <Box flexGrow={1}>
              <Typography variant="h4" gutterBottom>
                Income
              </Typography>
            </Box>
            {canUpdateBudget && (
              <PopupState variant="popover" popupId={`summary-settings`}>
                {(popupState) => (
                  <React.Fragment>
                    <IconButton variant="contained" {...bindTrigger(popupState)}>
                      <Settings />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={() => navigateWithReturn("/categories")}>
                        <ListItemIcon>
                          <CategoryTwoTone />
                        </ListItemIcon>
                        <ListItemText primary="Edit Categories" />
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            )}
          </Box>
          <TableContainer component={Paper}>
            <Table className="sticky">
              <TableHead>
                <TableRow>
                  <TableCell className="sticky">Category</TableCell>
                  <TableCell className="sticky"></TableCell>
                  <TableCell className="sticky" align="right">
                    Budget
                  </TableCell>
                  <TableCell className="sticky" align="right">
                    Deposit
                  </TableCell>
                  <TableCell className="sticky"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {budget.income[0].categories.map((row, i) => {
                  var carryOverStatus = "";
                  if (isCurrent && row.type === "CARRYOVER" && budget.potentialCarryover > row.amount) {
                    carryOverStatus = "MISSING";
                  }
                  if (isCurrent && row.type === "CARRYOVER" && budget.potentialCarryover < row.amount) {
                    carryOverStatus = "UNEXPECTED";
                  }
                  return (
                    <TableRow key={`income${i}`} hover className={`income ${row.status} ${carryOverStatus}`}>
                      <TableCell>{row.category}</TableCell>
                      <TableCell style={{ width: "1%", paddingRight: 0 }}>
                        {isCurrent && row.type === "CARRYOVER" && budget.potentialCarryover > row.amount && (
                          <PopupState variant="popover" popupId={`income-menu-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <FlagTwoTone />
                                </IconButton>
                                {canUpdateBudget && (
                                  <Menu {...bindMenu(popupState)}>
                                    <CarryOver
                                      popupState={popupState}
                                      category={row.category}
                                      amount={budget.potentialCarryover}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        )}
                        {isCurrent && row.type === "CARRYOVER" && budget.potentialCarryover < row.amount && (
                          <PopupState variant="popover" popupId={`income-menu-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <ErrorOutline />
                                </IconButton>
                                {canUpdateBudget && (
                                  <Menu {...bindMenu(popupState)}>
                                    <CarryOver
                                      popupState={popupState}
                                      category={row.category}
                                      amount={budget.potentialCarryover}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        )}
                        {row.status === "MISSING" && (
                          <PopupState variant="popover" popupId={`income-menu-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <FlagTwoTone />
                                </IconButton>
                                {canUpdateBudget && (
                                  <Menu {...bindMenu(popupState)}>
                                    <UsePlan
                                      popupState={popupState}
                                      category={row.category}
                                      amount={row.plannedAmount}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        )}
                        {row.status === "UNEXPECTED" && (
                          <PopupState variant="popover" popupId={`income-menu-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <ErrorOutline />
                                </IconButton>
                                {canUpdateBudget && (
                                  <Menu {...bindMenu(popupState)}>
                                    <Clear
                                      popupState={popupState}
                                      category={row.category}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        )}
                      </TableCell>
                      <TableCell align="right" style={{ width: "1%", paddingLeft: "3px" }}>
                        {row.type !== "CARRYOVER" && canUpdateBudget ? (
                          <AmountEditable
                            id={`income-amount-${i}`}
                            amount={row.amount}
                            onChange={(amount) => updateBudgetItem(row.category, amount)}
                          />
                        ) : (
                          <Amount amount={row.amount} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Amount amount={row.deposited} />
                      </TableCell>
                      <TableCell style={{ width: "1%", paddingLeft: 0 }}>
                        <PopupState variant="popover" popupId={`income-menu-${i}`}>
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                <ArrowDropDown />
                              </IconButton>
                              {row.type !== "CARRYOVER" && (
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      listTransactions(row.category);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <FontAwesomeIcon icon={faFilter} />
                                    </ListItemIcon>
                                    <ListItemText primary="List Transactions" />
                                  </MenuItem>
                                  {canUpdateBudget && row.amount < row.deposited && (
                                    <Balance
                                      popupState={popupState}
                                      category={row.category}
                                      amount={row.deposited}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  )}
                                  {canUpdateBudget && row.amount > 0 && (
                                    <Clear
                                      popupState={popupState}
                                      category={row.category}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  )}
                                  {canUpdateBudget && row.plannedAmount > 0 && row.amount !== row.plannedAmount && (
                                    <UsePlan
                                      popupState={popupState}
                                      category={row.category}
                                      amount={row.plannedAmount}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  )}
                                </Menu>
                              )}
                              {canUpdateBudget && row.type === "CARRYOVER" && (
                                <Menu {...bindMenu(popupState)}>
                                  <CarryOver
                                    popupState={popupState}
                                    category={row.category}
                                    amount={budget.potentialCarryover}
                                    updateBudgetItem={updateBudgetItem}
                                  />
                                  {row.amount > 0 && (
                                    <Clear
                                      popupState={popupState}
                                      category={row.category}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  )}
                                </Menu>
                              )}
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default TableIncomeSection;

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  Container, Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme
} from "@material-ui/core";
import { ArrowDropDown, Delete, HourglassEmpty, PersonPin, Share, WatchLater } from "@material-ui/icons";
import { Alert, AlertTitle, Autocomplete, Skeleton } from "@material-ui/lab";
import { Formik } from "formik";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { baseUrl, get, put, query } from "app/api";
import { goBack } from "app/util";
import { useAuthorization, useAuthorizationWrite } from "auth";
import Confirmation from "components/Confirmation";
import Loading from "components/Loading";
import SingleLayout from "components/SingleLayout";
import SnackMessage from "components/SnackMessage";

import { LinearProgress } from "@material-ui/core";
import CreateSharingRequest from "./CreateSharingRequest";

import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      250,
      250,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

//const AvatarEdit = React.lazy(() => import("react-avatar-edit"));

export const sharingQuery = `
query {
  sharing {
    shared {
      id
      login
      email
      avatar
      firstName
      lastName
      pending
      expired
      policy {
        defaultAccess
      }
    }
    sharedWithMe {
      id
      login
      email
      avatar
      firstName
      lastName
      pending
      expired
      policy {
        defaultAccess
      }
    }
  }
}
`;

export const acceptSharingMutation = `
mutation updateSharingAccept ($id: String!) {
  updateSharingAccept (id: $id) {
      id
      login
      email
      avatar
      firstName
      lastName
      timestamp
      expired
      pending
      policy {
          defaultAccess
      }
  }
}`;

export const declineSharingMutation = `
mutation updateSharingDecline ($id: String!) {
  updateSharingDecline (id: $id) {
      id
      login
      email
      avatar
      firstName
      lastName
      timestamp
      expired
      pending
      policy {
          defaultAccess
      }
  }
}`;

const settingsQuery = `
  query {
    settings {
      defaultSavingsCategory
      tags
      complete
      planDate
    }
    categories {
      name
      type
      categories {
        name
      }
    }
  }
`;

const settingsWithProfilesQuery = `
  query {
    settings {
      defaultSavingsCategory
      tags
      complete
      planDate
    }
    profiles {
      myProfiles {
        name
        profile
      }
    }
    categories {
      name
      type
      categories {
        name
      }
    }
  }
`;

const createSharingRequestMutation = `
mutation createSharingRequest ($email: String!) {
  createSharingRequest (email: $email) {
      id
      login
      email
      avatar
      firstName
      lastName
      timestamp
      expired
      pending
      policy {
          defaultAccess
      }
  }
}`;

const removeSharingMutation = `
mutation removeSharing ($login: String!) {
  removeSharing (login: $login) {
    id
    login
    email
    avatar
    firstName
    lastName
    pending
    expired
    policy {
      defaultAccess
    }
  }
}`;

const removeSharingRequestMutation = `
mutation removeSharingRequest ($id: String!) {
  removeSharingRequest (id: $id) {
    id
    login
    email
    avatar
    firstName
    lastName
    pending
    expired
    policy {
      defaultAccess
    }
  }
}`;

const updateSettingsMutation = `
mutation updateSettings($input: UpdateSettingsInput!) {
  updateSettings (input: $input) {
    defaultSavingsCategory
    tags
    planDate
  }
}`;

const mapCategories = (groups) => {
  const savings = groups.filter((g) => g.type === "SAVINGS");
  if (savings.length === 0) {
    return [];
  }

  return savings[0].categories.map((c) => c.name);
};

const determineProfileToEdit = (selectedProfile, authProfile, authLogin) => {
  const profile = selectedProfile || authProfile || "";
  if (!profile.includes(authLogin)) {
    return `${authLogin}#0`;
  } else {
    return profile;
  }
};

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const auth = useSelector((state) => state.auth);
  const state = useSelector((state) => ({ ...state.stateManager.settings, ...state.settings }));
  const selectedProfile = useSelector((state) => state.settings?.selectedProfile);
  const [avatarUpdated, setAvatarUpdated] = useState(false);
  const [preview, setPreview] = useState(state.avatar);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationSubmit, setConfirmationSubmit] = useState(() => {});
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const [createSharingRequestOpen, setCreateSharingRequestOpen] = useState(false);

  const sharingAvailable = useAuthorization("Sharing", "Privileged");
  const canUpdateSettings = useAuthorizationWrite("Settings");
  const profilesAvailable = auth.login === auth.subscriber;

  const avatarInputRef = useRef(null);

  const handleUpload = async (event, values, setFieldValue, setFieldError) => {
    const file = event.target?.files[0];
    const image = await resizeFile(file);
    setPreview(image);
    setAvatarUpdated(true);
  };

  useEffect(() => {
    setPreview(state.avatar);
  }, [setPreview, state.avatar]);

  useEffect(() => {
    const profileToEdit = determineProfileToEdit(selectedProfile, auth?.profile, auth?.subscriber);
    if (!state.isFetching && state.isDirty) {
      var avatarProfile = profileToEdit;
      if (
        profileToEdit.includes(auth?.subscriber) &&
        auth?.login !== auth?.subscriber &&
        profileToEdit.split("#")[1] === "0"
      ) {
        avatarProfile = `${auth?.login}#0`;
      }

      dispatch({
        type: "AVATAR_SETTINGS",
        payload: get(auth, "/avatar", baseUrl.avatar, false, avatarProfile)
      });

      if (profilesAvailable) {
        dispatch({ type: "SETTINGS", payload: query(auth, settingsWithProfilesQuery, {}, profileToEdit) });
      } else {
        dispatch({ type: "SETTINGS", payload: query(auth, settingsQuery, {}, profileToEdit) });
      }

      if (sharingAvailable) {
        dispatch({ type: "SHARING", payload: query(auth, sharingQuery, {}, profileToEdit) });
      }
    }
  }, [dispatch, auth, state, selectedProfile, profilesAvailable, sharingAvailable]);

  const resumeSetup = () => {
    const profileToEdit = determineProfileToEdit(selectedProfile, auth?.profile, auth?.subscriber);
    sessionStorage.setItem(`${baseUrl.cookieScope}.${auth.login}.profile`, profileToEdit);
    window.location = "/";
  };

  const submit = async (values, { resetForm, setSubmitting, setErrors }) => {
    const profileToEdit = determineProfileToEdit(selectedProfile, auth?.profile, auth?.subscriber);

    try {
      if (avatarUpdated) {
        var avatarProfile = profileToEdit;
        if (
          profileToEdit.includes(auth?.subscriber) &&
          auth?.login !== auth?.subscriber &&
          profileToEdit.split("#")[1] === "0"
        ) {
          avatarProfile = `${auth?.login}#0`;
        }

        await put(auth, "/avatar", { avatar: preview }, baseUrl.avatar, avatarProfile);
        dispatch({ type: "HEADER_DIRTY" });
      }

      if (canUpdateSettings) {
        await query(
          auth,
          updateSettingsMutation,
          {
            input: {
              defaultSavingsCategory: values.defaultSavingsCategory,
              tags: values.tags,
              planDate: values.planDate
            }
          },
          profileToEdit
        ).then(() => {
          dispatch({ type: "SET_SNACK", success: "Complete" });
          dispatch({ type: "SETTINGS_DIRTY" });
          dispatch({ type: "PLANNER_DIRTY" });
          setSubmitting(false);
          resetForm();
          goBack(history, state.returnPage);
        });
      }
    } catch (ex) {
      setSubmitting(false);
      setErrors({ global: ex.message });
      return;
    }
  };

  const switchProfiles = (profile) => {
    dispatch({
      type: "SELECT_PROFILE",
      payload: profile
    });
    dispatch({
      type: "SETTINGS_DIRTY"
    });
  };

  const submitCreateSharingRequest = (values) => {
    return dispatch({
      type: "CREATE_SHARING_REQUEST",
      payload: query(auth, createSharingRequestMutation, values)
    }).then((_) => {
      dispatch({ type: "PROFILES_DIRTY" });
      dispatch({ type: "SET_SNACK", success: "Complete" });
      setCreateSharingRequestOpen(false);
    });
  };

  const removeSharing = (popupState, login, firstName) => {
    popupState?.close();

    setConfirmationSubmit(() => () => removeSharingSubmit(login));
    setConfirmationTitle("Remove Sharing");
    setConfirmationBody(`Are you sure you want to stop sharing this profile with ${firstName}?`);
    setConfirmationOpen(true);
  };

  const removeSharingSubmit = (login) => {
    setConfirmationOpen(false);
    dispatch({
      type: "REMOVE_SHARING",
      payload: query(auth, removeSharingMutation, { login })
    }).then(() => {
      dispatch({ type: "PROFILES_DIRTY" });
    });
  };

  const removeSharingRequest = (popupState, id) => {
    popupState?.close();

    setConfirmationSubmit(() => () => removeSharingRequestSubmit(id));
    setConfirmationTitle("Remove Sharing Request");
    setConfirmationBody(`Are you sure you want to remove this sharing request?`);
    setConfirmationOpen(true);
  };

  const removeSharingRequestSubmit = (id) => {
    setConfirmationOpen(false);
    dispatch({
      type: "REMOVE_SHARING",
      payload: query(auth, removeSharingRequestMutation, { id })
    }).then(() => {
      dispatch({ type: "PROFILES_DIRTY" });
    });
  };

  const resendSharingRequest = (popupState, email) => {
    popupState?.close();

    setConfirmationSubmit(() => () => resendSharingRequestSubmit(email));
    setConfirmationTitle("Resend Sharing Request");
    setConfirmationBody(`Are you sure you want to resend this sharing request?`);
    setConfirmationOpen(true);
  };

  const resendSharingRequestSubmit = (email) => {
    setConfirmationOpen(false);
    dispatch({
      type: "CREATE_SHARING_REQUEST",
      payload: query(auth, createSharingRequestMutation, { email })
    }).then(() => {
      dispatch({ type: "PROFILES_DIRTY" });
    });
  };

  const isLoading = state.isDirty || state.isFetching;

  return (
    <SingleLayout fullWidth title="Settings" maxWidth="md">
      <Loading loading={isLoading}>
        <Container maxWidth="sm">
          {state.profiles?.length > 1 && (
            <Box mb={2}>
              <AppBar position="static">
                <Tabs
                  value={determineProfileToEdit(selectedProfile, auth?.profile, auth?.subscriber)}
                  aria-label="Select Profile"
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={(_, value) => switchProfiles(value)}
                >
                  {state.profiles.map((profile, i) => (
                    <Tab label={profile.name} key={`tab${i}`} value={profile.profile} />
                  ))}
                </Tabs>
              </AppBar>
            </Box>
          )}

          {isLoading && (
            <Box mb={2}>
              <Paper>
                <Box
                  mt={"2px"}
                  px={{ xs: 4, sm: theme.spacing(2) }}
                  py={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box mx="auto" mt={2} mb={3}>
                    <Skeleton variant="circle" style={{ width: theme.spacing(18), height: theme.spacing(18) }} />
                  </Box>
                  <Box mx="auto" mt={1} mb={3}>
                    <Skeleton variant="rect" style={{ width: theme.spacing(30), height: theme.spacing(5) }} />
                  </Box>
                  <Box mx="auto" mb={3}>
                    <Skeleton variant="rect" style={{ width: theme.spacing(34), height: theme.spacing(10) }} />
                  </Box>
                  <Box mx="auto" mb={3}>
                    <Skeleton variant="rect" style={{ width: theme.spacing(34), height: theme.spacing(8) }} />
                  </Box>
                  <Box ml="auto" mt={1} mb={3}>
                    <Skeleton variant="rect" style={{ width: theme.spacing(20), height: theme.spacing(5) }} />
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}

          {!isLoading && !state?.settings?.complete && (
            <Alert severity="warning">
              <AlertTitle>New Profile</AlertTitle>
              It appears that this profile's setup is not yet complete. Would you like to switch to this profile to
              complete the setup?
              <Box mt={2} py={1} display="flex" flexDirection="row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    resumeSetup();
                  }}
                >
                  Resume Setup
                </Button>
              </Box>
            </Alert>
          )}

          {!isLoading && state?.settingsError && (
            <Box mb={3}>
              <Alert severity="error" variant="filled">
                <AlertTitle>Settings</AlertTitle>
                {state?.settingsError}
              </Alert>
            </Box>
          )}

          {!isLoading && !state?.settingsError && state?.settings?.complete && (
            <>
              <Box mb={2}>
                <Paper>
                  <Formik initialValues={state.settings} onSubmit={submit} enableReinitialize>
                    {({
                      values,
                      errors,
                      touched,
                      setFieldError,
                      setFieldValue,
                      isSubmitting,
                      resetForm,
                      handleSubmit,
                      handleChange,
                      handleBlur
                    }) => (
                      <Box
                        mt={"2px"}
                        px={{ xs: 4, sm: theme.spacing(2) }}
                        py={2}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Box mx="auto" mt={2} mb={3}>
                          <Avatar
                            alt="Avatar"
                            style={{ width: theme.spacing(18), height: theme.spacing(18) }}
                            src={preview ? preview : state.avatar}
                            variant="circular"
                          />
                        </Box>

                        <Box mx="auto" mt={1} mb={3}>
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            multiple={false}
                            ref={avatarInputRef}
                            onChange={(event) => handleUpload(event, values, setFieldValue, setFieldError)}
                          />
                          <Button
                            startIcon={<PersonPin />}
                            variant="contained"
                            color="primary"
                            onClick={() => avatarInputRef?.current?.click()}
                          >
                            Select new profile picture
                          </Button>
                        </Box>

                        <Divider />

                        {canUpdateSettings && (
                          <>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              name="defaultSavingsCategory"
                              label="Default Savings Category"
                              value={values.defaultSavingsCategory}
                              onChange={handleChange}
                              disabled={isSubmitting}
                              onBlur={handleBlur}
                              error={errors.defaultSavingsCategory && touched.defaultSavingsCategory}
                              helperText={touched.defaultSavingsCategory && errors.defaultSavingsCategory}
                            >
                              {mapCategories(state.categories).map((option) => (
                                <MenuItem value={option} key={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              name="planDate"
                              label="When adding planned transactions..."
                              value={values.planDate}
                              onChange={handleChange}
                              disabled={isSubmitting}
                              onBlur={handleBlur}
                              error={errors.planDate && touched.planDate}
                              helperText={touched.planDate && errors.planDate}
                            >
                              <MenuItem value="PLAN_DATE">Use the plan date</MenuItem>
                              <MenuItem value="TODAY">Use today's date</MenuItem>
                            </TextField>

                            <Autocomplete
                              multiple
                              options={values.tags}
                              value={values.tags}
                              freeSolo
                              onChange={(_, t) => {
                                setFieldValue("tags", t);
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map
                                  ? value.map((option, index) => (
                                      <Chip
                                        size="small"
                                        variant="outlined"
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  : [value]
                              }
                              renderInput={(x) => (
                                <TextField
                                  {...x}
                                  name="tags"
                                  label="Tags"
                                  margin="normal"
                                  disabled={isSubmitting}
                                  onBlur={handleBlur}
                                  error={errors.tags && touched.tags}
                                  helperText={touched.tags && errors.tags}
                                />
                              )}
                            />
                          </>
                        )}
                        <Box hidden={!errors.global || errors.global === ""}>
                          <Alert severity="error" variant="filled" onClose={() => setFieldError("global", "")}>
                            {errors.global}
                          </Alert>
                        </Box>

                        <Box ml="auto" mt={{ xs: 8, sm: 3 }} mb={2}>
                          <Button
                            onClick={() => {
                              resetForm();
                              goBack(history, state.returnPage);
                            }}
                            color="secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleSubmit}
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting || !!state?.settingsError}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Formik>
                </Paper>
              </Box>

              {sharingAvailable && (
                <Box display="flex" justifyContent="center" mb={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Share />}
                    onClick={() => setCreateSharingRequestOpen(true)}
                  >
                    Share this profile
                  </Button>
                </Box>
              )}
            </>
          )}
        </Container>
        <Container maxWidth="md">
          {!state?.settings?.isFetching && state?.settings?.complete && state?.sharingError && (
            <Alert severity="warning">
              <AlertTitle>Sharing</AlertTitle>Unable to retrieve sharing data
            </Alert>
          )}
          {state.sharingReloading && <LinearProgress />}
          {!state.sharingReloading && state?.settings?.complete && state?.sharing?.length > 0 && (
            <>
              <Box textAlign="center" width="100%">
                <Typography variant="h4">Shared With...</Typography>
              </Box>

              <Box mb={2} py={2} display="flex" flexWrap="wrap" justifyContent="center">
                {state.sharing.map((p, i) => (
                  <Box m={0.5} key={`shared${i}`} minWidth={{ xs: "100%", md: "280px" }}>
                    <Paper key={`share${i}`}>
                      <Box p={3} display="flex" alignItems="center">
                        <Box pr={3}>
                          <Avatar style={{ width: "64px", height: "64px" }} src={p.avatar} />
                        </Box>
                        <Box flexGrow={1} display="flex" flexDirection="column">
                          {p.login && <Typography variant="h6">{`${p.firstName} ${p.lastName}`}</Typography>}
                          {!p.login && <Typography variant="h6">{`${p.email}`}</Typography>}
                          {p.expired && <Chip className="warning" label="Expired" size="small" icon={<WatchLater />} />}

                          {p.pending && !p.expired && (
                            <Chip className="info" label="Pending" size="small" icon={<HourglassEmpty />} />
                          )}

                          {!p.pending && <Typography variant="body1">{`${p.login}`}</Typography>}
                        </Box>
                        <Box ml={3}>
                          <PopupState variant="popover" popupId={`goals-menu-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <ArrowDropDown />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  {!p.pending && (
                                    <MenuItem onClick={() => removeSharing(popupState, p.login, p.firstName)}>
                                      <ListItemIcon>
                                        <Delete />
                                      </ListItemIcon>
                                      <ListItemText primary="Remove Sharing" />
                                    </MenuItem>
                                  )}
                                  {p.pending && (
                                    <MenuItem onClick={() => removeSharingRequest(popupState, p.id)}>
                                      <ListItemIcon>
                                        <Delete />
                                      </ListItemIcon>
                                      <ListItemText primary="Remove Request" />
                                    </MenuItem>
                                  )}
                                  {p.expired && (
                                    <MenuItem onClick={() => resendSharingRequest(popupState, p.email)}>
                                      <ListItemIcon>
                                        <Delete />
                                      </ListItemIcon>
                                      <ListItemText primary="Resend Request" />
                                    </MenuItem>
                                  )}
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Container>

        <CreateSharingRequest
          open={createSharingRequestOpen}
          cancel={() => setCreateSharingRequestOpen(false)}
          submitShare={submitCreateSharingRequest}
          submitPartner={submitCreateSharingRequest}
        />

        <Confirmation
          open={confirmationOpen}
          cancel={() => setConfirmationOpen(false)}
          submit={confirmationSubmit}
          title={confirmationTitle}
          message={confirmationBody}
        />

        <SnackMessage dispatch={dispatch} snack={state.snack} />
      </Loading>
    </SingleLayout>
  );
};

export default Settings;

import { Box, Button } from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { query } from "app/api";
import { useAuthorizationWrite } from "auth";
import Loading from "components/Loading";
import SidebarLayout from "components/SidebarLayout";
import SnackMessage from "components/SnackMessage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  budgetQuery,
  carryOverBudgetItemMutation,
  updateBudgetItemMutation,
  updateBudgetWithRecommendations,
} from "./queries";
import TableIncomeSection from "./TableIncomeSection";
import TableSpendingSection from "./TableSpendingSection";

const Budget = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const payPeriod = useSelector((state) => state.auth.payPeriod);
  const state = useSelector((state) => ({ ...state.stateManager.budget, ...state.budget }));
  const sidebar = useSelector((state) => state.sidebar);

  const canUpdateBudget = useAuthorizationWrite("Budget");

  useEffect(() => {
    if (payPeriod && !state.isFetching && state.isDirty) {
      dispatch({ type: "BUDGET", payload: query(auth, budgetQuery, { payPeriod }) });
    }
  }, [dispatch, auth, state, payPeriod]);

  const updateBudgetItem = (category, amount, ignore) => {
    if (amount !== undefined) {
      dispatch({
        type: "OPTIMISTIC_BUDGET_UPDATE",
        payload: {
          category,
          amount,
        },
      });
    }

    dispatch({
      type: "UPDATE_BUDGET",
      payload: query(auth, updateBudgetItemMutation, {
        payPeriod,
        category,
        amount: amount !== undefined ? Number(amount) : undefined,
        ignore,
      }),
    }).then(() => {
      dispatch({type: "SUMMARY_DIRTY"})
    });
  };

  const carryOverSpendingItem = (category) => {
    dispatch({
      type: "UPDATE_BUDGET",
      payload: query(auth, carryOverBudgetItemMutation, {
        payPeriod,
        category,
      }),
    });
  };

  const listTransactions = (category) => {
    dispatch({ type: "NEW_FILTER", payload: `category:${category}` });
    history.push("/tracking");
  };

  const loadFullPlan = () => {
    dispatch({
      type: "UPDATE_BUDGET_WITH_RECOMMENDATIONS",
      payload: query(auth, updateBudgetWithRecommendations, {
        payPeriod,
      }),
    }).then(() => dispatch({ type: "SUMMARY_DIRTY" }));
  };

  const navigateWithReturn = (destination) => {
    dispatch({ type: "RETURN_PAGE", payload: history.location.pathname });
    history.push(destination);
  };

  return (
    <SidebarLayout title="Budget">
      <Loading loading={state.isDirty || state.isFetching} error={state.error}>
        <Box hidden={state.error !== ""}>
          {state?.budget?.isEmpty && canUpdateBudget && (
            <Box mb={3}>
              <Alert severity="info">
                You haven't started this budget, yet. Would you like to automatically apply all the recommendations from
                your plan?
                <Box mt={2} py={1} display="flex" flexDirection="row">
                  <Box mr={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        loadFullPlan();
                      }}
                    >
                      Yes
                    </Button>
                  </Box>
                  <Button variant="contained" onClick={() => dispatch({ type: "CLEAR_EMPTY_BUDGET_NOTICE" })}>
                    No
                  </Button>
                </Box>
              </Alert>
            </Box>
          )}
          {!state.budget.income?.[0] && (
            <>
              <Skeleton height={80} width={240} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "24px" }} />
              <Skeleton height={80} width={240} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
            </>
          )}
          <TableIncomeSection
            auth={auth}
            budget={state.budget}
            updateBudgetItem={updateBudgetItem}
            listTransactions={listTransactions}
            isCurrent={sidebar.payPeriod.isCurrent}
            navigateWithReturn={navigateWithReturn}
          />
          <TableSpendingSection
            auth={auth}
            budget={state.budget}
            updateBudgetItem={updateBudgetItem}
            listTransactions={listTransactions}
            carryOverSpendingItem={carryOverSpendingItem}
          />
        </Box>
        <SnackMessage dispatch={dispatch} snack={state.snack} />
      </Loading>
    </SidebarLayout>
  );
};

export default Budget;

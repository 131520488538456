import { Backdrop, Box, createMuiTheme, CssBaseline, ThemeProvider, Typography } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import React, { useEffect } from 'react'

const theme = createMuiTheme({
  palette: {
    primary: {main: "#fff"}
  },
})

const ErrorPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location="/"
    }, 5000);
    return () => clearTimeout(timer)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop open={true}>
        <Box mr={2}>
          <Warning color="primary"/>
        </Box>
        <Typography color="primary" variant="h5">There was a problem logging you in.<br/>Redirecting to try again...</Typography>
      </Backdrop>
    </ThemeProvider>
  )
}

export default ErrorPage
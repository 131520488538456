import { baseUrl, query } from "app/api";
import { analytics } from "app/useAnalytics";
import axios from "axios";
import { CookieStorage } from "cookie-storage";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useSelector } from "react-redux";

export { default as CallbackPage } from "./CallbackPage";
export { default as ErrorPage } from "./ErrorPage";
export { default as NoAccessPage } from "./NoAccessPage";
export { default as StopPage } from "./StopPage";

/*const amplifyConfig = {
  Auth: {
    identityPoolId: "us-east-1:fa9d4dac-dc82-40f5-88ee-bb9642fed715",
    region: "us-east-1",
  },
};
//Initialize Amplify
Auth.configure(amplifyConfig);

const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: "96ac6b4c10864026b6f7d5d3742021b5",
    // Amazon service region
    region: "us-east-1",
    mandatorySignIn: true,
  },
};

Analytics.configure(analyticsConfig);
*/

var wss = null;
var intervalId;

const DEFAULT_STATE = {
  login: "",
  subscriber: "",
  profileSubscriber: "",
  profile: null,
  profileNumber: 0,
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  accessToken: "",
  emailVerified: false,
  phoneVerified: false,
  idToken: "",
  settings: null,
  authz: { defaultAccess: ["Privileged", "Partner", "Read", "Write"] },
  identities: [],
  plan: "",
  features: {
    accountLink: false,
    profiles: false
  }
};

export const storage = new CookieStorage({
  path: "/",
  domain: baseUrl.cookieDomain
});

const endpoint = "https://cognito-idp.us-east-1.amazonaws.com";

const CONFIG = (() => {
  switch (window.location.hostname) {
    case "app.budgetocity.com": {
      return {
        logout: "https://auth.budgetocity.com/login",
        authApiBase: "https://api.budgetocity.com/auth"
      };
    }
    case "app.test.budgetocity.com": {
      return {
        logout: "https://auth.test.budgetocity.com/login",
        authApiBase: "https://api.test.budgetocity.com/auth"
      };
    }
    default: {
      return {
        logout: "https://login.test.budgetocity.com/login",
        authApiBase: "http://localhost:8090"
      };
    }
  }
})();

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "TOKEN_REFRESH": {
      if (intervalId) {
        wss.send(JSON.stringify({ action: "authorization", id_token: action.payload.idToken, profile: state.profile }));
      }

      return {
        ...state,
        ...action.payload
      };
    }
    case "UPDATE_IDENTITY_FULFILLED":
    case "FEDERATION_UNLINK_FULFILLED":
    case "CONFIRM_IDENTITY_FULFILLED": {
      const accessToken = action.payload?.data?.accessToken || state.accessToken;
      const idToken = action.payload?.data?.idToken || state.idToken;

      const expires = Date.now() + 3600000;

      storage.setItem(`${baseUrl.cookieScope}.id_token`, idToken, {
        expires: new Date(expires),
        domain: baseUrl.cookieDomain
      });

      storage.setItem(`${baseUrl.cookieScope}.access_token`, accessToken, {
        expires: new Date(expires),
        domain: baseUrl.cookieDomain
      });

      storage.setItem(`${baseUrl.cookieScope}.expires`, expires, {
        expires: new Date(expires),
        domain: baseUrl.cookieDomain
      });

      const decodeToken = jwtDecode(idToken);
      return {
        ...state,
        idToken,
        accessToken,
        firstName: decodeToken.given_name,
        lastName: decodeToken.family_name,
        emailAddress: decodeToken.email,
        phoneNumber: decodeToken.phone_number,
        emailVerified: decodeToken.email_verified,
        phoneNumberVerified: decodeToken.phone_number_verified,
        identities: decodeToken.identities || []
      };
    }
    case "BRIGHT_THEME": {
      localStorage.setItem(`${state.login}-brightTheme`, action.payload);

      return {
        ...state,
        brightTheme: action.payload
      };
    }
    case "SIDEBAR_FULFILLED": {
      const payPeriod = action.payload?.payPeriod?.name;
      const account = action.payload?.accountBalance?.account;

      sessionStorage.setItem(`${state.profile}-payPeriod`, payPeriod);
      sessionStorage.setItem(`${state.profile}-account`, account);

      return {
        ...state,
        payPeriod,
        account
      };
    }
    case "CREATE_PAYPERIOD_FULFILLED": {
      const payPeriod = action.payload?.createPayPeriod?.payPeriod?.name;

      return {
        ...state,
        payPeriod
      };
    }
    default:
      return state;
  }
};

export const useAuthorizationWrite = (scope) => {
  const policy = useSelector((state) => state.auth)?.authz;
  return (policy[scope] && policy[scope].includes("Write")) || (policy.defaultAccess && policy.defaultAccess.includes("Write"));
};

export const useAuthorization = (scope, permission) => {
  const policy = useSelector((state) => state.auth)?.authz;
  return (
    (policy[scope] && policy[scope].includes(permission)) || (policy.defaultAccess && policy.defaultAccess.includes(permission))
  );
};

export const changePassword = (oldPassword, newPassword) => {
  return axios.post(
    endpoint,
    {
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
      AccessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`)
    },
    {
      headers: cognitoHeaders("ChangePassword")
    }
  );
};

export const disableMFA = () => {
  return axios.post(
    endpoint,
    {
      AccessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`),
      SMSMfaSettings: {
        Enabled: false,
        PreferredMfa: false
      },
      SoftwareTokenMfaSettings: {
        Enabled: false,
        PreferredMfa: false
      }
    },
    {
      headers: cognitoHeaders("SetUserMFAPreference")
    }
  );
};

export const enableMFA = async () => {
  return axios.post(
    endpoint,
    {
      AccessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`)
    },
    {
      headers: cognitoHeaders("AssociateSoftwareToken")
    }
  );
};

export const enableMFAFinalize = async (userCode) => {
  const response = await axios.post(
    endpoint,
    {
      AccessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`),
      UserCode: userCode
    },
    {
      headers: cognitoHeaders("VerifySoftwareToken")
    }
  );

  if (response?.data?.Status !== "SUCCESS") {
    throw new Error("Failed to complete MFA registration");
  }

  console.log("Enabling MFA");
  return axios.post(
    endpoint,
    {
      AccessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`),
      SoftwareTokenMfaSettings: {
        Enabled: true,
        PreferredMfa: true
      }
    },
    {
      headers: cognitoHeaders("SetUserMFAPreference")
    }
  );
};

export const resendAttributeConfirmation = (attribute) => {
  return axios.post(
    endpoint,
    {
      AccessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`),
      AttributeName: attribute,
      ClientMetadata: {}
    },
    {
      headers: cognitoHeaders("GetUserAttributeVerificationCode")
    }
  );
};

export const getUser = () => {
  return axios.post(
    endpoint,
    {
      AccessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`)
    },
    {
      headers: cognitoHeaders("GetUser")
    }
  );
};

export const logout = () => {
  // Did I mention I hate cookies?
  storage.setItem(`${baseUrl.cookieScope}.id_token`, null, { domain: baseUrl.cookieDomain });
  storage.setItem(`${baseUrl.cookieScope}.access_token`, null, { domain: baseUrl.cookieDomain });
  storage.setItem(`${baseUrl.cookieScope}.expires`, null, { domain: baseUrl.cookieDomain });
  window.location = `${CONFIG.logout}`;
};

export const isAuthenticated = async (dispatch) => {
  try {
    const path = window.location.pathname;

    if (path === "/noaccess") {
      return false;
    }

    const accessToken = storage.getItem(`${baseUrl.cookieScope}.access_token`);
    const idToken = storage.getItem(`${baseUrl.cookieScope}.id_token`);

    // I hate cookies
    if (!idToken || idToken === "null") {
      if (path === "/error" || path === "/loading") {
        return false;
      } else {
        logout();
        return false;
      }
    } else {
      const expires = Number(storage.getItem(`${baseUrl.cookieScope}.expires`));
      if (!expires || moment().diff(new Date(expires)) >= 0) {
        logout();
        return false;
      }

      const decodeToken = jwtDecode(idToken);
      const login = decodeToken["cognito:username"];
      const subscriber = decodeToken["subscriber"];
      const profileNumber = Number(localStorage.getItem(`${login}-profileNumber`) || 0);

      const profile = sessionStorage.getItem(`${baseUrl.cookieScope}.${login}.profile`) || `${subscriber}#${profileNumber}`;

      const setupCheck = await query(
        { accessToken, idToken, profile },
        `
        query { 
          setup { 
            income 
            spending 
            account 
            subscription 
          }

          authz {
            defaultAccess
            Admin
          }
        }
      `
      );

      if (
        path !== "/setup" &&
        (!setupCheck?.setup?.income || !setupCheck?.setup?.spending || !setupCheck?.setup?.account) /* ||
          !setupCheck?.setup?.subscription*/
      ) {
        window.location = "/setup";
        return false;
      }

      if (path !== "/setup") {
        analytics.identify(login, {
          name: `${decodeToken.given_name} ${decodeToken.family_name}`,
          firstName: decodeToken.given_name,
          lastName: decodeToken.family_name,
          email: decodeToken.email,
          phone: decodeToken.phoneNumber,
          segment: "active",
          progress: "active"
        });
      }

      const realProfileNumber = profile.split("#")[1];

      const connect = () => {
        wss = new WebSocket(baseUrl.websocket);

        const ping = () => {
          wss.send("__ping__");
        };

        wss.onopen = () => {
          intervalId = setInterval(ping, 150000);
          wss.send(
            JSON.stringify({
              action: "authorization",
              id_token: storage.getItem(`${baseUrl.cookieScope}.id_token`),
              profile
            })
          );
        };

        wss.onclose = () => {
          clearInterval(intervalId);
          setTimeout(() => connect(), 1000);
        };

        wss.onmessage = (payload) => {
          try {
            const data = JSON.parse(payload?.data || "{ actions: [] }");
            for (const action of data.actions) {
              if (action === "w00t") {
              } else {
                dispatch({ type: action });
              }
            }
          } catch (ex) {
            console.log(ex);
          }
        };
      };

      connect();

      dispatch({
        type: "TOKEN_REFRESH",
        payload: {
          accessToken: accessToken,
          idToken: idToken,
          login: login,
          firstName: decodeToken.given_name,
          lastName: decodeToken.family_name,
          emailAddress: decodeToken.email,
          phoneNumber: decodeToken.phone_number,
          identities: decodeToken.identities || [],
          plan: decodeToken.plan,
          features: {
            accountLink: decodeToken?.feature_accountLink === "true",
            profiles: decodeToken?.feature_profiles === "true"
          },
          subscriber,
          profile,
          profileSubscriber: profile.split("#")[0],
          emailVerified: decodeToken?.email_verified,
          phoneNumberVerified: decodeToken?.phone_number_verified,
          profileNumber: realProfileNumber,
          authz: setupCheck?.authz,
          payPeriod: sessionStorage.getItem(`${profile}-payPeriod`) ? sessionStorage.getItem(`${profile}-payPeriod`) : "",
          account: sessionStorage.getItem(`${profile}-account`) ? sessionStorage.getItem(`${profile}-account`) : "",
          brightTheme: localStorage.getItem(`${login}-brightTheme`)
            ? localStorage.getItem(`${login}-brightTheme`) === "true"
            : false
        }
      });

      startAuthCheck(dispatch);

      return true;
    }
  } catch (ex) {
    console.log(ex);
    if (ex.includes("Operation not available to login")) {
      window.location = "/noaccess";
      return false;
    }
    logout();
  }
};

export const refresh = async (dispatch) => {
  try {
    const payload = await axios.post(`${CONFIG.authApiBase}/refresh`, null, {
      headers: {
        Authorization: storage.getItem(`${baseUrl.cookieScope}.access_token`)
      }
    });

    if (!payload?.data?.idToken) {
      console.log("No id token in playload.  That's beyond bad.  Logging out.");
      logout();
      return false;
    }

    const expires = Date.now() + 3600000;

    storage.setItem(`${baseUrl.cookieScope}.id_token`, payload.data.idToken, {
      expires: new Date(expires),
      domain: baseUrl.cookieDomain
    });
    storage.setItem(`${baseUrl.cookieScope}.access_token`, payload.data.accessToken, {
      expires: new Date(expires),
      domain: baseUrl.cookieDomain
    });
    storage.setItem(`${baseUrl.cookieScope}.expires`, expires, {
      expires: new Date(expires),
      domain: baseUrl.cookieDomain
    });
    const decodeToken = jwtDecode(storage.getItem(`${baseUrl.cookieScope}.id_token`));

    await dispatch({
      type: "TOKEN_REFRESH",
      payload: {
        idToken: storage.getItem(`${baseUrl.cookieScope}.id_token`),
        accessToken: storage.getItem(`${baseUrl.cookieScope}.access_token`),
        plan: decodeToken.plan
      }
    });
  } catch (ex) {
    console.log(ex);
    logout();
    return false;
  }

  return true;
};

var authCheckTimer = null;
var lastUpdated = moment();

const clearTimer = (_) => {
  if (authCheckTimer) {
    clearInterval(authCheckTimer);
    authCheckTimer = null;
  }
};

export const pageLoaded = ({ location }) => {
  const pathname = location.pathname;

  //Analytics.record({ name: "pageView", attributes: { page: location.pathname } }, null);

  if (pathname === "/settings" || pathname === "/categories") {
    sessionStorage.setItem("pathname", "/");
  } else {
    sessionStorage.setItem("pathname", pathname);
  }
  lastUpdated = moment();
  return null;
};

const startAuthCheck = (dispatch) => {
  authCheckTimer = setInterval(() => {
    if (moment().diff(lastUpdated) < 60 * 60000) {
      console.log("Refreshing...");
      refresh(dispatch);
    } else {
      console.log("Stale session...");
      logout();
    }
  }, 300000);

  console.log("Refreshing...");
  refresh(dispatch);
};

document.getElementById("root").addEventListener("mousemove", () => {
  lastUpdated = moment();
});

window.addEventListener("scroll", () => {
  lastUpdated = moment();
});

clearTimer();

const cognitoHeaders = (service) => {
  return {
    "X-Amz-Target": `AWSCognitoIdentityProviderService.${service}`,
    "X-Amz-User-Agent": "aws-amplify/0.1.x js",
    "Content-Type": "application/x-amz-json-1.1"
  };
};

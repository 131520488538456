export const queryAccounts = `
  query { 
    accounts {
      id
      name
      defaultAccount
      active
      startingBalance
      buffer
      type
    }
  }  
`

export const queryAccountsPremium = `
  query { 
    accounts {
      id
      name
      defaultAccount
      active
      startingBalance
      buffer
      type
    }
    settings {
      tags
    }
    accountLinks {
      id
      institutionName
      lastUpdated
      status
      error {
        code
        message
        type
      }
      accounts {
        id
        selected
        officialName
        mask
        type
        subType
        currentBalance
        availableBalance
        limit
        tags
        lastUpdated
      }
    }
  }  
`

export const queryAccount = `
  query account ($id: String!) { 
    account (id: $id) {
      id
      name
      defaultAccount
      active
      startingBalance
      buffer
      type
    }
  }
`

export const mutateAccount = `
  mutation updateAccount ($input: UpdateAccountInput!) {
    updateAccount (input: $input) {
      id
      active
      defaultAccount
    }
  }
`

export const createAccount = `
  mutation createAccount ($input: SetupAccountInput!) {
    createAccount (input: $input) {
      id
      active
      defaultAccount
    }
  }
`

export const createAccountLinkToken = `
  mutation createAccountLinkToken {
    createAccountLinkToken
  }
`

export const createAccountRelinkToken = `
  mutation createAccountRelinkToken ($id: String!) {
    createAccountRelinkToken (id: $id)
  }
`

export const createAccountLink = `
  mutation createAccountLink ($input: PlaidLinkInput!) {
    createAccountLink (input: $input)
  }
`

export const removeAccountLink = `
  mutation removeAccountLink ($id: String!) {
    removeAccountLink (id: $id)
  }
`

export const updateAccountLinkMutation = `
  mutation updateAccountLink ($input: UpdateAccountLinkInput!) {
    updateAccountLink (input: $input) {
      id
      institutionName
      lastUpdated
      accounts {
        id
        selected
        officialName
        mask
        type
        subType
        currentBalance
        availableBalance
        limit
        tags
        lastUpdated
      }
    }
  }
`
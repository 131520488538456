import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import { deepCopy, removeAtIndex } from "app/util";
import AmountEditable from "components/AmountEditable";

import { mapDirectionTypes } from "./index";
import validate from "./validate";
import { DatePicker } from "@material-ui/pickers";

const descriptionFilterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.description,
});

const mapCategories = (groups) => {
  return groups.reduce((p, c) => [...p, ...c.categories.map((y) => ({ group: c.name, name: y.name }))], []);
};

const EditTransaction = ({
  open,
  cancel,
  submit,
  title,
  accounts,
  payPeriods,
  transaction: inputTransaction,
  typeAhead,
  categories,
  tags,
}) => {
  const theme = useTheme();
  const [transaction, updateTransaction] = useState(deepCopy(inputTransaction));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mappedCategories = mapCategories(categories);

  useEffect(() => {
    updateTransaction(deepCopy(inputTransaction));
  }, [inputTransaction, updateTransaction]);

  const submitHandler = (values, { resetForm, setSubmitting, setErrors }) => {
    submit({
      account: values.account,
      amounts: values.amounts,
      cleared: values.cleared,
      date: values.date,
      description: values.description.trim(),
      direction: values.direction,
      id: values.id,
      payPeriod: values.payPeriod,
      tags: values.tags,
    })
      .then(() => {
        resetForm();
      })
      .catch((ex) => {
        setSubmitting(false);
        setErrors({ global: ex });
      });
  };

  return (
    <Formik initialValues={transaction} enableReinitialize validate={validate} onSubmit={submitHandler}>
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldTouched,
        setFieldValue,
        setFieldError,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
          <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box display="flex" mb={2}>
                <Box width="50%">
                  <FormControl color="secondary">
                    <InputLabel id="account-label">Account</InputLabel>
                    <Select
                      name="account"
                      labelId="account-label"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.account && touched.account}
                      value={values.account}
                    >
                      {accounts.map(({ name: account }) => (
                        <MenuItem value={account} key={`et-${account}`}>
                          {account}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box width="50%">
                  <FormControl color="secondary">
                    <InputLabel id="payPeriod-label">Pay Period</InputLabel>
                    <Select
                      name="payPeriod"
                      labelId="payPeriod-label"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.payPeriod && touched.payPeriod}
                      value={values.payPeriod}
                    >
                      {payPeriods.map(({ name: payPeriod, isCurrent }) => (
                        <MenuItem value={payPeriod} key={`et-${payPeriod}`}>
                          {payPeriod}
                          {isCurrent && "*"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Box display="flex">
                <Box width="50%">
                  <Box width="140px">
                    <DatePicker
                      label="Date"
                      value={values.date}
                      format={"MMM DD, YYYY"}
                      margin="normal"
                      autoOk
                      onChange={(date) => {
                        setFieldValue("date", date.format("YYYY-MM-DD"));
                      }}
                      variant="inline"
                      disabled={isSubmitting}
                      error={errors.date && touched.date}
                      helperText={touched.date && errors.date}
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <AmountEditable
                    label="Amount"
                    emptyStart
                    amount={values.amount}
                    margin="normal"
                    disabled={isSubmitting}
                    onBlur={() => {
                      setFieldTouched("amount");
                      setFieldTouched(`amounts0`);
                    }}
                    onChange={(x) => {
                      var amounts = values.amounts;
                      amounts[0].amount = amounts.reduce((p, c, i) => (i > 0 ? p - c.amount : p), x);
                      setFieldValue("amount", x);
                      setFieldValue("amounts", amounts);
                      setTimeout(() => {
                        setFieldTouched("amount");
                        setFieldTouched(`amounts0`);
                      }, 2);
                    }}
                    error={errors.amount && touched.amount}
                    helperText={touched.amount && errors.amount}
                  />
                </Box>
              </Box>

              <Autocomplete
                options={typeAhead}
                getOptionLabel={(x) => (x.description ? x.description : "")}
                filterOptions={descriptionFilterOptions}
                freeSolo
                inputValue={values.description}
                onChange={(_, ta) => {
                  var amounts = values.amounts;
                  if (ta && ta.categories && ta.categories.length > 0) {
                    const amount = values.amount;
                    amounts = ta.categories.map((x, i) => ({ category: x, amount: i === 0 ? amount : 0 }));
                  }
                  updateTransaction({
                    ...values,
                    description: ta && ta.description ? ta.description : values.description,
                    direction: ta && ta.direction ? ta.direction : values.direction,
                    tags: (ta && ta.tags ? ta.tags : values.tags).filter(tag => ((tags || []).includes(tag)) && tag !== "Payment"),
                    amounts,
                  });
                }}
                renderInput={(x) => (
                  <TextField
                    {...x}
                    name="description"
                    label="Description"
                    margin="normal"
                    color="secondary"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.description && touched.description}
                    helperText={touched.description && errors.description}
                  />
                )}
              />
            </Box>

            {transaction.amounts.map((a, i) => (
              <Box display="flex" key={`etc-${i}`} alignItems="flex-start">
                <Box flexGrow={1} mr={1}>
                  <Autocomplete
                    options={mappedCategories}
                    groupBy={(c) => c.group}
                    disableClearable
                    autoHighlight
                    getOptionLabel={(c) => (c.name ? c.name : c)}
                    getOptionSelected={(o, v) => o.name === v}
                    value={a.category}
                    onChange={(_, ac) => {
                      var amounts = values.amounts;
                      amounts[i].category = ac ? ac.name : "";
                      setFieldValue("amounts", amounts);

                      if (i === 0 && accounts.find((a) => a.name === values.account)?.type === "SPENDING") {
                        setFieldValue("direction", ac.group === "Income" ? "CREDIT" : "DEBIT");
                      }

                      const firstGroupIncome =
                        mappedCategories.find((x) => x.name === amounts[0].category)?.group === "Income";
                      for (const z in amounts) {
                        if (z > 0) {
                          const thisGroupIncome =
                            mappedCategories.find((x) => x.name === amounts[z].category)?.group === "Income";
                          if (thisGroupIncome !== firstGroupIncome) {
                            amounts[z].category = "";
                          }
                        }
                      }
                    }}
                    renderInput={(x) => (
                      <TextField
                        {...x}
                        name={`category${i}`}
                        label={i === 0 ? "Category" : ""}
                        margin={i === 0 ? "normal" : "dense"}
                        color="secondary"
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors[`category${i}`] && touched[`category${i}`]}
                        helperText={touched[`category${i}`] && errors[`category${i}`]}
                      />
                    )}
                  />
                </Box>
                <Box hidden={transaction.amounts.length < 2} mr={1}>
                  <AmountEditable
                    label={i === 0 ? "Amount" : ""}
                    amount={a.amount}
                    margin={i === 0 ? "normal" : "dense"}
                    disabled={i === 0 || isSubmitting}
                    onChange={(x) => {
                      var amounts = values.amounts;
                      amounts[i].amount = x;
                      amounts[0].amount = amounts.reduce((p, c, i) => (i > 0 ? p - c.amount : p), values.amount);
                      setFieldValue("amounts", amounts);
                      setFieldTouched(`amounts0`);
                      setFieldTouched(`amounts${i}`);
                    }}
                    error={errors[`amount${i}`] && touched[`amount${i}`]}
                    helperText={touched[`amount${i}`] && errors[`amount${i}`]}
                  />
                </Box>
                <Box mt={i === 0 ? 4 : 1}>
                  {i === 0 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        const amounts = [...values.amounts, ...[{ category: "", amount: 0 }]];
                        updateTransaction({ ...values, amounts });
                      }}
                    >
                      <AddCircleOutline style={{ fill: "green" }} />
                    </IconButton>
                  )}
                  {i !== 0 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        var amounts = removeAtIndex(values.amounts, i);
                        amounts[0].amount = amounts.reduce(
                          (p, c, i) => (i > 0 ? p - Number(c.amount) : p),
                          Number(values.amount)
                        );
                        updateTransaction({ ...values, amounts });
                      }}
                    >
                      <RemoveCircleOutline style={{ fill: "red" }} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}

            <Box display="flex">
              <Box my={2} mr={3}>
                <FormControl color="secondary">
                  <InputLabel id="direction-label">Direction</InputLabel>
                  <Select
                    name="direction"
                    labelId="direction-label"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.direction && touched.direction}
                    value={values.direction}
                  >
                    <MenuItem value="CREDIT">
                      {mapDirectionTypes(accounts.find((a) => a.name === values.account)?.type).CREDIT}
                    </MenuItem>
                    <MenuItem value="DEBIT">
                      {mapDirectionTypes(accounts.find((a) => a.name === values.account)?.type).DEBIT}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box flexGrow={1}>
                <Autocomplete
                  multiple
                  options={tags}
                  value={values.tags}
                  freeSolo
                  onChange={(_, t) => {
                    setFieldValue("tags", t);
                    //updateTransaction({...transaction, tags: t})
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map
                      ? value.map((option, index) => (
                          <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      : [value]
                  }
                  renderInput={(x) => (
                    <TextField
                      {...x}
                      name="tags"
                      label="Tags"
                      margin="normal"
                      color="secondary"
                      disabled={isSubmitting}
                      onBlur={handleBlur}
                      error={errors.tags && touched.tags}
                      helperText={touched.tags && errors.tags}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box hidden={!errors.global || errors.global === ""}>
              <Alert severity="error" onClose={() => setFieldError("global", "")}>
                {errors.global}
              </Alert>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  cancel();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting || !isValid}
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default EditTransaction;

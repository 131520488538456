export const queryNotifications = `query notifications ($start: Int, $count: Int) {
  notifications (start: $start, count: $count) {
      counts {
          total
          unread
      }
      notifications {
          id
          subject
          date
          body
          read
      }
      start
      count
  }
}`;

export const updateNotification = `mutation updateNotification ($input: UpdateNotificationInput!) {
  updateNotification (input: $input) {
      counts {
          total
          unread
      }
      notification {
          id
          subject
          date
          body
          read
      }
  }
}`

export const updateNotifications = `mutation updateNotification ($read: Boolean!) {
  updateNotifications (read: $read) {
    total
    unread
  }
}`

export const removeNotification = `mutation removeNotification ($id: String!) {
  removeNotification (id: $id) {
      total
      unread
  }
}`

export const removeNotifications = `mutation removeNotifications {
  removeNotifications {
      total
      unread
  }
}`

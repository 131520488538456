import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";

const Confirmation = ({ open, cancel, submit, message, noText = "No", yesText = "Yes", title = "Are you sure?" }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await submit();
    setIsSubmitting(false);
  };

  return (
    <Dialog open={open} aria-labelledby="confirmation-dialog-title" aria-describedby="confirmation-dialog-description">
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && (
          <Button onClick={cancel} color="secondary">
            {noText}
          </Button>
        )}
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary" variant="contained" autoFocus>
          {yesText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;

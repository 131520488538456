import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AmountEditable from "components/AmountEditable";
import { useEffect, useState } from "react";

const RebalanceAccount = ({ open, cancel, submit, startingBalance, account: inputAccount }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [error, setError] = useState(null);
  const [amount, setAmount] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setAmount(startingBalance);
  }, [startingBalance]);

  const submitHandler = () => {
    setIsSubmitting(true);
    submit(amount)
      .then(() => {
        setIsSubmitting(false);
      })
      .catch((ex) => {
        setIsSubmitting(false);
        setError(ex);
      });
  };

  return (
    <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
      <DialogTitle id="edit-dialog-title">New Starting Balance </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" component="p" gutterBottom style={{marginBottom: "16px"}}>
            If you haven't kept up with tracking your expenses, you may find your starting balance and bank balance are wildly incorrect.  
            That's okay - starting over is an important part of budgeting.  Just keep at it!
          </Typography>

          <Typography variant="body1" component="p" gutterBottom style={{marginBottom: "16px"}}>
            You can reset your starting balance here, in your current pay period.  This will adjust things so
            that you're tracking restarts with this pay period.  If you're unsure of what to use - try using
            your bank balance <em>right before</em> your first budgeted paycheck.  That way you can start tracking 
            with the deposit of your paycheck.
          </Typography>

          <Typography variant="body1" component="p" gutterBottom style={{marginBottom: "32px"}}>
            Don't be afraid to get this number wrong.  If you find that you need a different starting balance 
            to make things work, just change it again until things look right.  
          </Typography>

          <AmountEditable
            ariaLabel="New starting balance"
            variant="outlined"
            amount={amount}
            onChange={(x) => setAmount(x)}
            style={{ width: theme.spacing(20) }}
          />

          <Box hidden={!error || error === ""}>
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
          <Button
            onClick={() => {
              cancel();
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            color="primary"
            variant="contained"
            disabled={isSubmitting || !amount || amount < 0}
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RebalanceAccount;

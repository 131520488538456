
const DEFAULT_STATE = {
  skeleton: true,
  creatingPayPeriod: false,
  filter: "",
  payPeriods: [""],
  accounts: [""],
  payPeriod: {
    name: "",
    previous: null,
    next: null,
    isCurrent: false
  },
  settings: {
    budgetFrequency: ""
  },
  accountBalance: {
    account: "",
    startingBalance: null,
    bankBalance: null,
    currentBalance: null
  },
  budgetBalance: {
    income: null,
    spending: null,
    difference: null,
    groups: []
  },
  error: ""
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SIDEBAR_FULFILLED": {
      const account = action.payload?.accountBalance?.account
      if (!account) {
        return {
          ...state,
          ...action.payload,
          accountBalance: {
            startingBalance: 0,
            bankBalance: 0,
            currentBalance: 0,
            account: null
          },
          skeleton: false,
          error: ""
        }
      }

      return {
        ...state,
        ...action.payload,
        skeleton: false,
        error: ""
      }
    }
    case "SIDEBAR_REJECTED": {
      return {
        ...state,
        skeleton: false,
        error: action.payload
      }
    }
    case "CREATE_PAYPERIOD_PENDING": {
      return {
        ...state,
        creatingPayPeriod: true
      }
    }
    case "CREATE_PAYPERIOD_FULFILLED": {
      return {
        ...state,
        ...action.payload.createPayPeriod,
        creatingPayPeriod: false
      }
    }
    case "CREATE_PAYPERIOD_REJECTED": {
      return {
        ...state,
        creatingPayPeriod: false
      }
    }
    case "UPDATE_BUDGETFREQUENCY_FULFILLED": {
      const payPeriod = action?.payload.updateBudgetFrequency?.payPeriod
      const payPeriods = state.payPeriods.map(p=>{
        if (p.name === payPeriod.name) {
          return {
            ...p,
            prettyRange: payPeriod.prettyRange
          }
        }
        return p
      })

      if (payPeriod.name !== state.payPeriod.name) {
        return {
          ...state,
          payPeriods
        }
      }

      return {
        ...state,
        payPeriod: {
          ...state.payPeriod,
          prettyRange: payPeriod.prettyRange
        },
        payPeriods
      }
    }
    case "UPDATE_BUDGET_FULFILLED": {
      const updatedBudgetItem = 
        action.payload?.updateBudgetItem ||
          action.payload?.carryOverBudgetItem
      return {
        ...state,
        budgetBalance: updatedBudgetItem?.balance
      }
    }
    case "UPDATE_BUDGET_WITH_RECOMMENDATIONS_FULFILLED": {
      const balance = action?.payload?.updateBudgetWithRecommendations?.balance
      if (balance) {
        return {
          ...state,
          budgetBalance: balance
        }      
      }
      return state
    }
    case "UPDATEBALANCES_FULFILLED": {
      for (const b of action?.payload?.updateAccountBalances) {
        if (b.payPeriod === state.payPeriod.name) {
          return {
            ...state,
            accountBalance: {
              ...state.accountBalance,
              startingBalance: b.startingBalance,
              bankBalance: b.bankBalance,
              currentBalance: b.currentBalance
            }
          }
        }
      }

      return state
    }
    case "NEW_FILTER": {
      return {
        ...state,
        filter: action.payload
      }
    }
    default:
      return state
  }
}

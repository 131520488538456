import axios from "axios";
import momentTimeZone from "moment-timezone";

export const baseUrl = (() => {
  switch (window.location.hostname) {
    case "app.budgetocity.com": {
      return {
        web: "https://budgetocity.com",
        api: "https://api.budgetocity.com",
        avatar: "https://api.budgetocity.com",
        logout: "https://",
        cookieScope: "p",
        cookieDomain: ".budgetocity.com",
        identityPool: "us-east-1:dc2c5f6d-784e-44f7-a86f-9b70a88fa66a",
        cognitoPool: "cognito-idp.us-east-1.amazonaws.com/us-east-1_5JEBkdt9h",
        pinPoint: "48d6a895a1874c338212fca6a4e71b5f",
        websocket: "wss://websocket.budgetocity.com",
        altDomain: "https://next.budgetocity.com",
      };
    }
    case "app.test.budgetocity.com": {
      return {
        web: "https://test.budgetocity.com",
        api: "https://api.test.budgetocity.com",
        auth: "https://api.test.budgetocity.com/auth",
        avatar: "https://api.test.budgetocity.com",
        cookieScope: "t",
        cookieDomain: ".test.budgetocity.com",
        identityPool: "us-east-1:b54ae0cb-919d-42db-9a1c-ac5674570f4c",
        cognitoPool: "cognito-idp.us-east-1.amazonaws.com/us-east-1_0RAM8kshZ",
        pinPoint: "dfdb60ba926e4c2590efe6ad9c0d3f7a",
        websocket: "wss://websocket.test.budgetocity.com",
        altDomain: "https://next.test.budgetocity.com",
      };
    }
    default:
      return {
        web: "http://localhost:8000",
        api: "http://localhost:8080/default",
        auth: "http://localhost:8090",
        avatar: "http://localhost:8085",
        cookieScope: "l",
        cookieDomain: "localhost",
        identityPool: "us-east-1:b54ae0cb-919d-42db-9a1c-ac5674570f4c",
        cognitoPool: "cognito-idp.us-east-1.amazonaws.com/us-east-1_0RAM8kshZ",
        pinPoint: "dfdb60ba926e4c2590efe6ad9c0d3f7a",
        websocket: "wss://websocket.test.budgetocity.com",
        altDomain: "http://localhost:3000",
      };
  }
})();

const restUrl = baseUrl.api;
//const restUrl = "http://localhost:8081/default"

export const get = async (auth, path, baseUrl = restUrl, defaultProfile = false, profileOverride) => {
  return await axios.get(baseUrl + path, {
    headers:
      profileOverride || (!defaultProfile && auth.profile)
        ? {
            Authorization: auth.idToken,
            profile: profileOverride || auth.profile,
          }
        : {
            Authorization: auth.idToken,
          },
  });
};

export const put = async (auth, path, data, baseUrl = restUrl, profileOverride) => {
  return await axios.put(baseUrl + path, data, {
    headers:
      profileOverride || auth.profile
        ? {
            Authorization: auth.idToken,
            profile: profileOverride || auth.profile,
          }
        : {
            Authorization: auth.idToken,
          },
  });
};

/**
 * For update attributes only!!
 */
export const authPost = async (auth, path, data) => {
  return await axios.post(baseUrl.auth + path, data, {
    headers: {
      Authorization: auth.accessToken,
    }
  });
};

export const query = async (auth, query, variables, profileOverride) => {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        baseUrl.api + "/graphql/v1",
        {
          query,
          variables,
        },
        {
          headers: auth.profile
            ? {
                Authorization: auth.idToken,
                profile: profileOverride || auth.profile,
                tz: momentTimeZone.tz.guess(),
              }
            : {
                Authorization: auth.idToken,
                tz: momentTimeZone.tz.guess(),
              },
        }
      )
      .then((response) => {
        const data = response?.data?.data;
        const error = response?.data?.errors;

        if (error && error?.length > 0) {
          reject(error[0]?.message || "Unable to process query request");
        } else {
          resolve(data);
        }
      })
      .catch((ex) => {
        const error = ex?.response?.data?.errors;
        if (error && error?.length > 0) {
          reject(error[0]?.message || "Unable to process query request");
        } else {
          reject(ex.message);
        }
      });
  });
};

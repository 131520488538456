export const budgetQuery = `query ($payPeriod: String!) {
  budget (payPeriod: $payPeriod) {
    potentialCarryover
    isEmpty
    income {
      name,
      categories {
        category,
        amount,
        deposited,
        plannedAmount,
        status,
        type
      }
    }, spending {
      name,
      amount,
      remaining,
      percentage,
      categories {
        category,
        amount,
        remaining,
        plannedAmount,
        status,
        recommendation,
        percentage
      }
    }
  }
}`

export const updateBudgetWithRecommendations = `mutation updateBudgetWithRecommendations($payPeriod: String!) {
  updateBudgetWithRecommendations (payPeriod: $payPeriod) {
    budget {
      potentialCarryover
      isEmpty
      income {
        name,
        categories {
          category,
          amount,
          deposited,
          plannedAmount,
          status,
          type
        }
      }, spending {
        name,
        amount,
        remaining,
        percentage,
        categories {
          category,
          amount,
          remaining,
          plannedAmount,
          status,
          recommendation,
          percentage
        }
      }
    }
    balance {
      income,
      spending,
      difference
      groups {
        name
        amount
      }
      }
  }
}`

export const updateBudgetItemMutation = `mutation updateBudgetItem($payPeriod: String!, $category: String!, $amount: Int, $ignore: Boolean) {
  updateBudgetItem(payPeriod: $payPeriod, category: $category, amount: $amount, ignoreRecommendation: $ignore) {
    item {
      category,
      amount,
      plannedAmount,
      recommendation,
      status,
      deposited,
      remaining,
      percentage
    },
    parent {
      name
      amount
      remaining
      percentage
    },
    balance {
      income,
      spending,
      difference
      groups {
        name
        amount
      }
      }
  }
}`

export const carryOverBudgetItemMutation = `mutation carryOverBudgetItem($payPeriod: String!, $category: String!) {
  carryOverBudgetItem(payPeriod: $payPeriod, category: $category) {
    item {
      category,
      amount,
      plannedAmount,
      recommendation,
      status,
      deposited,
      remaining,
      percentage
    },
    parent {
      name
      amount
      remaining
      percentage
    },
    balance {
      income,
      spending,
      difference
      groups {
        name
        amount
      }
      }
  }
}`

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Menu,
  Toolbar,
  Typography,
  useTheme
} from "@material-ui/core";
import { ExpandMore, Menu as MenuIcon, NotificationsActive, Share } from "@material-ui/icons";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { baseUrl, query } from "app/api";
import { acceptSharingMutation, declineSharingMutation } from "pages/settings/Settings";

import BadgeAvatars from "./BadgeAvatars";
import SnackMessage from "./SnackMessage";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};

const getAnimationSettings = () => {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    origin: {
      x: 0.5,
      y: 0.4
    }
  };
}

const NavHeader = ({ title }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const theme = useTheme();

  const refAnimationInstance = useRef(null);

  const auth = useSelector((state) => state.auth);

  const header = useSelector((state) => ({
    ...state.header,
    brightTheme: state.auth.brightTheme
  }));

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  useEffect(() => {
    if (header.confetti !== 0) {
      refAnimationInstance.current(getAnimationSettings());
    }
    // eslint-disable-next-line
  }, [header.confetti]);

  const navigateWithReturn = (destination) => {
    dispatch({ type: "RETURN_PAGE", payload: history.location.pathname });
    history.push(destination);
  };

  const acceptSharingRequest = (popupState, id) => {
    popupState.close();
    dispatch({ type: "ACCEPT_SHARING", payload: query(auth, acceptSharingMutation, { id }) }).then(() => {
      dispatch({ type: "PROFILES_DIRTY" });
    });
  };

  const declineSharingRequest = (popupState, id) => {
    popupState.close();
    dispatch({ type: "DECLINE_SHARING", payload: query(auth, declineSharingMutation, { id }) }).then(() => {
      dispatch({ type: "PROFILES_DIRTY" });
    });
  };

  return (
    <AppBar
      position="fixed"
      color="primary"
      style={{ zIndex: header.sidebarOpen ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Menu"
              onClick={() => dispatch({ type: "TOGGLE_NAV_SIDEBAR" })}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box mr={1}>
            <Link href={baseUrl.web}>
              <Avatar alt="Budgetocity™" src="/images/budgetocity-circle-tm-40.png" variant="rounded" />
            </Link>
          </Box>
          <Box>
            <Typography variant="h6" noWrap>
              {!header.sidebarOpen && title}
            </Typography>
          </Box>
        </Box>
        <Box flexGrow={1}></Box>
        {header?.sharing?.length > 0 && (
          <Box mr={1}>
            <PopupState variant="popover" popupId={`sharing`}>
              {(popupState) => (
                <React.Fragment>
                  <IconButton {...bindTrigger(popupState)}>
                    <Badge
                      badgeContent={header?.sharing?.length}
                      color="error"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Share />
                    </Badge>
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <List subheader={<ListSubheader>Sharing Invitiations</ListSubheader>}>
                      {header.sharing.map((s, i) => (
                        <ListItem key={`sharing${i}`}>
                          <ListItemAvatar>
                            <Avatar src={s.avatar} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box display="flex">
                                <Box flexGrow={1} mr={2} component="span">{`${s.firstName} ${s.lastName}`}</Box>
                                <Button
                                  size="small"
                                  color="secondary"
                                  onClick={() => declineSharingRequest(popupState, s.id)}
                                >
                                  Decline
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => acceptSharingRequest(popupState, s.id)}
                                >
                                  Accept
                                </Button>
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Box>
        )}
        {header?.notificationCount?.total > 0 && (
          <Box mr={1}>
            <IconButton onClick={() => navigateWithReturn("/notifications")}>
              <Badge
                badgeContent={header?.notificationCount?.unread}
                color="error"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <NotificationsActive />
              </Badge>
            </IconButton>
          </Box>
        )}
        <Box>
          <IconButton
            edge="start"
            color="secondary"
            style={{ borderRadius: "0px" }}
            aria-label="Your Picture"
            id="navSettingsButton"
            onClick={() => dispatch({ type: "TOGGLE_NAV_SETTINGS" })}
          >
            <BadgeAvatars
              alt={`${auth.firstName} ${auth.lastName}`}
              loginAvatar={header.loginAvatar}
              profileAvatar={header.profileAvatar}
              variant="circular"
            />
            <ExpandMore />
          </IconButton>
        </Box>
      </Toolbar>
      <SnackMessage dispatch={dispatch} snack={header.snack} />
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </AppBar>
  );
};

export default NavHeader;

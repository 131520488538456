import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { AddCircle, ArrowDropDown, Delete, DoneAll, Edit, ExitToApp, ExpandMore } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { query } from "app/api";
import { mergeArrays } from "app/util";
import { useAuthorizationWrite } from "auth";
import Amount from "components/Amount";
import Confirmation from "components/Confirmation";
import Loading from "components/Loading";
import { EMPTY_TRANSACTION } from "components/Sidebar";
import { newTransactionMutation, newTransactionQuery, updateAccountBalances } from "components/Sidebar/queries";
import SidebarLayout from "components/SidebarLayout";
import SnackMessage from "components/SnackMessage";

import { updateSavingsBalances } from "../Savings/queries";
import EditTransaction from "../Tracking/EditTransaction";
import AddTransactions from "./AddTransactions";
import EditEvent from "./EditEvent";
import { addTransactionsMutation, createPlannedEventMutation, plannerQuery, queryCreatePlannedEvent, queryPlannedEvent, removeMutation, updatePlannedEventMutation } from "./queries";

export const EMPTY_EVENT = {
  tags: [],
  settings: {
    tags: [],
  },
  categories: [],
  plannedEvent: {
    type: "MONTH",
    startDate: moment().format("YYYY-MM-DD"),
    description: "",
    direction: "DEBIT",
    amounts: [
      {
        category: null,
        amount: null,
      },
    ],
    tags: [],
  },
};

const Planner = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const endDate = useSelector((state) => state.sidebar.payPeriod.endDate);
  const account = useSelector((state) => state.auth.account);
  const payPeriod = useSelector((state) => state.auth.payPeriod);
  const planner = useSelector((state) => ({ ...state.stateManager.planner, ...state.planner }));

  const canUpdatePlanner = useAuthorizationWrite("Planner");

  const displayable = !planner.isFetching || planner.planner.events.length !== 0;

  const [showLinkDisclaimer, setShowLinkDisclaimer] = useState(false);
  const [getLink, setLink] = useState(null);
  const [newOpen, setNewOpen] = useState(false);
  const [newTransactionWaiting, setNewTransactionWaiting] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(EMPTY_TRANSACTION);

  const [removalConfirmationOpen, setRemovalConfirmationOpen] = useState(false);
  const [removalConfirmationSubmit, setRemovalConfirmationSubmit] = useState(() => {});

  const [editEventOpen, setEditEventOpen] = useState(false);
  const [editEventWaiting, setEditEventWaiting] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(EMPTY_EVENT);
  const [adding, setAdding] = useState(false);

  const [addTransactionsOpen, setAddTransactionsOpen] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const [oldPlan, setOldPlan] = useState(false);

  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (endDate < today) {
      setOldPlan(true);
      return;
    }

    setOldPlan(false);

    if (endDate && payPeriod && !planner.isFetching && planner.isDirty) {
      dispatch({ type: "PLANNER", payload: query(auth, plannerQuery, { payPeriod }) });
    }
  }, [dispatch, auth, planner, payPeriod, endDate, today]);

  useEffect(() => {
    if (oldPlan) {
      dispatch({ type: "PLANNER_CLEAR" });
    }
  }, [dispatch, oldPlan]);

  const newTransaction = (date, description, amounts, direction, tags) => {
    setNewTransactionWaiting(true);
    query(auth, newTransactionQuery)
      .then((t) => {
        setTransactionToEdit({
          ...t,
          transaction: {
            ...EMPTY_TRANSACTION.transaction,
            account,
            payPeriod,
            date: date && planner.planner.planDate !== "TODAY" ? date : today,
            description: description ? description : "",
            amount: amounts ? amounts.reduce((p, c) => p + c.amount, 0) : 0,
            amounts: amounts ? amounts : [{ category: "", amount: 0 }],
            direction: direction ? direction : "DEBIT",
            tags: tags ? tags : [],
          },
        });
        setNewOpen(true);
      })
      .finally(() => setNewTransactionWaiting(false));
  };

  const handleBillPay = (link) => {
    setLink(link)
    setShowLinkDisclaimer(true)
  }

  const submitTransaction = (transaction) => {
    const finalTransaction = {
      payPeriod: transaction.payPeriod,
      account: transaction.account,
      date: transaction.date,
      description: transaction.description,
      amounts: transaction.amounts,
      direction: transaction.direction,
      cleared: transaction.cleared,
      tags: transaction.tags,
    };

    const tAccount = transaction.account;
    const tPayPeriod = transaction.payPeriod;

    const updatePayPeriod = tPayPeriod < payPeriod ? tPayPeriod : payPeriod;

    return query(auth, newTransactionMutation, {
      input: finalTransaction,
    }).then(async (_) => {
      try {
        query(auth, updateSavingsBalances, {});

        await query(auth, updateAccountBalances, {
          account,
          payPeriod: updatePayPeriod,
        });

        if (tAccount !== account) {
          // Runs in the background
          query(auth, updateAccountBalances, {
            account: tAccount,
            payPeriod: updatePayPeriod,
          });
        }

        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
        setNewOpen(false);
      } catch (ex) {
        dispatch({ type: "SET_SNACK", error: ex });
      }
    });
  };

  const editPlannedEvent = (popupState, id, row) => {
    console.log(id);
    popupState.close();
    setAdding(false);
    setEditEventWaiting(true);
    query(auth, queryPlannedEvent, { id })
      .then((t) => {
        setEventToEdit(t);
        setEditEventOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setEditEventWaiting(false));
  };

  const submitEditEvent = (plannedEvent) => {
    return query(auth, updatePlannedEventMutation, {
      input: plannedEvent,
    }).then((_) => {
      dispatch({ type: "SET_SNACK", success: "Complete" });
      dispatch({ type: "PLANNER_DIRTY" });
      dispatch({ type: "BUDGET_DIRTY" });
      setEditEventOpen(false);
    });
  };

  const addPlannedEvent = () => {
    setAdding(true);
    setEditEventWaiting(true);
    query(auth, queryCreatePlannedEvent)
      .then((t) => {
        setEventToEdit({
          ...t,
          plannedEvent: EMPTY_EVENT.plannedEvent,
        });
        setEditEventOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setEditEventWaiting(false));
  };

  const submitCreateEvent = (plannedEvent) => {
    return query(auth, createPlannedEventMutation, {
      input: plannedEvent,
    }).then((_) => {
      dispatch({ type: "SET_SNACK", success: "Complete" });
      dispatch({ type: "PLANNER_DIRTY" });
      dispatch({ type: "BUDGET_DIRTY" });
      setEditEventOpen(false);
    });
  };

  const removalConfirmation = (popupState, id) => {
    popupState.close();
    setRemovalConfirmationOpen(true);
    setRemovalConfirmationSubmit(() => removePlannedEvent(id));
  };

  const removePlannedEvent = (id) => () => {
    setRemovalConfirmationOpen(false);

    dispatch({
      type: "REMOVE_PLANNED_EVENT",
      payload: query(auth, removeMutation, { id }),
    }).then(() => {
      dispatch({ type: "PLANNER_DIRTY" });
      dispatch({ type: "BUDGET_DIRTY" });
      dispatch({ type: "SET_SNACK", success: "Complete" });
    });
  };

  const addSelectedEvents = () => {
    const selectedEvents = planner.planner.events.filter(x=>x.selected) || []
    if (selectedEvents.length > 0) {
      setSelectedEvents(selectedEvents);
      setAddTransactionsOpen(true);
    }
  }

  const submitAddTransactions = async (transactions) => {
    return query(auth, addTransactionsMutation, {
      input: transactions,
    }).then((_) => {
      dispatch({ type: "SET_SNACK", success: "Complete" });
      dispatch({ type: "SIDEBAR_DIRTY" });
      setEditEventOpen(false);
    });

  }

  const manageSelection = (id, value) => {
    dispatch({ type: "PLANNER_SELECT", payload: { id, value } });
  };


  const UnusedEventsTable = ({ title, source, expanded }) => (
    <Accordion defaultExpanded={expanded || planner.filter !== ""}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {source.map((row, i) => (
                <TableRow key={`${title}${i}`} hover className="oldEvent">
                  <TableCell>
                    <Typography noWrap>{moment(row.date).format("MMM D")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box>{row.description}</Box>
                    <Box style={{ fontSize: "80%" }}>{row.amounts[0].category}</Box>
                  </TableCell>
                  <TableCell align="right">
                    <Amount bold greenGood={row.direction === "CREDIT"} amount={row.amounts[0].amount} />
                  </TableCell>
                  <TableCell>
                    {canUpdatePlanner && (
                      <PopupState variant="popover" popupId={`planner-menu-${i}`}>
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                              <ArrowDropDown />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem onClick={() => editPlannedEvent(popupState, row.id, row)}>
                                <ListItemIcon>
                                  <Edit />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                              </MenuItem>
                              <MenuItem onClick={() => removalConfirmation(popupState, row.id)}>
                                <ListItemIcon>
                                  <Delete />
                                </ListItemIcon>
                                <ListItemText primary="Remove" />
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );

  const EventsTable = () => (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5" gutterBottom>
          Necessary Budget Items
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <TableContainer component={Paper}>
          <Table aria-label="Necessary Budget Items">
            <TableHead>
              <TableRow>
                {canUpdatePlanner && (
                  <TableCell>
                    <IconButton onClick={() => dispatch({ type: "PLANNER_SELECTALL" })}>
                      <DoneAll />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">Amount</TableCell>
                {!fullScreen && <TableCell align="right">Balance</TableCell>}
                {canUpdatePlanner && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {planner.planner.filteredEvents.map((row, i) => {
                return (
                  <TableRow key={`events${i}`} hover className={row.date < today ? "oldEvent" : ""}>
                    {canUpdatePlanner && (
                      <TableCell width="1%">
                        <Checkbox onChange={() => manageSelection(row.id, !row.selected)} checked={row.selected} />
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography noWrap>{moment(row.date).format("MMM D")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box>{row.description}</Box>
                      <Box style={{ fontSize: "85%" }}>{row.amounts[0].category}</Box>
                    </TableCell>
                    <TableCell align="right">
                      {row.entered && <FontAwesomeIcon icon={faUserCheck} size="sm"/>}
                    </TableCell>
                    <TableCell align="right">
                      <Amount bold greenGood={row.direction === "CREDIT"} amount={row.amounts[0].amount} />
                      {fullScreen && <Amount style={{ fontSize: "90%" }} amount={row.balance} />}
                    </TableCell>
                    {!fullScreen && (
                      <TableCell align="right">
                        <Amount amount={row.balance} />
                      </TableCell>
                    )}
                    {canUpdatePlanner && (
                      <TableCell>
                        <PopupState variant="popover" popupId={`planner-menu-${i}`}>
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                <ArrowDropDown />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => editPlannedEvent(popupState, row.eventId, row)}>
                                  <ListItemIcon>
                                    <Edit />
                                  </ListItemIcon>
                                  <ListItemText primary="Edit" />
                                </MenuItem>
                                {row?.link && (row?.link !== "") && !fullScreen &&
                                <MenuItem onClick={() => handleBillPay(row.link)}>
                                  <ListItemIcon>
                                    <ExitToApp />
                                  </ListItemIcon>
                                  <ListItemText primary="Pay Bill"/>
                                </MenuItem>
                                }
                                <MenuItem
                                  onClick={() =>
                                    newTransaction(row.date, row.description, row.amounts, row.direction, row.tags)
                                  }
                                >
                                  <ListItemIcon>
                                    <AddCircle />
                                  </ListItemIcon>
                                  <ListItemText primary="Add Transaction" />
                                </MenuItem>
                                <MenuItem onClick={() => removalConfirmation(popupState, row.eventId)}>
                                  <ListItemIcon>
                                    <Delete />
                                  </ListItemIcon>
                                  <ListItemText primary="Remove" />
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <SidebarLayout
      title="Planner"
      addPlannedEvent={() => addPlannedEvent()}
      showAddSelectedEvents={planner.planner.selectedIds.length > 0}
      addSelectedEvents={() => addSelectedEvents()}
    >
      <Loading
        loading={!oldPlan && (newTransactionWaiting || editEventWaiting || planner.isDirty || planner.isFetching)}
        error={planner.error}
      >
        <Box>
          {!displayable && (
            <>
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
              <Skeleton variant="rect" height={50} style={{ marginBottom: "4px" }} />
            </>
          )}

          <Box hidden={planner.planner.filteredUnusedEvents.length === 0} mb={3}>
            <UnusedEventsTable
              source={planner.planner.filteredUnusedEvents}
              expanded={true}
              title="Unused Budget Items"
            />
          </Box>
          <Box hidden={planner.planner.filteredEvents.length === 0} mb={3}>
            <EventsTable />
          </Box>
          <Box hidden={planner.planner.filteredUnusedYearlyEvents.length === 0}>
            <UnusedEventsTable
              source={planner.planner.filteredUnusedYearlyEvents}
              title="Unused Yearly Budget Items"
              expanded={false}
            />
          </Box>

          {oldPlan && (
            <Alert severity="info">
              This is an old pay period. The plan only makes sense for your current and future pay periods.
            </Alert>
          )}

          {!oldPlan &&
            !planner.isDirty &&
            !planner.isFetching &&
            planner.planner.unusedEvents.length === 0 &&
            planner.planner.events.length === 0 &&
            planner.planner.unusedYearlyEvents.length === 0 && (
              <Alert severity="info">
                You don't have a plan, yet... Click "Add Event" to start creating your plan!
              </Alert>
            )}

          <EditTransaction
            open={newOpen}
            cancel={() => setNewOpen(false)}
            submit={submitTransaction}
            accounts={transactionToEdit.accounts}
            payPeriods={transactionToEdit.payPeriods}
            transaction={transactionToEdit.transaction}
            typeAhead={transactionToEdit.typeAhead}
            categories={transactionToEdit.categories}
            tags={transactionToEdit.settings.tags}
            title="New Transaction"
          />

          <EditEvent
            open={editEventOpen}
            cancel={() => setEditEventOpen(false)}
            submit={adding ? submitCreateEvent : submitEditEvent}
            event={eventToEdit.plannedEvent}
            categories={eventToEdit.categories}
            tags={mergeArrays(eventToEdit.settings.tags, eventToEdit.plannedEvent.tags)}
            title={adding ? "Adding Planned Event" : "Edit Planned Event"}
          />

          <AddTransactions
            open={addTransactionsOpen}
            cancel={() => setAddTransactionsOpen(false)}
            submit={submitAddTransactions}
            selectedEvents={selectedEvents}
            planDate={planner.planner.planDate}
          />

          <Confirmation
            open={removalConfirmationOpen}
            cancel={() => setRemovalConfirmationOpen(false)}
            submit={removalConfirmationSubmit}
            message="Are you sure you want to remove this entry?"
          />

          <SnackMessage dispatch={dispatch} snack={planner.snack} />
        </Box>
      </Loading>
      <Dialog
        open={showLinkDisclaimer}
        aria-labelledby="createlink-dialog-title"
        aria-describedby="createlink-dialog-description"
        disableEnforceFocus
      >
        <DialogTitle id="createlink-dialog-title">Pay Bill</DialogTitle>
        <DialogContent>
          <DialogContentText id="createlink-dialog-description">
            By clicking, "Confirm", you will be leaving Budgetocity for a third party website.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button color="secondary" onClick={() => setShowLinkDisclaimer(false)}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" href={getLink} target="_blank" onClick={() => setShowLinkDisclaimer(false)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default Planner;

import { validConfirmationCode } from "@budgetocity-internal/validation-library";
import { Typography } from "@material-ui/core";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";
import React from "react";

const ManageIdentityConfirm = ({ open, cancel, attribute, attributeName, attributeInstructions, submitConfirm }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const submitHandler = (values, { resetForm, setSubmitting, setErrors }) => {
    submitConfirm({
      attribute,
      confirmationCode: values.confirmationCode.trim(),
    })
      .then(() => {
        resetForm();
      })
      .catch((ex) => {
        setSubmitting(false);
        setErrors({ global: ex?.response?.data?.message || ex?.message || "Unable to confirm attribute" });
      });
  };

  const validate = (values) => {
    var errors = {};

    const confirmationCodeCheck = validConfirmationCode(values.confirmationCode);
    if (!confirmationCodeCheck.passed) {
      errors = { ...errors, confirmationCode: confirmationCodeCheck.reason };
    }

    return errors;
  };

  return (
    <Formik initialValues={{ email: "" }} enableReinitialize validate={validate} onSubmit={submitHandler}>
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldError,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
          <DialogTitle id="edit-dialog-title">Confirm {attributeName}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">{attributeInstructions}</Typography>
              <Box display="flex" mb={2}>
                <TextField
                  name="confirmationCode"
                  label="Confirmation Code"
                  color="secondary"
                  type="confirmationCode"
                  fullWidth
                  margin="normal"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmationCode}
                  error={errors.confirmationCode && touched.confirmationCode}
                  helperText={touched.confirmationCode && errors.confirmationCode}
                />
              </Box>
              <Box hidden={!errors.global || errors.global === ""}>
                <Alert severity="error" onClose={() => setFieldError("global", null)}>
                  {errors.global}
                </Alert>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  cancel();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting || !isValid}>
                Submit
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default ManageIdentityConfirm;

import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { validConfirmationCode } from "@budgetocity-internal/validation-library";
import { FileCopy } from "@material-ui/icons";
import { disableMFA, enableMFA, enableMFAFinalize, getUser } from "auth";
import { Formik } from "formik";
import QRCode from "react-qr-code";

const DEFAULT_STATE = {
  mfa: false,
  secretCode: null
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "MFA_INIT_FULFILLED": {
      return {
        ...state,
        secretCode: action?.payload?.data?.SecretCode
      };
    }
    case "MFA_FULFILLED": {
      return {
        ...state,
        secretCode: null,
        mfa: !!action?.payload?.data?.PreferredMfaSetting
      };
    }
    default: {
      return state;
    }
  }
};

const ManageMFA = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const state = useSelector((state) => ({ ...state.stateManager.mfa, ...state.mfa }));
  const auth = useSelector((state) => ({ ...state.stateManager.auth, ...state.auth }));

  useEffect(() => {
    if (!state.isFetching && state.isDirty) {
      dispatch({ type: "MFA", payload: getUser() });
    }
  }, [dispatch, state]);

  const submitEnableMFA = () => {
    dispatch({ type: "MFA_INIT", payload: enableMFA() }).catch((ex) => {
      setError(ex?.response?.data?.message || ex?.message || "Failed to initialize MFA");
    });
  };

  const submitUserCode = async (values) => {
    dispatch({ type: "MFA_INIT", payload: enableMFAFinalize(values.userCode) })
      .then((r) => {
        dispatch({ type: "MFA_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
      })
      .catch((ex) => {
        setError(ex?.response?.data?.message || ex?.message || "Failed to disable MFA");
      });
  };

  const submitDisableMFA = () => {
    disableMFA()
      .then(() => {
        dispatch({ type: "MFA_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
      })
      .catch((ex) => {
        setError(ex?.response?.data?.message || ex?.message || "Failed to disable MFA");
      });
  };

  const validate = (values) => {
    var errors = {};

    const userCodeCheck = validConfirmationCode(values.userCode);
    if (!userCodeCheck.passed) {
      errors = { ...errors, userCode: userCodeCheck.reason };
    }

    return errors;
  };

  return (
    <Paper>
      <Box mx={8} mb={3} display="flex" flexDirection="column" justifyContent="center">
        <Box my={3}>
          <Typography variant="h5">Two Factor Authentication</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" paragraph gutterBottom>
            Two factor authentication is an extra safety precaution for your Budgetocity™ account. When enabled, you
            have a separate application that generates a temprorary pass code that goes with your password. This helps
            prevent other people from getting into your account - even if they get your password.
          </Typography>
          <Typography variant="body1" paragraph gutterBottom>
            You will need an authenticator app like Google Authenticate or Authy to enable this feature.
          </Typography>
        </Box>
        <Box mb={3}>
          {!state?.secretCode && (
            <>
              {state.mfa && (
                <Button variant="contained" color="primary" onClick={() => submitDisableMFA()}>
                  Disable MFA
                </Button>
              )}
              {!state.mfa && (
                <Button variant="contained" color="primary" onClick={() => submitEnableMFA()}>
                  Enable MFA
                </Button>
              )}
            </>
          )}
          {state?.secretCode && (
            <>
              <Box display="flex" width="100%" justifyContent="center">
                <QRCode value={`otpauth://totp/Budgetocity:${auth.emailAddress}?secret=${state.secretCode}`} />
              </Box>
              <Box display="flex" width="100%" justifyContent="center" mb={3}>
                <Button startIcon={<FileCopy />} onClick={() => navigator.clipboard.writeText(state.secretCode)}>
                  Copy Key
                </Button>
              </Box>
              Once you're set up, please enter your the next code from your authenticator app to finish:
              <Formik initialValues={{ userCode: "" }} enableReinitialize validate={validate} onSubmit={submitUserCode}>
                {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                  <>
                    <TextField
                      name="userCode"
                      label="Verification Code"
                      color="secondary"
                      type="userCode"
                      fullWidth
                      margin="normal"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.userCode}
                      error={errors.userCode && touched.userCode}
                      helperText={touched.userCode && errors.userCode}
                    />
                    <Button type="submit" variant="contained" color="primary" onClick={() => handleSubmit()}>
                      Submit
                    </Button>
                  </>
                )}
              </Formik>
            </>
          )}
        </Box>

        {error && (
          <Alert severity="error" variant="filled" onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
      </Box>
    </Paper>
  );
};

export default ManageMFA;

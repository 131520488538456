export const profilesQuery = `
query profiles {
  profiles {
      myProfiles {
        profile
        subscriber
        profileNumber
        name
        firstName
        lastName
        avatar
        policy {
            defaultAccess
        }
        complete
        canSelect
        shared {
            login
            policy {
                defaultAccess
            }
        }
      }
      otherProfiles {
        profile
        subscriber
        profileNumber
        shortCut
        name
        firstName
        lastName
        avatar
        canSelect
        policy {
            defaultAccess
        }
      }
  }
}
`;

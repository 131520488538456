import { Backdrop, createMuiTheme, CssBaseline, ThemeProvider, Typography } from '@material-ui/core'
import { baseUrl } from 'app/api'
import jwtDecode from "jwt-decode"
import React, { useEffect } from 'react'
import { logout, storage } from "./index"

const theme = createMuiTheme({
  palette: {
    type: "dark"
  },
})

const NoAccessPage = () => {
  const idToken = storage.getItem(`${baseUrl.cookieScope}.id_token`)
  if (idToken) {
    const login = jwtDecode(idToken)["subscriber"]
    if (login) {
      sessionStorage.removeItem(`${baseUrl.cookieScope}.${login}.profile`)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      logout();
    }, 5000);
    return () => clearTimeout(timer)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop open={true}>
        <Typography color="secondary" variant="h5">You do not have access to view this profile.<br/>Switching to your primary profile...</Typography>
      </Backdrop>
    </ThemeProvider>
  )
}

export default NoAccessPage
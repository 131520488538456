export const plannerQuery = `query ($payPeriod: String) { 
  settings {
    planDate
  }
  planner (payPeriod: $payPeriod) {
    unusedEvents {
      id
      description
      date
      direction
	    amounts {
        amount
        category
      }
    }
    unusedYearlyEvents {
      id
      description
      date
      direction
      amounts {
        amount
        category
      }
    }
    events {
      id
      type
      description
      direction
      tags
      date
      amounts {
        amount
        category
      }
      entered
      link
    }
  }
}`

export const queryPlannedEvent = `
query planner ($id: String!) {
  plannedEvent(id: $id) {
    id
    type
    day
    startDate
    description
    direction
    amounts {
      category
      amount
    }
    tags
    link
  }
  settings {
    planDate
    tags
  }
  categories {
    name
    categories {
      name
    }
  }
}
`

export const queryCreatePlannedEvent = `
query planner {
  settings {
    planDate
    tags
  }
  categories {
    name
    categories {
      name
    }
  }
}
`

export const updatePlannedEventMutation = `mutation updatePlannedEvent($input: UpdatePlannedEventInput!) {
  updatePlannedEvent(input: $input) {
    id
    type
    day
    startDate
    description
    direction
    amounts {
      category
      amount
    }
    tags
    link
  }
}
`

export const createPlannedEventMutation = `mutation createPlannedEvent($input: CreatePlannedEventInput!) {
  createPlannedEvent(input: $input) {
    id
    type
    day
    month
    description
    direction
    amounts {
      category
      amount
    }
    tags
  }
}
`

export const removeMutation = `mutation removePlannedEvent($id: String!) {
  removePlannedEvent(id: $id)
}`

export const addTransactionsMutation = `
mutation createTransactionBulk ($input: [CreateTransactionInput!]!) {
  createTransactionBulk (input: $input) {
    id
  }
}`
import { reducer as snackReducer } from 'components/SnackMessage'
import moment from "moment"

const DEFAULT_STATE = {
  criteria: {
    account: "",
    payPeriod: "",
    accountLink: "",
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    category: "",
    cleared: "FALSE",
    direction: "",
    tags: [],
  },
  accounts: [],
  accountLinks: [],
  accountLinkAvailable: false,
  categories: [],
  allTags: [],
  viewCriteria: true,
  snack: { type: "", message: "" }
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SEARCHCRITERIA_UPDATE": {
      return {
        ...state,
        criteria: {
          ...state.criteria,
          ...action.payload
        }
      }
    }
    case "SEARCHCRITERIA_FULFILLED": {
      const accountLinks = action?.payload?.accountLinks?.reduce((p, c)=>([...p, ...c.accounts.map(a=>({
        id: a.id, 
        selected: a.selected,
        tags: a.tags,
        name: `${c.institutionName}: ${a.officialName}`,
        currentBalance: a.currentBalance
      }))]), [])?.filter(x=>x.selected)

      return {
        ...state,
        ...action.payload,
        accounts: action?.payload?.accounts?.map(a=>a.name),
        accountLinks,
        accountLinkAvailable: accountLinks?.length > 0,
        allTags: action?.payload?.settings?.tags,
        criteria: {
          ...state.criteria,
          account: action?.payload?.accounts?.find(a => a.defaultAccount)?.name || "",
          payPeriod: action?.payload?.payPeriod?.name || "",
        }
      }
    }
    case "SEARCHRESULTS_FULFILLED": {
      return {
        ...state,
        viewCriteria: false
      }
    }
    case "SEARCHCRITERIA_SHOW": {
      return {
        ...state,
        viewCriteria: true
      }
    }
    default:
      return snackReducer(state, action)
  }
}

const DEFAULT_STATE = (states) => {
  return states.reduce((p, name) => {
    return { ...p, [name]: { isFetching: false, isDirty: true } }
  }, {})
}
 
const stateManager = (states) => (state = DEFAULT_STATE(states), action) => {
  const stepMatches = /(UPDATE_)?([^_]*)(_.*)*_(DIRTY|PENDING|FULFILLED|REJECTED)/.exec(action.type)
  if (!stepMatches) return state
 
  const stateName = stepMatches[2].toLowerCase()
  const step = stepMatches.slice(-1)[0]
 
  const forceDirty1 = (action.type === "SIDEBAR_FULFILLED")
    ? {
      summary: { ...state.summary, isDirty: true },
      planner: { ...state.planner, isDirty: true },
      budget: { ...state.budget, isDirty: true },
      savings: { ...state.savings, isDirty: true },
      tracking: { ...state.tracking, isDirty: true },
      searchresults: { ...state.searchresults, isDirty: true },
      budgetfrequency: { ...state.budgetfrequency, isDirty: true },
    }
    : {}
 
  const forceDirty2 = (action.type === "UPDATEBALANCES_FULFILLED")
    ? {
      searchresults: { ...state.searchresults, isDirty: true },
    }
    : {}
 
  if (state[stateName] === undefined) {
    return {
      ...state,
      ...forceDirty1,
      ...forceDirty2,
    }
  }

  return {
    ...state,
    ...forceDirty1,
    ...forceDirty2,
    [stateName]: {
      ...state[stateName],
      isDirty: step === "DIRTY",
      isFetching: (step === "PENDING")
    }
  }
}

export default stateManager
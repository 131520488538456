
export const mergeArrays = (...arrays) => {
  let jointArray = []

  arrays.forEach(array => {
    jointArray = [...jointArray, ...array]
  })

  const uniqueArray = jointArray.reduce((newArray, item) =>{
    if (newArray.includes(item)){
      return newArray
    } else {
      return [...newArray, item]
    }
  }, [])
  
  return uniqueArray
}

export const swapItems = (list, source, destination) => {
  const result = Array.from(list)
  const [removed] = result.splice(source, 1)
  result.splice(destination, 0, removed)

  return result
}


export const deepCopy = inObject => {
  let outObject, value, key

  if(typeof inObject !== "object" || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = (typeof value === "object" && value !== null) ? deepCopy(value) : value
  }
  
  return outObject
}

export const removeAtIndex = (list, index) => {
  return [...list.slice(0, index), ...list.slice(index + 1)]
}


export const a11yProps = index => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}

const corePages = ["/", "/planner", "/budget", "/tracking", "/savings", "/search", "/profiles"]

export const goBack = (history, returnPage) => {
  if (corePages.includes(returnPage)) {
    history.push(returnPage)
  } else {
    history.push("/")
  }
}
export const searchCriteriaQuery = `query { 
  accounts(active: true) {
    name
    defaultAccount
  }
  payPeriod {
    name
  }
  categories {
    name
    type
    categories {
      name
    }
  }
  settings {
  	tags
  }
}`

export const searchCriteriaQueryWithLink = `query { 
  accounts(active: true) {
    name
    defaultAccount
  }
  payPeriod {
    name
  }
  accountLinks {
    institutionName
    accounts {
      id
      selected
      tags
      officialName
    }
  }
  categories {
    name
    type
    categories {
      name
    }
  }
  settings {
  	tags
  }
}`

export const searchQuery = `query ($startDate: String!, $endDate: String!, $account: String, $category: String, $direction: TransactionDirection, $cleared: Boolean, $tags: [String]!, $accountLinkId: String) {
  search (startDate: $startDate, endDate: $endDate, account: $account, category: $category, direction: $direction, cleared: $cleared, tags: $tags, accountLinkId: $accountLinkId) {
    transactions {
      id
      payPeriod
      account
      date
      description
      amount
      amounts {
        category
        amount
      }
      direction
      cleared
      tags
      matched
      matchCleared
    }
  }
  
  accountBalance (account: $account) {
    bankBalance
  }

}`

export const searchQueryWithLink = `query ($startDate: String!, $endDate: String!, $account: String, $category: String, $direction: TransactionDirection, $cleared: Boolean, $tags: [String]!, $accountLinkId: String) {
  search (startDate: $startDate, endDate: $endDate, account: $account, category: $category, direction: $direction, cleared: $cleared, tags: $tags, accountLinkId: $accountLinkId) {
    transactions {
      id
      payPeriod
      account
      date
      description
      amount
      amounts {
        category
        amount
      }
      direction
      cleared
      tags
      matched
      matchCleared
    }
    
    plaidTransactions {
      id
      date
      authorizedDate
      name
      merchantName
      amount
      category
      pending
      matched
    }
  }

  accountBalance (account: $account) {
    bankBalance
  }

  accountLink (id: $accountLinkId) {
    currentBalance
    lastUpdated
    tags
    type
  }
}`

export const tagTransactions = `mutation ($ids: [String!]!, $tags: [String!]!), {
	tagTransactions(ids: $ids, tags: $tags) {
    id
    tags
    cleared
  }
}`

export const untagTransactions = `mutation ($ids: [String!]!, $tag: String!, $alsoClear: Boolean!), {
	untagTransactions(ids: $ids, alsoClear: $alsoClear, tag: $tag) {
    id
    tags
    cleared
  }
}`

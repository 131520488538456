import { reducer as snackReducer } from 'components/SnackMessage'

const DEFAULT_STATE = {
  groups: [],
  priorGroups: null,
  returnPage: "/",
  error: "",
  snack: { type: "", message: "" }
}
/*
const reduceCurrentCategories = groups => groups.reduce((p1,c1) => 
        ([...p1, ...c1.categories.reduce((p2,c2) => ([...p2, ...[{id: c2.id, name: c2.name}]]), [])]), [])

const reduceCategoryNames = groups => groups.reduce((p1,c1) => 
        ([...p1, ...c1.categories.reduce((p2,c2) => ([...p2, ...[c2.name]]), [])]), [])
*/
export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "RETURN_PAGE": {
      return {
        ...state,
        returnPage: action.payload
      }
    }
    case "CATEGORIES_FULFILLED": {
      return {
        ...state,
        groups: action?.payload?.categories,
        error: null
      }
    }
    case "CATEGORIES_REJECTED": {
      return {
        ...state,
        error: action?.payload
      }
    }
    case "REORDER_GROUPS": {
      const source = action?.payload?.source
      const destination = action?.payload?.destination
      return {
        ...state,
        groups: state.groups.reduce((p,g,i) => {
          if (i === destination) {
            return [...p, ...[g, state.groups[source]]]
          } else if (i === source) {
            return p
          } else {
            return [...p, ...[g]]
          }
        }, []),
        priorGroups: state.groups
      }
    }
    case "MOVE_CATEGORY_TO_GROUP": {
      const sourceGroup = action?.payload?.sourceGroup
      const destinationGroup = action?.payload?.destinationGroup
      const category = action?.payload?.category

      return {
        ...state,
        groups: state.groups.map(g => {
          if (g.name === sourceGroup) {
            return {
              ...g, 
              categories: g.categories.filter(c=>c.id !== category.id)
            }
          } else if (g.name === destinationGroup) {
            return {
              ...g, 
              categories: [
                ...g.categories,
                ...[category]
              ]
            }
          } else {
            return g
          }
        }),
        priorGroups: state.groups
      }
    }
    case "REORDER_CATEGORIES": {
      const sourceCategory = action?.payload?.sourceCategory
      const destinationCategory = action?.payload?.destinationCategory
      const destinationGroup = action?.payload?.destinationGroup

      return {
        ...state,
        groups: state.groups.map(g => {
          return {
            ...g, 
            categories: g.categories.filter(c=>c.id !== sourceCategory.id)
          }
        }).map(g => {
          if (destinationCategory) {
            return {
              ...g, 
              categories: g.categories.reduce((p, c)=>{
                if (c.id === destinationCategory.id) {
                  return [...p, ...[sourceCategory, c]]
                } else {
                  return [...p, ...[c]]
                }
              }, [])
            }
          } else {
            if (g.name === destinationGroup) {
              return {
                ...g, 
                categories: [...g.categories, ...[sourceCategory]]
              }
            } else {
              return g
            }
          }
        }),
        priorGroups: state.groups
      }
    }
    case "UPDATECATEGORIES_PENDING": {
      return {
        ...state,
        priorGroups: state.priorGroups ? state.priorGroups : state.groups
      }
    }
    case "UPDATECATEGORIES_FULFILLED": {
      const groups =  action?.payload?.createCategoryGroup ||
                      action?.payload?.updateCategoryGroupName ||
                      action?.payload?.removeCategoryGroup ||
                      action?.payload?.updateCategoryGroupOrder ||
                      action?.payload?.createCategory ||
                      action?.payload?.updateCategory ||
                      action?.payload?.removeCategory ||
                      action?.payload?.updateCategoryOrder ||
                      false
      
      if (!groups) {
        return {
          ...state,
          priorGroups: null
        }
      }
      return {
        ...state,
        groups,
        error: null,
        priorGroups: null
      }
    }
    case "UPDATECATEGORIES_REJECTED": {
      return {
        ...state,
        groups: state.priorGroups,
        priorGroups: null
      }
    }
    default:
      return snackReducer(state, action)
  }
}
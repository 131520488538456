import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { CallbackPage, ErrorPage, isAuthenticated, NoAccessPage, pageLoaded, StopPage, storage } from "auth";
import Theme from "components/Theme";
import BudgetPage from "pages/product/Budget";
import PlannerPage from "pages/product/Planner";
import SavingsPage from "pages/product/Savings";
import SearchPage from "pages/product/Search";
import SummaryPage from "pages/product/Summary";
import TrackingPage from "pages/product/Tracking";
import AccountsPage from "pages/settings/Accounts";
import SecurityPage from "pages/settings/Security";
import Analytics from "components/Analytics";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import store from "./app/store";

import "typeface-oxygen";
import "typeface-roboto";
import { baseUrl } from "app/api";

const PaymentErrorPage = lazy(() => import("pages/misc/Payment/ErrorPage"));
const PaymentSuccessPage = lazy(() => import("pages/misc/Payment/SuccessPage"));
const PricingPage = lazy(() => import("pages/misc/Pricing"));
const SetupPage = lazy(() => import("pages/misc/Setup"));
const SharingAcceptPage = lazy(() => import("pages/misc/Sharing/Accept"));
const SharingDeclinePage = lazy(() => import("pages/misc/Sharing/Decline"));
const NotificationsPage = lazy(() => import("pages/product/Notifications"));
const PayPeriodsPage = lazy(() => import("pages/settings/BudgetFrequency"));
const ProfilesPage = lazy(() => import("pages/settings/Profiles"));
const CategoriesPage = lazy(() => import("pages/settings/Categories"));
const SettingsPage = lazy(() => import("pages/settings/Settings"));

const start = async () => {
  if (
    storage.getItem(`${baseUrl.cookieScope}.beta`) === "true" &&
    (window.location.hostname === "app.budgetocity.com" || window.location.hostname === "app.test.budgetocity.com")
  ) {
    window.location.href = baseUrl.altDomain;
    return;
  }

  if (await isAuthenticated(store.dispatch)) {
    ReactDOM.render(
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <Theme>
              <Route component={Analytics} />
              <Route component={pageLoaded} />
              <Suspense fallback={<></>}>
                <Switch>
                  <Route path="/" exact component={SummaryPage} />
                  <Route path="/notifications" exact component={NotificationsPage} />
                  <Route path="/planner" exact component={PlannerPage} />
                  <Route path="/budget" exact component={BudgetPage} />
                  <Route path="/tracking" exact component={TrackingPage} />
                  <Route path="/savings" exact component={SavingsPage} />
                  <Route path="/search" exact component={SearchPage} />
                  <Route path="/settings" exact component={SettingsPage} />
                  <Route path="/profiles" exact component={ProfilesPage} />
                  <Route path="/security" exact component={SecurityPage} />
                  <Route path="/categories" exact component={CategoriesPage} />
                  <Route path="/accounts" exact component={AccountsPage} />
                  <Route path="/budget-frequency" exact component={PayPeriodsPage} />
                  <Route path="/setup" exact component={SetupPage} />
                  <Route path="/loading" exact component={CallbackPage} />
                  <Route path="/error" exact component={ErrorPage} />
                  <Route path="/pricing" exact component={PricingPage} />
                  <Route path="/payment/success" exact component={PaymentSuccessPage} />
                  <Route path="/payment/error" exact component={PaymentErrorPage} />
                  <Route path="/sharing/accept" exact component={SharingAcceptPage} />
                  <Route path="/sharing/decline" exact component={SharingDeclinePage} />
                  <Redirect to="/" />
                </Switch>
              </Suspense>
            </Theme>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </Provider>,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <BrowserRouter>
        <Switch>
          <Route path="/loading" exact component={CallbackPage} />
          <Route path="/callback" exact component={CallbackPage} />
          <Route path="/error" exact component={ErrorPage} />
          <Route path="/fullstop" exact component={StopPage} />
          <Route path="/noaccess" exact component={NoAccessPage} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>,
      document.getElementById("root")
    );
  }
};

start();

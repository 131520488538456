import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { baseUrl, get, query } from "app/api";
import { useAuthorization } from "auth";
import { PAGE_SIZE } from "pages/product/Notifications";
import { profilesQuery } from "pages/settings/Profiles/queries";
import { sharingQuery } from "pages/settings/Settings";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { queryNotifications } from "../pages/product/Notifications/queries";
import NavHeader from "./Header";
import NavSettings from "./NavSettings";
import NavSidebar from "./NavSidebar";




const DEFAULT_STATE = {
  loginAvatar: "/images/blank-avatar.jpeg",
  profileAvatar: null,
  name: "",
  showSavings: false,
  sidebarOpen: false,
  settingsOpen: false,
  confetti: 0,
  profiles: {
    myProfiles: [],
    otherProfiles: [],
  },
  profileShortcuts: [],
  adminLink: null,
  sharing: [],
  snack: { type: "", message: "" },
};

const sendUnreadCountToNative = (count) => {
  try {
    if (window?.webkit) {
      window?.webkit?.messageHandlers.callback.postMessage({
        type: "unreadCount",
        value: count,
      });
    }
  } catch (err) {
    console.log("Can not reach native code");
  }
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "W00T": {
      return {
        ...state,
        confetti: Math.random()
      };
    }
    case "ADMIN_LINK_FULFILLED": {
      return {
        ...state,
        adminLink: action?.payload?.adminLink,
      };
    }
    case "AVATAR_LOGIN_FULFILLED": {
      return {
        ...state,
        loginAvatar: action?.payload?.data,
      };
    }
    case "AVATAR_PROFILE_FULFILLED": {
      return {
        ...state,
        profileAvatar: action?.payload?.data,
      };
    }
    case "HEADER_FULFILLED": {
      const accounts = action.payload?.accounts || [];
      const savingsIndex = accounts.findIndex((a) => a.type === "SAVINGS");

      return {
        ...state,
        showSavings: savingsIndex !== -1
      };
    }
    case "NOTIFICATIONS_FULFILLED": {
      sendUnreadCountToNative(action.payload?.notifications?.counts?.unread);

      return {
        ...state,
        notificationCount: action.payload?.notifications?.counts,
      };
    }
    case "UPDATE_NOTIFICATION_FULFILLED": {
      sendUnreadCountToNative(action.payload.updateNotification.counts?.unread);
      return {
        ...state,
        notificationCount: action.payload.updateNotification.counts,
        error: "",
      };
    }
    case "TOGGLE_NAV_SIDEBAR": {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    }
    case "OPEN_NAV_SIDEBAR": {
      return {
        ...state,
        sidebarOpen: true,
      };
    }
    case "CLOSE_NAV_SIDEBAR": {
      return {
        ...state,
        sidebarOpen: false,
      };
    }
    case "TOGGLE_NAV_SETTINGS": {
      return {
        ...state,
        settingsOpen: !state.settingsOpen,
      };
    }
    case "SHARING_FULFILLED": {
      return {
        ...state,
        sharing: action.payload?.sharing?.sharedWithMe?.filter((s) => s.pending && !s.expired) || [],
      };
    }
    case "ACCEPT_SHARING_FULFILLED":
    case "DECLINE_SHARING_FULFILLED": {
      const sharing = action.payload?.updateSharingAccept || action.payload?.updateSharingDecline;
      return {
        ...state,
        sharing: sharing?.filter((s) => s.pending && !s.expired),
        profiles: {
          ...state.profiles,
          otherProfiles: sharing?.filter((s) => !s.pending),
        },
        snack: { type: "success", message: "Complete" },
      };
    }
    case "ACCEPT_SHARING_REJECTED":
    case "DECLINE_SHARING_REJECTED": {
      return {
        ...state,
        snack: { type: "error", message: action.payload },
      };
    }
    case "OPEN_NAV_SETTINGS": {
      return {
        ...state,
        settingsOpen: true,
      };
    }
    case "CLOSE_NAV_SETTINGS": {
      return {
        ...state,
        settingsOpen: false,
      };
    }
    case "PROFILES_FULFILLED": {
      return {
        ...state,
        profiles: action.payload?.profiles,
        profileShortcuts: [
          ...action.payload?.profiles?.myProfiles?.filter((x) => x.complete),
          ...action.payload?.profiles?.otherProfiles?.filter((x) => x.shortCut),
        ],
      };
    }
    case "UPDATE_PROFILE_SHORTCUT_FULFILLED": {
      const profiles = {
        myProfiles: state.profiles?.myProfiles,
        otherProfiles: state.profiles?.otherProfiles.map((p) => {
          if (p.profile === action?.payload?.updateProfileShortCut?.profile) {
            return {
              ...p,
              shortCut: action?.payload?.updateProfileShortCut?.shortCut,
            };
          }
          return p;
        }),
      };

      return {
        ...state,
        profiles,
        profileShortcuts: [
          ...profiles?.myProfiles?.filter((x) => x.complete),
          ...profiles?.otherProfiles?.filter((x) => x.shortCut),
        ],
      };
    }
    case "CLEAR_SNACK": {
      return { ...state, snack: { type: "", message: "" } };
    }
    default:
      return state;
  }
};

const queryHeader = () =>
`query {
  accounts (active: true) {
    type
  }
}`;

const Navigation = ({ title, children }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const largeFormat = useMediaQuery(theme.breakpoints.up("lg"));
  const auth = useSelector((state) => state.auth);

  const notificationAvailable = useAuthorization("Notification", "Partner");
  const notificationState = useSelector((state) => ({ ...state.stateManager.notifications, ...state.notifications }));

  const admin = useAuthorization("Admin", "Admin");
  const sharingAllowed = useAuthorization("Profiles", "Privileged");
  const profilesAvailable = auth.login === auth.subscriber;

  const header = useSelector((state) => ({
    ...state.stateManager.header,
    ...state.header,
  }));

  useEffect(() => {
    if (!notificationState.isFetching && notificationState.isDirty) {
      dispatch({
        type: "NOTIFICATIONS",
        payload: query(auth, queryNotifications, { start: notificationState.start, count: PAGE_SIZE }),
      });
    }
    // eslint-disable-next-line 
  }, [notificationState]);

  useEffect(() => {
    if (!header.isFetching && header.isDirty) {
      const defaultProfile = `${auth.subscriber}#0`;
      dispatch({
        type: "AVATAR_LOGIN",
        payload: get(auth, "/avatar", baseUrl.avatar, true),
      });

      if (defaultProfile !== auth.profile) {
        dispatch({
          type: "AVATAR_PROFILE",
          payload: get(auth, "/avatar", baseUrl.avatar),
        });
      }

      if (admin) {
        dispatch({
          type: "ADMIN_LINK",
          payload: query(auth, `query adminLink { adminLink }`),
        });
      }

      dispatch({
        type: "HEADER",
        payload: query(auth, queryHeader()),
      });

      if (profilesAvailable) {
        dispatch({ type: "PROFILES", payload: query(auth, profilesQuery, {}, `${auth.subscriber}#0`) });
      }

      if (sharingAllowed) {
        dispatch({ type: "SHARING", payload: query(auth, sharingQuery) });
      }
    }
  }, [dispatch, admin, auth, header, notificationAvailable, profilesAvailable, sharingAllowed]);

  useEffect(() => {
    if (largeFormat && !header.sidebarOpen) {
      dispatch({ type: "OPEN_NAV_SIDEBAR" });
    } else if (!largeFormat && header.sidebarOpen) {
      dispatch({ type: "CLOSE_NAV_SIDEBAR" });
    }
    // eslint-disable-next-line
  }, [dispatch, largeFormat]);

  return (
    <Box display="flex">
      <NavSidebar />
      <NavSettings />
      <Box width="100%" flexGrow={1}>
        <NavHeader title={title} />
        {children}
      </Box>
    </Box>
  );
};

export default Navigation;

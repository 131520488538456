import { reducer as snackReducer } from 'components/SnackMessage'

const DEFAULT_STATE = {
  canAdd: false,
  savings: {
    funds: [],
    accounts: [],
    total: 0
  },
  error: "",
  snack: { type: "", message: "" }
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SAVINGS_FULFILLED": {
      return {
        ...state,
        ...action.payload,
        canAdd: action.payload?.parentCategory?.categories?.length > 0,
        error: ""
      }
    }
    case "SAVINGS_REJECTED": {
      return {
        ...state,
        error: action?.payload
      }
    }
    default:
      return snackReducer(state, action)
  }
}

import { Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Radio, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core"
import { Add, ArrowDropDown, Edit } from "@material-ui/icons"
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { query } from "app/api"
import Amount from "components/Amount"
import { updateSavingsBalances } from "pages/product/Savings/queries"

import EditAccount from "./EditAccount"
import { createAccount, mutateAccount, queryAccount } from "./queries"

const NEW_ACCOUNT = {
  defaultAccount: false,
  name: "",
  startingBalance: 0,
  buffer: 0,
  active: true,
  type: "SPENDING"
}


const AccountTab = ({setEditAccountWaiting}) => {
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)
  const state = useSelector(state => ({...state.stateManager.accounts, ...state.accounts}))

  const [editAccountOpen, setEditAccountOpen] = useState(false)
  const [accountToEdit, setAccountToEdit] = useState(NEW_ACCOUNT)
  const [adding, setAdding] = useState(false)

  const selectDefaultAccount = (id) => {
    dispatch({
      type: "UPDATE_ACCOUNT",
      payload: state.accounts.map(a => {
        if (a.id === id) {
          return {
            ...a,
            defaultAccount: true
          }
        } else {
          return {
            ...a,
            defaultAccount: false
          }
        }
      })
    })
    
    query(auth, mutateAccount, {input: { id, defaultAccount: true }}).then(() => {
      dispatch({type: "ACCOUNTS_DIRTY"})
      dispatch({type: "SIDEBAR_DIRTY"})
    }).catch(ex => {
      dispatch({ type: "SET_SNACK", error: ex })
    })
  }

  const updateAccountActive = (id, active) => {
    dispatch({
      type: "UPDATE_ACCOUNT",
      payload: state.accounts.map(a => {
        if (a.id === id) {
          return {
            ...a,
            active
          }
        } else {
          return a
        }
      })
    })

    query(auth, mutateAccount, {input: { id, active }}).then(() => {
      dispatch({type: "ACCOUNTS_DIRTY"})
      dispatch({type: "SIDEBAR_DIRTY"})
    }).catch(ex => {
      dispatch({ type: "SET_SNACK", error: ex })
    })
  }
  
  const editAccount = (popupState, id) => {
    setAdding(false)
    popupState.close()
    setEditAccountWaiting(true)
    query(auth, queryAccount, { id }).then(t => {
      setAccountToEdit(t.account)
      setEditAccountOpen(true)
    }).catch(ex =>{
      dispatch({ type: "SET_SNACK", error: ex })
    }).finally(()=>setEditAccountWaiting(false))
  }

  const addAccount = () => {
    setAdding(true)
    setAccountToEdit(NEW_ACCOUNT)
    setEditAccountOpen(true)
  }

  const submitCreateAccount = account => {
    return query(auth, createAccount, { input: {
      name: account.name,
      type: account.type,
      startingBalance: account.startingBalance?account.startingBalance:0,
      buffer: account.buffer?account.buffer:0
    }}).then(_ => {
      query(auth, updateSavingsBalances, {});

      dispatch({ type: "ACCOUNTS_DIRTY" })
      dispatch({ type: "SAVINGS_DIRTY" })
      dispatch({ type: "SIDEBAR_DIRTY" })
      dispatch({ type: "SET_SNACK", success: "Complete" })

      setEditAccountOpen(false)
    })
  }

  const submitEditAccount = account => {
    return query(auth, mutateAccount, {
      input: account
    }).then(_ => {
      dispatch({ type: "ACCOUNTS_DIRTY" })
      dispatch({ type: "SIDEBAR_DIRTY" })
      dispatch({ type: "SET_SNACK", success: "Complete" })
      setEditAccountOpen(false)
    })
  }

  const Accounts = ({title, type, accounts}) => (
    <React.Fragment>
      <Box mb={2}>
        <Typography color="secondary" variant="h4">{title}</Typography>
      </Box>
      <Box mb={8}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {type === "SPENDING" && <TableCell className="small">Default</TableCell>}
                <TableCell>Account Name</TableCell>
                <TableCell className="right">Starting Balance</TableCell>
                {type === "SPENDING" && <TableCell className="right">Buffer</TableCell>}
                <TableCell className="small">Active</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map(account => (
                <TableRow key={`account-${account.id}`}>
                  {type === "SPENDING" && 
                    <TableCell className="center">
                      <Radio
                        checked={account.defaultAccount}
                        onChange={()=>selectDefaultAccount(account.id)}
                        disabled={!account.active}
                        name={`default-${account.id}`}
                        inputProps={{ 'aria-label': 'Make account the default' }}
                      />
                    </TableCell>
                  }
                  <TableCell>
                    {account.name}
                  </TableCell>
                  <TableCell className="right">
                    <Amount amount={account.startingBalance} />
                  </TableCell>
                  {type === "SPENDING" && 
                    <TableCell className="right">
                      <Amount amount={account.buffer} />
                    </TableCell>
                  }
                  <TableCell className="center">
                    <Switch checked={account.active} color="primary" disabled={account.defaultAccount} onChange={()=>updateAccountActive(account.id, !account.active)}/>
                  </TableCell>
                  <TableCell>
                    <PopupState variant="popover" popupId={`account-menu-${account.id}`}>
                      {popupState => (
                        <React.Fragment>
                          <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                            <ArrowDropDown/>
                          </IconButton>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => editAccount(popupState, account.id)}>
                              <ListItemIcon><Edit /></ListItemIcon>
                              <ListItemText primary="Edit"/>
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Box display="flex">
        <Box flexGrow={1}>
        </Box>
        <Box style={{whiteSpace: "nowrap", marginLeft: "16px"}}>
          <Button color="primary" variant="contained" startIcon={<Add />} onClick={addAccount}>Add Account</Button>
        </Box>
      </Box>

      <Accounts title="Checking/Spending Accounts" type="SPENDING" accounts={state.accounts.filter(x=>x.type==="SPENDING")} />
      <Accounts title="Savings Accounts" type="SAVINGS" accounts={state.accounts.filter(x=>x.type==="SAVINGS")} />
      <Accounts title="Credit Cards" type="CREDIT" accounts={state.accounts.filter(x=>x.type==="CREDIT")} />
      <Accounts title="Other Accounts" type="UNCLASSIFIED" accounts={state.accounts.filter(x=>x.type==="UNCLASSIFIED")} />

      <EditAccount
        open={editAccountOpen}
        cancel={() => setEditAccountOpen(false)}
        submit={adding?submitCreateAccount:submitEditAccount}
        account={accountToEdit}
        title={adding?"Add Account":"Edit Account"} />

    </React.Fragment>
  )
}

export default AccountTab

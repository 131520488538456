
const DEFAULT_STATE = {
  transactions: [],
  accountLinkTransactions: [],
  accountLinkLastUpdated: "",
  balanceType: localStorage.getItem("searchBalanceType") || "DEPOSIT",
  count: 0,
  total: 0,
  statementBalance: "",
  bankBalance: "",
  firstSelectedPayPeriod: "",
  selectedIds: [],
  difference: 0,
  selectedCount: 0,
  selectedTotal: 0,
  error: ""
}

const totalTransactions = (transactions) => {
  return transactions.reduce((p,t) => {
    return p + (t.amounts.reduce((p2,a) => p2 + a.amount, 0) * ((t.direction === "CREDIT")?1:-1))
  }, 0)
}

const findFirstSelectedPayPeriod = transactions => {
  return transactions.reduce((p,c) => {
    if ((p === "") || (c.payPeriod < p)) {
      return c.payPeriod
    } else {
      return p
    }
  }, "")
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SEARCHRESULTS_NEWBALANCE": {
      return {
        ...state,
        statementBalance: action.payload
      }
    }
    case "UPDATE_BALANCE_TYPE": {
      localStorage.setItem("searchBalanceType", action.payload)
      return {
        ...state,
        balanceType: action.payload,
      }
    }
    case "SEARCHCRITERIA_UPDATE": {
      return {
        ...state,
        statementBalance: "",
        firstSelectedPayPeriod: "",
        selectedIds: [],
        selectedCount: 0,
        selectedTotal: 0
      }
    }
    case "SEARCHRESULTS_FULFILLED": {
      const transactions = action?.payload?.search?.transactions?.map(t => ({
        ...t,
        selected: state.selectedIds.indexOf(t.id) !== -1
      }))
      const total = totalTransactions(transactions)

      const selectedTransactions = transactions.filter(t=>t.selected)
      const firstSelectedPayPeriod = findFirstSelectedPayPeriod(selectedTransactions)
      const selectedTotal = totalTransactions(selectedTransactions)
      const selectedIds = selectedTransactions.map(t=>t.id)
      const accountLinkTransactions = action.payload?.search?.plaidTransactions || []

      if (accountLinkTransactions?.length > 0) {
        return {
          ...state,
          transactions,
          accountLinkTransactions,
          accountLinkLastUpdated: action.payload?.accountLink?.lastUpdated,
          balanceType: (action.payload?.accountLink?.type === "DEPOSITORY") ? "DEPOSIT" : "CREDIT",
          count: transactions.length,
          total,
          statementBalance: action?.payload?.accountLink?.currentBalance || "",
          bankBalance: action?.payload?.accountBalance?.bankBalance || 0,
          selectedCount: selectedTransactions.length,
          selectedTotal,
          selectedIds,
          firstSelectedPayPeriod,
          error: ""
        }  
      }


      return {
        ...state,
        transactions,
        accountLinkTransactions,
        count: transactions.length,
        total,
        statementBalance: action?.payload?.accountLink?.currentBalance || "",
        selectedCount: selectedTransactions.length,
        selectedTotal,
        selectedIds,
        firstSelectedPayPeriod,
        error: ""
      }
    }
    case "SEARCHRESULTS_REJECTED": {
      return {
        ...state,
        error: action?.payload
      }
    }
    case "SEARCHRESULTS_SELECT": {
      const id = action.payload.id
      const value = action.payload.value

      const transactions = state.transactions.map(t=>{
        if (t.id === id) {
          return {
            ...t,
            selected: value
          }
        }
        return t
      })

      const selectedTransactions = transactions.filter(t=>t.selected)
      const firstSelectedPayPeriod = findFirstSelectedPayPeriod(selectedTransactions)
      const selectedTotal = totalTransactions(selectedTransactions)
      const selectedIds = selectedTransactions.map(t=>t.id)

      return {
        ...state,
        transactions,
        selectedIds,
        firstSelectedPayPeriod,
        selectedCount: selectedTransactions.length,
        selectedTotal
      }
    }
    case "SEARCHRESULTS_SELECTALL": {
      if (state.transactions.count === 0) {
        return state
      }

      const value = !state.transactions[0].selected

      const transactions = state.transactions.map(t=>{
        return {
          ...t,
          selected: value
        }
      })

      const selectedIds = value ? 
        transactions.map(t=>t.id)
      :
        []

      const selectedTransactions = transactions.filter(t=>t.selected)
      const firstSelectedPayPeriod = findFirstSelectedPayPeriod(selectedTransactions)
      const selectedTotal = totalTransactions(selectedTransactions)

      return {
        ...state,
        transactions,
        selectedIds: selectedIds,
        firstSelectedPayPeriod,
        selectedCount: selectedTransactions.length,
        selectedTotal
      }
    }
    default:
      return state
  }
}

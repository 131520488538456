import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link as LinkIcon, LinkOff } from "@material-ui/icons";
import moment from "moment-timezone";
import React, { useCallback, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useDispatch, useSelector } from "react-redux";

import { query } from "app/api";
import Amount from "components/Amount";
import Confirmation from "components/Confirmation";
import TypeFormat from "pages/settings/Accounts/TypeFormat";

import {
  createAccountLink,
  createAccountLinkToken,
  createAccountRelinkToken,
  removeAccountLink,
  updateAccountLinkMutation,
} from "./queries";
import { Autocomplete } from "@material-ui/lab";

const AccountLinkTab = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const state = useSelector((state) => ({ ...state.stateManager.accounts, ...state.accounts }));

  const [showLinkDisclaimer, setShowLinkDisclaimer] = useState(false);
  const [linkToken, setLinkToken] = useState(null);

  const [unlinkConfirmationOpen, setUnlinkConfirmationOpen] = useState(false);
  const [unlinkConfirmationSubmit, setUnlinkConfirmationSubmit] = useState(() => {});
  const [unlinkConfirmationMessage, setUnlinkConfirmationMessage] = useState("");

  const beginLinkAccount = () => {
    setLinkToken(null);

    query(auth, createAccountLinkToken)
      .then(({ createAccountLinkToken }) => {
        setLinkToken(createAccountLinkToken);
        setShowLinkDisclaimer(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      });
  };

  const endLinkAccount = () => {
    setShowLinkDisclaimer(false);
    setTimeout(() => {
      document.body.style = "";
    }, 1000);
  };

  const beginRelinkAccount = (id) => {
    setLinkToken(null);

    query(auth, createAccountRelinkToken, { id })
      .then(({ createAccountRelinkToken }) => {
        setLinkToken(createAccountRelinkToken);
        setShowLinkDisclaimer(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      });
  };

  const LinkAccount = ({ token }) => {
    const onExit = useCallback(() => {
      endLinkAccount();
    }, []);

    const onEvent = useCallback((eventName) => {
      if (eventName === "HANDOFF") {
        endLinkAccount();
      }
    }, []);

    const onSuccess = useCallback((_, metadata) => {
      query(auth, createAccountLink, { input: metadata })
        .then((_) => {
          dispatch({ type: "ACCOUNTS_DIRTY" });
          dispatch({ type: "SIDEBAR_DIRTY" });
        })
        .catch((ex) => {
          dispatch({ type: "SET_SNACK", error: ex });
        });
    }, []);

    const config = {
      token,
      onSuccess,
      onExit,
      onEvent,
    };

    const { open, exit, ready, error } = usePlaidLink(config);

    if (error) {
      dispatch({ type: "SET_SNACK", error });
    }

    return (
      <React.Fragment>
        <Button
          onClick={() => {
            exit();
            endLinkAccount();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button onClick={open} disabled={!ready || error} color="primary" variant="contained">
          Continue
        </Button>
      </React.Fragment>
    );
  };

  const unlinkConfirmation = (id, name) => {
    setUnlinkConfirmationMessage(`Are you sure you want to remove the connection to all your ${name} accounts?`);
    setUnlinkConfirmationOpen(true);
    setUnlinkConfirmationSubmit(() => unlinkAccount(id));
  };

  const unlinkAccount = (id) => () => {
    query(auth, removeAccountLink, { id })
      .then((_) => {
        dispatch({ type: "ACCOUNTS_DIRTY" });
        dispatch({ type: "SIDEBAR_DIRTY" });

        setUnlinkConfirmationOpen(false);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      });
  };

  const updateAccountLink = (id, selected, tags) => {
    query(auth, updateAccountLinkMutation, { input: { id, selected, tags: tags } })
      .then((_) => {
        dispatch({ type: "ACCOUNTS_DIRTY" });
        dispatch({ type: "SIDEBAR_DIRTY" });
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      });
  };

  const Institution = ({ institution, hasLimit }) => (
    <Box mb={6}>
      <Box display="flex">
        <Box flexGrow={1} mt={2} mb={0} pb={0} component="h2">
          <Hidden smDown>Institution:&nbsp;</Hidden>
          {institution.institutionName}
        </Box>
        <Box mt={2} style={{ whiteSpace: "nowrap", marginLeft: "16px" }}>
          <Button
            color="secondary"
            startIcon={<LinkOff />}
            onClick={() => unlinkConfirmation(institution.id, institution.institutionName)}
          >
            Unlink Account
          </Button>
        </Box>
      </Box>
      <Box display="flex" mb={1} alignItems="center">
        Status: {institution.status}
        {institution.loggedOut && (
          <Box ml={2}>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => beginRelinkAccount(institution.id)}
            >
              Login
            </Button>
          </Box>
        )}
        <Box flexGrow={1}></Box>
        <Box fontStyle="italic">
          <Typography variant="body2">
            Last updated {moment(institution.lastUpdated + "Z").format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell className="right">Current Balance</TableCell>
              {hasLimit && <TableCell className="right">Limit</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <AccountList accounts={institution.accounts} hasLimit={hasLimit} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const AccountList = ({ accounts, hasLimit }) => (
    <React.Fragment>
      {accounts.map((account, i) => (
        <TableRow key={`account${i}`}>
          <TableCell>
            <Checkbox
              checked={account.selected}
              onClick={() => updateAccountLink(account.id, !account.selected, account.tags)}
            />
          </TableCell>
          <TableCell>
            <Box>{account.officialName}</Box>
            <Box fontSize="0.9em">
              <TypeFormat>{account.subType}</TypeFormat>
            </Box>
          </TableCell>
          <TableCell>
            <Autocomplete
              multiple
              options={state?.tags || []}
              value={account.tags}
              freeSolo
              onChange={(_, t) => {
                updateAccountLink(account.id, account.selected, t)
              }}
              renderTags={(value, getTagProps) =>
                value.map
                  ? value.map((option, index) => (
                      <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  : [value]
              }
              renderInput={(x) => <TextField {...x} name="tags" margin="dense" color="secondary" />}
            />
          </TableCell>
          <TableCell className="right">
            <Amount amount={account.currentBalance} />
          </TableCell>
          {hasLimit && (
            <TableCell className="right">
              {account.subType === "CREDIT_CARD" && <Amount amount={account.limit} />}
            </TableCell>
          )}
        </TableRow>
      ))}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Box display="flex" mb={2}>
        <Box flexGrow={1}></Box>
        <Box style={{ whiteSpace: "nowrap", marginLeft: "16px" }}>
          <Button color="primary" variant="contained" startIcon={<LinkIcon />} onClick={beginLinkAccount}>
            Add Account Link
          </Button>
        </Box>
      </Box>

      <Typography variant="body1" paragraph gutterBottom>
        <strong>Account Link</strong> is a feature that allows you to link your Budgetocity™ tracking accounts to real
        bank, credit and investment institutions. As a premium subscriber to Budgetocity™, you have access to Account
        Link to quickly synchronize your budget tracking with live accounts. You can also be alerted when new
        transactions become available to add to your tracking.
      </Typography>

      <Typography variant="body1" paragraph gutterBottom>
        Budgetocity, LLC uses a third party provider,{" "}
        <Link href="https://plaid.com/how-it-works-for-consumers/" mr={2} rel="noreferrer" target="_blank">
          Plaid
        </Link>
        , to connect our customers to their live accounts. Plaid provides safe, reliable connections to several banking
        institutions. The use of your bank password and other verification details will remain between you and your
        bank. Budgetocity does not store your banking credentials on any of its systems or databases.
      </Typography>

      {!state?.accountLinks?.length && (
        <Typography paragraph variant="body1" gutterBottom>
          Click "Add Account Link" to begin the process of connecting to your bank. You can remove a link at any time,
          and add that link back again later if you wish.
        </Typography>
      )}

      {state?.accountLinks?.map((institution, i) => (
        <Institution
          key={`institution${i}`}
          institution={institution}
          hasLimit={institution?.accounts.filter((x) => x.subType === "CREDIT_CARD").length > 0}
        />
      ))}

      <Dialog
        open={showLinkDisclaimer && !!linkToken}
        aria-labelledby="createlink-dialog-title"
        aria-describedby="createlink-dialog-description"
        disableEnforceFocus
      >
        <DialogTitle id="createlink-dialog-title">Link Accounts</DialogTitle>
        <DialogContent>
          <DialogContentText id="createlink-dialog-description">
            By clicking, "Continue", you agree to use Plaid to find and authenticate to your bank. You will be
            temporarily redirected to Plaid to continue, and return when you're connection is complete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LinkAccount token={linkToken} />
        </DialogActions>
      </Dialog>

      <Confirmation
        open={unlinkConfirmationOpen}
        cancel={() => setUnlinkConfirmationOpen(false)}
        submit={unlinkConfirmationSubmit}
        message={unlinkConfirmationMessage}
      />
    </React.Fragment>
  );
};

export default AccountLinkTab;

import { Backdrop, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import styled from 'styled-components'

const LoadingDiv = styled(Backdrop)`
  z-index: 200;
  color: #fff;
  font-size: 2em;
`

const Progress = styled(CircularProgress)`
  margin-right: 1em;
  color: #fff;
`

const Loading = ({loading = false, hard = false, error=null, children}) => {
  if (error && error !== "") {
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    )
  }

  if (hard) {
    return (
      <LoadingDiv open={true}>
        <Progress/>
      </LoadingDiv>
    )
  }

  return (
    <React.Fragment>
      <LoadingDiv open={loading}><Progress/></LoadingDiv>
      {children}
    </React.Fragment>
  )
}

export default Loading
export const sidebarQuery = `query ($payPeriod: String!, $account: String!) { 
  settings {
    budgetFrequency
  }
  payPeriod (payPeriod: $payPeriod) {
    name
    previous
    next
    isCurrent
    prettyRange
    startDate
    endDate
  }
  accounts(active: true) {
    name
    type
    defaultAccount
  }
  payPeriods {
    name
    prettyRange
    isCurrent
  }
  accountBalance (payPeriod: $payPeriod, account: $account) {
    startingBalance
    bankBalance
    currentBalance
    account
  }
  budgetBalance (payPeriod: $payPeriod) {
    income
    spending
    difference
    groups {
      name
      amount
    }
  }
}`;

export const newTransactionQuery = `query newTransaction {
    accounts (active: true) {
      name
      type
      defaultAccount
    }
    payPeriods {
      name
      isCurrent
    }
    typeAhead {
      description
      categories
      tags
      direction
    }
    categories {
      name
      categories {
        name
      }
    }
    settings {
      tags
    }
}`;

export const newTransactionMutation = `mutation newTransaction($input: CreateTransactionInput!) {
  createTransaction(input: $input) {
    id
    payPeriod
    account
    date
    description
    amount
    amounts {
      category
    }
    direction
    cleared
    tags
  }
}`;

export const rebalanceAccountsMutation = `mutation rebalanceAccount ($payPeriod: String, $account: String!, $balance: Int!) {
  rebalanceAccount (payPeriod: $payPeriod, account: $account, balance: $balance)
}`;

export const updateAccountBalances = `
  mutation updateAccountBalances ($account: String, $payPeriod: String) {
    updateAccountBalances (account: $account, payPeriod: $payPeriod) {
      name
      payPeriod
      startingBalance
      bankBalance
      currentBalance
    }
  }
`;

export const createPayPeriodMutation = `
  mutation newPayPeriod {
    createPayPeriod {
      payPeriod {
        name
        previous
        next
        isCurrent
        prettyRange
        endDate
      }
      payPeriods {
        name
        prettyRange
        isCurrent
      }
    }
  }
`;

export const createCustomPayPeriodMutation = `
  mutation newPayPeriod ($nextPayPeriodEndDate: String!) {
    createPayPeriod (nextPayPeriodEndDate: $nextPayPeriodEndDate) {
      payPeriod {
        name
        previous
        next
        isCurrent
        prettyRange
        endDate
      }
      payPeriods {
        name
        prettyRange
        isCurrent
      }
    }
  }
`;

import { validEmail, validFirstLastName } from "@budgetocity-internal/validation-library";
import { Box, Button, Link, Paper, TextField, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { authPost } from "app/api";
import { goBack } from "app/util";
import { resendAttributeConfirmation } from "auth";

import ManageIdentityConfirm from "./ManageIdentityConfirm";

//const MuiPhoneNumber = React.lazy(() => import("material-ui-phone-number"));

const ManageIdentity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const state = useSelector((state) => ({ ...state.stateManager.settings, ...state.settings }));
  const auth = useSelector((state) => ({ ...state.stateManager.auth, ...state.auth }));

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [attributeToConfirm, setAttributeToConfirm] = useState("");
  const [attributeNameToConfirm, setAttributeNameToConfirm] = useState("");
  const [attributeInstructions, setAttributeInstructions] = useState("");

  const submitIdentityChange = async (values, { setSubmitting, setErrors }) => {
    const emailAddressChanged = values.emailAddress !== auth.emailAddress;
    const phoneNumberChanged = values.phoneNumber !== auth.phoneNumber;

    dispatch({
      type: "UPDATE_IDENTITY",
      payload: authPost(auth, "/updateIdentity", {
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
      }),
    })
      .then(() => {
        setSubmitting(false);
        dispatch({ type: "SET_SNACK", success: "Complete" });
        if (emailAddressChanged) {
          verifyCode("email");
        }
        if (!emailAddressChanged && phoneNumberChanged) {
          verifyCode("phone_number");
        }
      })
      .catch((ex) => {
        setSubmitting(false);
        setErrors({ global: ex?.response?.data?.message || ex?.message || "Failed to request update" });
      });
  };

  const validateIdentityChange = (values) => {
    var errors = {};

    const emailAddressCheck = validEmail(values.emailAddress);
    if (!emailAddressCheck.passed) {
      errors["emailAddress"] = emailAddressCheck.reason;
    }

    const firstNameCheck = validFirstLastName(values.firstName);
    if (!firstNameCheck.passed) {
      errors["firstName"] = firstNameCheck.reason;
    }

    const lastNameCheck = validFirstLastName(values.lastName);
    if (!lastNameCheck.passed) {
      errors["lastName"] = lastNameCheck.reason;
    }

    return errors;
  };

  const resendCode = async (attribute) => {
    resendAttributeConfirmation(attribute)
      .then(() => {
        dispatch({ type: "SET_SNACK", success: "Complete" });
        verifyCode(attribute);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex?.response?.data?.message || ex?.message || "Unable to resend code." });
      });
  };

  const verifyCode = async (attribute) => {
    if (attribute === "email") {
      setAttributeToConfirm("email");
      setAttributeNameToConfirm("Email Address");
      setAttributeInstructions("A verification code was sent to your new email address.  Please enter it here.");

      setConfirmationOpen(true);
    } else {
      setAttributeToConfirm("phone_number");
      setAttributeNameToConfirm("Phone Number");
      setAttributeInstructions("A verification code was sent to your new phone number.  Please enter it here.");

      setConfirmationOpen(true);
    }
  };

  const confirmAttribute = async (values) => {
    const promise = dispatch({
      type: "CONFIRM_IDENTITY",
      payload: authPost(auth, "/updateIdentity/confirmation", {
        attribute: values.attribute,
        confirmationCode: values.confirmationCode,
      }),
    });

    promise.then(() => {
      setConfirmationOpen(false);
      dispatch({ type: "SET_SNACK", success: "Complete" });
    });

    return promise;
  };

  return (
    <Paper>
      <Formik
        initialValues={{
          emailAddress: auth.emailAddress,
          firstName: auth.firstName,
          lastName: auth.lastName,
        }}
        validate={validateIdentityChange}
        onSubmit={submitIdentityChange}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          isValid,
          resetForm,
          setFieldError,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Box mx={8} mb={3} display="flex" flexDirection="column" justifyContent="center">
            <Box mt={3} mb={2}>
              <Typography variant="h5" gutterBottom>
                Update Your Identity
              </Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="h6">Looking to update your profile picture?</Typography>
              <Typography variant="body1" gutterBottom>
                You can do that in <Link onClick={() => history.push("/settings")}>your profile settings</Link>.{" "}
              </Typography>
            </Box>

            <TextField
              fullWidth
              type="firstName"
              margin="normal"
              name="firstName"
              label="First Name"
              value={values.firstName}
              onChange={handleChange}
              disabled={isSubmitting}
              onBlur={handleBlur}
              error={errors.firstName && touched.firstName}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              type="lastName"
              margin="normal"
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleChange}
              disabled={isSubmitting}
              onBlur={handleBlur}
              error={errors.lastName && touched.lastName}
              helperText={touched.lastName && errors.lastName}
            />

            <TextField
              fullWidth
              type="email"
              margin="normal"
              name="emailAddress"
              label="Email Address"
              value={values.emailAddress}
              onChange={handleChange}
              disabled={isSubmitting}
              onBlur={handleBlur}
              error={errors.emailAddress && touched.emailAddress}
              helperText={touched.emailAddress && errors.emailAddress}
            />

            {!auth.emailVerified && (
              <Alert severity="warning">
                You're email address needs to be verified.
                <br />
                <br />
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{ marginRight: theme.spacing(2) }}
                  onClick={() => resendCode("email")}
                >
                  Resend Code
                </Button>
                <Button color="secondary" variant="outlined" onClick={() => verifyCode("email")}>
                  Enter Code
                </Button>
              </Alert>
            )}

            {/* <Suspense fallback={<Box />}>
              <MuiPhoneNumber
                defaultCountry={"us"}
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                disabled={isSubmitting}
                value={values.phoneNumber}
                autoComplete="phoneNumber"
                onChange={(x) =>
                  handleChange({
                    target: {
                      name: "phoneNumber",
                      value: x.replace(/[ ()-]/g, ""),
                    },
                  })
                }
                onBlur={handleBlur}
                error={errors.phoneNumber && touched.phoneNumber ? true : false}
                helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
              />
            </Suspense>

            {!auth.phoneNumberVerified && (
              <Alert severity="warning">
                You're phone number needs to be verified.
                <br />
                <br />
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{ marginRight: theme.spacing(2) }}
                  onClick={() => resendCode("phone_number")}
                >
                  Resend Code
                </Button>
                <Button color="secondary" variant="outlined" onClick={() => verifyCode("phone_number")}>
                  Enter Code
                </Button>
              </Alert>
            )} */}

            <Box hidden={!errors.global || errors.global === ""}>
              <Alert severity="error" variant="filled" onClose={() => setFieldError("global", "")}>
                {errors.global}
              </Alert>
            </Box>

            <Box ml="auto" mt={3} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  goBack(history, state.returnPage);
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting || !isValid}>
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Formik>

      <ManageIdentityConfirm
        open={confirmationOpen}
        cancel={() => setConfirmationOpen(false)}
        submitConfirm={confirmAttribute}
        attribute={attributeToConfirm}
        attributeName={attributeNameToConfirm}
        attributeInstructions={attributeInstructions}
      />
    </Paper>
  );
};

export default ManageIdentity;

import { validateHttpsAddress, validCategory, validDescription, validTags } from "@budgetocity-internal/validation-library"

const validate = values => {
  var errors = {}

  if (!validDescription(values.description.trim()).passed) {
    errors = {...errors, description: "Please enter a valid description"}
  }

  if (!values.amounts || values.amounts.length !== 1 || !values.amounts[0].category) {
    errors = {...errors, category: "Please select a category"}
  } else {
    const vCategory = validCategory(values.amounts[0].category)
    if (!vCategory.passed) {
      errors = {...errors, category: "Please select a category"}
    }
  }

  if (values.type === "DAY") {
    if (values.day === "" || values.day < 0 || values.day > 90) {
      errors = {...errors, day: "Must be 1 to 90"}
    }
  }


  if (values.amounts === "") {
    errors = {...errors, amount: "Please enter a valid amount"}
  }

  if (!validTags(values.tags).passed) {
    errors = {...errors, tags: "One of the provided tags is not acceptable"}
  }

  const link = values?.link?.trim() || ""
  if (link !== "" && !validateHttpsAddress(link).passed) {
    errors = {...errors, link: "Please enter a valid web address with https"}
  }
 
  //console.log(errors)

  return errors
}

export default validate
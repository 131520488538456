
import { reducer as snackReducer } from 'components/SnackMessage'

const DEFAULT_STATE = {
  budget: {
    potentialCarryover: 0,
    income: [],
    spending: [],
    isEmpty: false,
  },
  error: "",
  snack: { type: "", message: "" }
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "BUDGET_FULFILLED": {
      return {
        ...state,
        budget: action?.payload?.budget,
        error: ""
      }
    }
    case "BUDGET_REJECTED": {
      return {
        ...state,
        isEmpty: false, 
        error: action.payload
      } 
    }
    case "UPDATE_BUDGET_FULFILLED": {
      if (!state?.budget?.income?.[0]?.categories) {
        return state
      }
      
      const updatedBudgetItem = 
        action.payload?.updateBudgetItem ||
          action.payload?.carryOverBudgetItem

      for (const [i, c] of state.budget.income[0].categories.entries()) {
        if (c.category === updatedBudgetItem.item.category) {
          var trueIncomeState = {
            ...state,
            isEmpty: false, 
            snack: {
              type: "success",
              message: "Complete"
            }
          }
          trueIncomeState.budget.income[0].categories[i] = updatedBudgetItem.item
          return trueIncomeState
        }
      }

      for (const [i] of state.budget.spending.entries()) {
        for (const [j, c] of state.budget.spending[i].categories.entries()) {
          if (c.category === updatedBudgetItem.item.category) {
            var trueSpendingState = {
              ...state,
              isEmpty: false, 
              snack: {
                type: "success",
                message: "Complete"
              }
            }
            trueSpendingState.budget.spending[i].amount = updatedBudgetItem.parent.amount
            trueSpendingState.budget.spending[i].remaining = updatedBudgetItem.parent.remaining
            trueSpendingState.budget.spending[i].percentage = updatedBudgetItem.parent.percentage
            trueSpendingState.budget.spending[i].categories[j] = updatedBudgetItem.item
            return trueSpendingState
          }
        }
      }

      return {
        ...state,
        isEmpty: false, 
        snack: {
          type: "success",
          message: "Complete"
        }
      }
    }
    case "UPDATE_BUDGET_REJECTED": {
      return {
        ...state,
        isEmpty: false, 
        snack: {
          type: "error",
          message: action.payload
        }
      }
    }
    case "UPDATE_BUDGET_WITH_RECOMMENDATIONS_FULFILLED": {
      const budget = action?.payload?.updateBudgetWithRecommendations?.budget
      if (budget) {
        return {
          ...state,
          budget,
          isEmpty: budget?.isEmpty
        }
      }
      
      return {
        ...state, 
        snack: { type: "error", message: "Unexpected results for budget"}
      }
    }
    case "UPDATE_BUDGET_WITH_RECOMMENDATIONS_REJECTED": {
      return {
        ...state,
        snack: {
          type: "error",
          message: action.payload
        }
      }
    }
    case "CLEAR_EMPTY_BUDGET_NOTICE": {
      return {
        ...state, 
        budget: {
          ...state.budget,
          isEmpty: false
        }
      }
    }
    case "OPTIMISTIC_BUDGET_UPDATE": {
      if (!state?.budget?.income?.[0]?.categories) {
        return state
      }
      
      for (const [i, c] of state.budget.income[0].categories.entries()) {
        if (c.category === action.payload.category) {
          var newIncomeState = {...state, isEmpty: false}
          newIncomeState.budget.income[0].categories[i].amount = action.payload.amount
          return newIncomeState
        }
      }

      for (const [i] of state.budget.spending.entries()) {
        for (const [j, c] of state.budget.spending[i].categories.entries()) {
          if (c.category === action.payload.category) {
            var newSpendingState = {...state, isEmpty: false}
            newSpendingState.budget.spending[i].categories[j].amount = action.payload.amount
            return newSpendingState
          }
        }
      }

      return state
    }
    default:
      return snackReducer(state, action)
  }
}


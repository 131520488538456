import { faBalanceScale } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Fab,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AccountBalanceOutlined,
  Add,
  ArrowLeft,
  ArrowRight,
  Clear,
  EventAvailableOutlined,
  HomeOutlined,
  ReceiptOutlined,
  Search,
  Settings,
} from "@material-ui/icons";
import { Alert, Skeleton, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { query } from "app/api";
import { useAuthorizationWrite } from "auth";
import Amount from "components/Amount";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import { updateSavingsBalances } from "pages/product/Savings/queries";
import EditTransaction from "pages/product/Tracking/EditTransaction";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import AddCustomPayPeriod from "./AddCustomPayPeriod";
import {
  createCustomPayPeriodMutation,
  createPayPeriodMutation,
  newTransactionMutation,
  newTransactionQuery,
  rebalanceAccountsMutation,
  sidebarQuery,
  updateAccountBalances,
} from "./queries";
import RebalanceAccount from "./RebalanceAccount";

const Chart = React.lazy(() => import("react-google-charts"));

const AVAILABLE_COLOR = "#117733";
const CHART_COLORS = [
  "#88CCEE",
  "#44AA99",
  "#999933",
  "#DDCC77",
  "#CC6677",
  "#882255",
  "#AA4499",
  "#77AADD",
  "#EE8866",
  "#EEDD88",
  "#FFAABB",
  "#99DDFF",
  "#44BB99",
  "#BBCC33",
  "#AAAA00",
  "#DDDDDD",
];

export const EMPTY_TRANSACTION = {
  accounts: [],
  payPeriods: [],
  typeAhead: [],
  categories: [],
  settings: {
    tags: [],
  },
  transaction: {
    id: null,
    payPeriod: "",
    account: "",
    date: "",
    description: "",
    amount: null,
    amounts: [
      {
        category: null,
        amount: null,
      },
    ],
    direction: "DEBIT",
    cleared: false,
    tags: [],
  },
};

const Sidebar = ({ addPlannedEvent, addSavingsGoal, addSelectedEvents, showAddSelectedEvents }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const sidebar = useSelector((state) => ({
    ...state.stateManager.sidebar,
    ...state.sidebar,
  }));

  const canUpdatePayPeriod = useAuthorizationWrite("PayPeriod");
  const canUpdateAccount = useAuthorizationWrite("Account");
  const canUpdateTransaction = useAuthorizationWrite("Transaction");
  const canUpdatePlanner = useAuthorizationWrite("Planner");
  const canUpdateSavings = useAuthorizationWrite("Savings");

  const account = useSelector((state) => state.auth.account);
  const payPeriod = useSelector((state) => state.auth.payPeriod);
  const planner = useSelector((state) => state.planner);

  const location = useLocation();
  const pagePath = location.pathname ? location.pathname : "";

  const today = moment().format("YYYY-MM-DD");

  const isPage = (page) => pagePath.includes(`/${page}`);
  const hasPlan = sidebar.payPeriod.startDate > today;

  const showAccountBalance = !isPage("budget") && !isPage("planner");

  const showBudgetBalance = !isPage("tracking") && !isPage("savings") && !isPage("planner");
  const showSearch = isPage("tracking");

  const showEventSearch = isPage("planner");

  const [newOpen, setNewOpen] = useState(false);
  const [newTransactionWaiting, setNewTransactionWaiting] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(EMPTY_TRANSACTION);

  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const [customPayPeriodOpen, setCustomPayPeriodOpen] = useState(false);
  const [rebalanceAccountOpen, setRebalanceAccountOpen] = useState(false);

  const FINAL_COLORS =
    sidebar?.budgetBalance?.groups?.[0]?.name === "Available" ? [...[AVAILABLE_COLOR], ...CHART_COLORS] : CHART_COLORS;

  const navigateWithReturn = (destination) => {
    dispatch({ type: "RETURN_PAGE", payload: history.location.pathname });
    history.push(destination);
  };

  useEffect(() => {
    if (!sidebar.isFetching && sidebar.isDirty) {
      dispatch({
        type: "SIDEBAR",
        payload: query(auth, sidebarQuery, {
          payPeriod: payPeriod ? payPeriod : "",
          account: account ? account : "",
        }),
      });
    }
  }, [dispatch, auth, sidebar, account, payPeriod, history]);

  const updatePayPeriod = (payPeriod, account) => {
    dispatch({ type: "SIDEBAR", payload: query(auth, sidebarQuery, { payPeriod, account }) });
  };

  const createPayPeriod = () => {
    if (sidebar.settings.budgetFrequency === "CUSTOM") {
      setCustomPayPeriodOpen(true);
    } else {
      dispatch({ type: "CREATE_PAYPERIOD", payload: query(auth, createPayPeriodMutation) })
        .then((_) => {
          dispatch({ type: "SET_SNACK", success: "Complete" });
          dispatch({ type: "SIDEBAR_DIRTY" });
        })
        .catch((ex) => {
          dispatch({ type: "SET_SNACK", error: ex });
        });
    }
  };

  const createCustomPayPeriod = async (nextPayPeriodEndDate) => {
    return dispatch({
      type: "CREATE_PAYPERIOD",
      payload: query(auth, createCustomPayPeriodMutation, { nextPayPeriodEndDate }),
    }).then((_) => {
      dispatch({ type: "SET_SNACK", success: "Complete" });
      dispatch({ type: "SIDEBAR_DIRTY" });
      setCustomPayPeriodOpen(false);
    });
  };

  const newTransaction = () => {
    setNewTransactionWaiting(true);
    query(auth, newTransactionQuery)
      .then((t) => {
        setTransactionToEdit({
          ...t,
          transaction: {
            ...EMPTY_TRANSACTION.transaction,
            account: sidebar.accountBalance.account,
            payPeriod: sidebar.payPeriod.name,
            date: today,
          },
        });
        setNewOpen(true);
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      })
      .finally(() => setNewTransactionWaiting(false));
  };

  const submitTransaction = (transaction) => {
    const finalTransaction = {
      payPeriod: transaction.payPeriod,
      account: transaction.account,
      date: transaction.date,
      description: transaction.description,
      amounts: transaction.amounts,
      direction: transaction.direction,
      cleared: transaction.cleared,
      tags: transaction.tags,
    };

    const account = sidebar.accountBalance.account;
    const payPeriod = sidebar.payPeriod.name;

    const tAccount = transaction.account;
    const tPayPeriod = transaction.payPeriod;

    const updatePayPeriod = tPayPeriod < payPeriod ? tPayPeriod : payPeriod;

    return query(auth, newTransactionMutation, { input: finalTransaction }).then(async () => {
      try {
        query(auth, updateSavingsBalances, {});

        await query(auth, updateAccountBalances, { account, payPeriod: updatePayPeriod });

        if (tAccount !== account) {
          query(auth, updateAccountBalances, { account: tAccount, payPeriod: updatePayPeriod });
        }

        dispatch({ type: "SET_SNACK", success: "Complete" });
        dispatch({ type: "SIDEBAR_DIRTY" });
        setNewOpen(false);
      } catch (ex) {
        dispatch({ type: "SIDEBAR_DIRTY" });
        dispatch({ type: "SET_SNACK", error: ex });
        setNewOpen(false);
      }
    });
  };

  const rebalanceAccountSubmit = async (amount) => {
    return query(auth, rebalanceAccountsMutation, {
      payPeriod: sidebar.payPeriod.name,
      account: sidebar.accountBalance.account,
      balance: amount,
    }).then(async () => {
      dispatch({ type: "SET_SNACK", success: "Complete" });
      dispatch({ type: "SIDEBAR_DIRTY" });
      dispatch({ type: "ACCOUNTS_DIRTY" });
      setRebalanceAccountOpen(false);
    });
  };

  return (
    <Paper elevation={3}>
      {fullScreen && canUpdateTransaction && !addPlannedEvent && !addSavingsGoal && (
        <Tooltip title="New Transaction" placement="left" arrow>
          <Fab
            variant="extended"
            color="primary"
            aria-label="New Transaction"
            style={{ position: "fixed", right: theme.spacing(6), bottom: theme.spacing(8), zIndex: 190 }}
            onClick={() => newTransaction()}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Transaction
          </Fab>
        </Tooltip>
      )}

      {fullScreen && canUpdatePlanner && addPlannedEvent && !showAddSelectedEvents && (
        <Tooltip title="Add Event" placement="left" arrow>
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add Event"
            style={{ position: "fixed", right: theme.spacing(6), bottom: theme.spacing(8), zIndex: 190 }}
            onClick={() => addPlannedEvent()}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Planned Event
          </Fab>
        </Tooltip>
      )}

      {fullScreen && canUpdatePlanner && addPlannedEvent && showAddSelectedEvents && (
        <>
          <Backdrop open={speedDialOpen} style={{ zIndex: 180 }} />

          <SpeedDial
            ariaLabel="Add"
            style={{ position: "fixed", right: theme.spacing(3), bottom: theme.spacing(4), zIndex: 190 }}
            icon={<SpeedDialIcon />}
            onClose={() => setSpeedDialOpen(false)}
            onOpen={() => setSpeedDialOpen(true)}
            open={speedDialOpen}
          >
            <SpeedDialAction
              icon={<ReceiptOutlined />}
              tooltipTitle="Add Transactions"
              tooltipOpen
              onClick={() => addSelectedEvents()}
              style={{ whiteSpace: "nowrap" }}
            />
            <SpeedDialAction
              icon={<EventAvailableOutlined />}
              tooltipTitle="New Event"
              tooltipOpen
              onClick={() => addPlannedEvent()}
              style={{ whiteSpace: "nowrap" }}
            />
          </SpeedDial>
        </>
      )}

      {fullScreen && canUpdateSavings && addSavingsGoal && (
        <Tooltip title="Add Savings Goal" placement="left" arrow>
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add Savings Goal"
            style={{ position: "fixed", right: theme.spacing(6), bottom: theme.spacing(8), zIndex: 190 }}
            onClick={() => addPlannedEvent()}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Goal
          </Fab>
        </Tooltip>
      )}

      {sidebar.error && (
        <Box>
          <Alert variant="filled" severity="error">
            {sidebar.error}
          </Alert>
        </Box>
      )}

      {sidebar.skeleton && (
        <Box py={1}>
          <Skeleton variant="rect" height={45} style={{ margin: theme.spacing(2) }} />
          {showAccountBalance && (
            <>
              <Divider py={2} />
              <Skeleton variant="rect" height={30} style={{ margin: theme.spacing(2) }} />
              <Skeleton variant="rect" height={20} width="40%" style={{ margin: theme.spacing(2) }} />
              <Skeleton variant="rect" height={70} style={{ margin: theme.spacing(2) }} />
            </>
          )}
          {showBudgetBalance && (
            <>
              <Divider py={2} />
              <Skeleton variant="rect" height={30} style={{ margin: theme.spacing(2) }} />
              <Skeleton variant="rect" height={70} style={{ margin: theme.spacing(2) }} />
              <Divider py={2} />
              <Skeleton variant="circle" width={258} height={258} style={{ margin: "32px" }} />
              <Divider py={2} />
              <Skeleton variant="rect" height={220} style={{ margin: theme.spacing(2) }} />
            </>
          )}
          {showSearch && (
            <>
              <Skeleton variant="rect" height={30} style={{ margin: theme.spacing(2) }} />
            </>
          )}
          {(showSearch || showEventSearch) && (
            <>
              <Divider py={2} />
              <Skeleton variant="rect" height={45} style={{ margin: theme.spacing(2) }} />
            </>
          )}
        </Box>
      )}

      {!sidebar.error && !sidebar.skeleton && (
        <Box>
          <Box height="95px">
            <Box
              p={2}
              position="fixed"
              minWidth={{ xs: "100%", md: "320px" }}
              className="payPeriodNav"
              style={{ borderRadius: "5px" }}
            >
              <Box display="flex">
                <Box flexGrow={1}>
                  {/* RENDER NAVIGATION */}
                  <FormControl>
                    <Select
                      labelId="payPeriod-label"
                      value={sidebar.payPeriod.name ? sidebar.payPeriod.name : ""}
                      renderValue={(value) => value}
                      displayEmpty
                      style={{ fontSize: "1.4em", fontWeight: "bold" }}
                      onChange={(p) => updatePayPeriod(p.target.value, sidebar.accountBalance.account)}
                    >
                      {sidebar.payPeriods.map((p) => (
                        <MenuItem key={`pp${p.name}`} value={p.name}>{`${p.name}${p.isCurrent ? "*" : ""}: ${
                          p.prettyRange
                        }`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <ButtonGroup size="small" variant="outlined" aria-label="Pay Period Navigation">
                  <Button
                    disabled={
                      sidebar.creatingPayPeriod ||
                      newTransactionWaiting ||
                      sidebar.isFetching ||
                      !sidebar.payPeriod.previous ||
                      (isPage("planner") && !hasPlan)
                    }
                    onClick={() => updatePayPeriod(sidebar.payPeriod.previous, sidebar.accountBalance.account)}
                  >
                    <ArrowLeft />
                  </Button>
                  <Button
                    disabled={sidebar.creatingPayPeriod || newTransactionWaiting || sidebar.isFetching}
                    onClick={() => updatePayPeriod("", sidebar.accountBalance.account)}
                  >
                    <HomeOutlined />
                  </Button>
                  <Button
                    disabled={
                      sidebar.creatingPayPeriod ||
                      newTransactionWaiting ||
                      sidebar.isFetching ||
                      (!sidebar.payPeriod.next && !canUpdatePayPeriod)
                    }
                    onClick={() => {
                      if (!sidebar.payPeriod.next) {
                        createPayPeriod();
                      } else {
                        updatePayPeriod(sidebar.payPeriod.next, sidebar.accountBalance.account);
                      }
                    }}
                  >
                    {!sidebar.payPeriod.next ? <Add /> : <ArrowRight />}
                  </Button>
                </ButtonGroup>
              </Box>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="body1">{sidebar.payPeriod.prettyRange}</Typography>
                </Box>
                <Box>
                  {sidebar.payPeriod.isCurrent && (
                    <Typography variant="button" style={{ color: "#f0a" }}>
                      Current
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider p={3} />

          {/* RENDER ACCOUNT BALANCE */}
          {showAccountBalance && (
            <>
              <Box p={2}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h6" gutterBottom>
                      Account Balance
                    </Typography>
                  </Box>
                  <Box mt={0.5} hidden={!canUpdateAccount}>
                    <PopupState variant="popover" popupId={`account-balance-settings`}>
                      {(popupState) => (
                        <React.Fragment>
                          <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                            <Settings />
                          </IconButton>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => navigateWithReturn("/accounts")}>
                              <ListItemIcon>
                                <AccountBalanceOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Edit Accounts" />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                setRebalanceAccountOpen(true);
                              }}
                            >
                              <ListItemIcon>
                                <FontAwesomeIcon icon={faBalanceScale} />
                              </ListItemIcon>
                              <ListItemText primary="Reset starting balance" />
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </Box>
                </Box>
                <Box display="flex" mb={2}>
                  <FormControl>
                    <Select
                      labelId="account-label"
                      value={sidebar.accountBalance.account ? sidebar.accountBalance.account : ""}
                      onChange={(a) => updatePayPeriod(sidebar.payPeriod.name, a.target.value)}
                    >
                      {sidebar.accounts.map((a) => (
                        <MenuItem key={`account${a.name}`} value={a.name}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="body1">Starting Balance:</Typography>
                  </Box>
                  <Amount amount={sidebar.accountBalance.startingBalance} bold />
                </Box>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="body1">Bank Balance:</Typography>
                  </Box>
                  <Amount amount={sidebar.accountBalance.bankBalance} bold />
                </Box>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="body1">Current Balance:</Typography>
                  </Box>
                  <Amount amount={sidebar.accountBalance.currentBalance} bold greenGood />
                </Box>
              </Box>
              <Divider p={3} />
            </>
          )}

          {/* RENDER BUDGET BALANCE */}
          {showBudgetBalance && (
            <>
              <Box p={2}>
                <Box display="flex">
                  <Typography variant="h6">Budget Balance</Typography>
                </Box>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="body1">Income:</Typography>
                  </Box>
                  <Amount amount={sidebar.budgetBalance.income} bold />
                </Box>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="body1">Spending:</Typography>
                  </Box>
                  <Amount amount={sidebar.budgetBalance.spending} bold />
                </Box>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="body1">Difference:</Typography>
                  </Box>
                  <Amount amount={sidebar.budgetBalance.difference} bold greenGood />
                </Box>
              </Box>
              <Divider p={3} />
              {!fullScreen && sidebar.budgetBalance.income > 0 && (
                <>
                  <Box p={2}>
                    <Box display="flex">
                      <Typography variant="h6">Budget Summary</Typography>
                    </Box>
                    <Box display="flex" maxWidth={320}>
                      <Suspense
                        fallback={<Skeleton variant="circle" width={258} height={258} style={{ margin: "32px" }} />}
                      >
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          data={[
                            ["Category", "Amount"],
                            ...sidebar.budgetBalance.groups.map((x) => [x.name, x.amount / 100.0]),
                          ]}
                          formatters={[
                            {
                              type: "NumberFormat",
                              column: 1,
                              options: {
                                prefix: "$",
                                negativeColor: "red",
                                negativeParens: true,
                              },
                            },
                          ]}
                          options={{
                            legend: "none",
                            titlePosition: "none",
                            chartArea: { width: "100%", height: "80%" },
                            backgroundColor: theme.palette.background.paper,
                            titleTextStyle: {
                              color: theme.palette.text.primary,
                            },
                            pieSliceBorderColor: theme.palette.background.default,
                            pieSliceTextStyle: {
                              color: "black",
                            },
                            pieHole: 0.6,
                            slices: {
                              0: {
                                offset: sidebar?.budgetBalance?.groups?.[0]?.name === "Available" ? 0.05 : 0,
                                textStyle: {
                                  color: sidebar?.budgetBalance?.groups?.[0]?.name === "Available" ? "white" : "black",
                                },
                              },
                            },
                            colors: FINAL_COLORS,
                          }}
                        />
                      </Suspense>
                    </Box>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>Group</TableCell>
                          <TableCell className="spendingAmount">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sidebar.budgetBalance.groups.map((x, i) => (
                          <React.Fragment key={`summary${i}`}>
                            {i < 5 && (
                              <TableRow>
                                <TableCell width="16px" style={{ backgroundColor: FINAL_COLORS[i] }}></TableCell>
                                <TableCell>{x.name}</TableCell>
                                <TableCell className="spendingAmount">
                                  <Amount amount={x.amount} />
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <Divider p={3} />
                </>
              )}
            </>
          )}

          {/* RENDER SEARCH BOX */}
          {showSearch && (
            <Box p={2} mt={2}>
              <form noValidate autoComplete="off">
                <TextField
                  id="transaction-search"
                  label="Filter Transactions"
                  color="secondary"
                  variant="outlined"
                  value={sidebar.filter}
                  fullWidth
                  InputProps={{
                    startAdornment: <Search />,
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          dispatch({
                            type: "NEW_FILTER",
                            payload: "",
                          });
                        }}
                        size="small"
                      >
                        <Clear />
                      </IconButton>
                    ),
                  }}
                  onChange={({ target: { value } }) => {
                    if (value !== undefined && value !== null) {
                      dispatch({
                        type: "NEW_FILTER",
                        payload: value,
                      });
                    }
                  }}
                />
              </form>
            </Box>
          )}

          {/* RENDER EVENT SEARCH BOX */}
          {showEventSearch && (
            <Box p={2} mt={2}>
              <form noValidate autoComplete="off">
                <TextField
                  id="events-search"
                  label="Filter Planned Events"
                  color="secondary"
                  variant="outlined"
                  value={planner.filter}
                  fullWidth
                  InputProps={{
                    startAdornment: <Search />,
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          dispatch({
                            type: "EVENT_NEW_FILTER",
                            payload: "",
                          });
                        }}
                        size="small"
                      >
                        <Clear />
                      </IconButton>
                    ),
                  }}
                  onChange={({ target: { value } }) => {
                    if (value !== undefined && value !== null) {
                      dispatch({
                        type: "EVENT_NEW_FILTER",
                        payload: value,
                      });
                    }
                  }}
                />
              </form>
            </Box>
          )}

          {!fullScreen && canUpdateTransaction && !addPlannedEvent && !addSavingsGoal && (
            <Box p={2} display="flex" justifyContent="center">
              <Button color="primary" variant="contained" startIcon={<Add />} onClick={newTransaction}>
                New Transaction
              </Button>
            </Box>
          )}

          {!fullScreen && canUpdatePlanner && addPlannedEvent && (
            <Box p={2} display="flex" justifyContent="center">
              {!showAddSelectedEvents && (
                <Button color="primary" variant="contained" startIcon={<Add />} onClick={addPlannedEvent}>
                  Planned Event
                </Button>
              )}
              {showAddSelectedEvents && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={addPlannedEvent}
                    style={{ marginRight: "8px" }}
                  >
                    Planned Event
                  </Button>
                  <Button color="primary" variant="contained" startIcon={<Add />} onClick={addSelectedEvents}>
                    Transactions
                  </Button>
                </>
              )}
            </Box>
          )}

          {!fullScreen && canUpdateSavings && addSavingsGoal && (
            <Box p={2} display="flex" justifyContent="center">
              <Button color="primary" variant="contained" startIcon={<Add />} onClick={addSavingsGoal}>
                Add Savings Goal
              </Button>
            </Box>
          )}

          <EditTransaction
            open={newOpen}
            cancel={() => setNewOpen(false)}
            submit={submitTransaction}
            accounts={transactionToEdit.accounts}
            payPeriods={transactionToEdit.payPeriods}
            transaction={transactionToEdit.transaction}
            typeAhead={transactionToEdit.typeAhead}
            categories={transactionToEdit.categories}
            tags={transactionToEdit.settings.tags}
            title="New Transaction"
          />

          <AddCustomPayPeriod
            open={customPayPeriodOpen}
            nextPayPeriodStartDate={moment(sidebar.payPeriod.endDate).add(1, "day").format("YYYY-MM-DD")}
            cancel={() => setCustomPayPeriodOpen(false)}
            submit={createCustomPayPeriod}
          />

          <RebalanceAccount
            open={rebalanceAccountOpen}
            account={account}
            startingBalance={sidebar.accountBalance.startingBalance}
            cancel={() => setRebalanceAccountOpen(false)}
            submit={rebalanceAccountSubmit}
          />
        </Box>
      )}
    </Paper>
  );
};

export default Sidebar;

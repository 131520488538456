import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select, useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { deepCopy } from "app/util";
import AmountEditable from "components/AmountEditable";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import validate from "./validate";

const EditGoal = ({ open, cancel, submit, title, isEditing, goal: inputGoal, categories }) => {
  const theme = useTheme();
  const [goal, updateGoal] = useState(deepCopy(inputGoal));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (inputGoal.by) {
      updateGoal({
        ...deepCopy(inputGoal),
        isNeededBy: true,
      });
    } else {
      updateGoal({
        ...deepCopy(inputGoal),
        isNeededBy: false,
        by: moment().format("YYYY-MM-DD"),
      });
    }
  }, [inputGoal, updateGoal]);

  const submitHandler = (values, { resetForm, setSubmitting, setErrors }) => {
    const submitValue = {
      category: values.category,
      amount: values.amount,
      by: values.isNeededBy ? values.by : null,
    };

    submit(submitValue)
      .then(() => {
        resetForm();
      })
      .catch((ex) => {
        setSubmitting(false);
        setErrors({ global: ex });
      });
  };

  return (
    <Formik initialValues={goal} enableReinitialize validate={validate} onSubmit={submitHandler}>
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
          <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box mb={2}>
                <FormControl color="secondary" margin="normal" fullWidth>
                  <InputLabel id="category-label">Savings Category</InputLabel>
                  <Select
                    name="category"
                    labelId="category-label"
                    disabled={isSubmitting || isEditing}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={errors.category && touched.category}
                    value={values.category}
                  >
                    {categories.map((c, i) => (
                      <MenuItem key={`category${i}`} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2} width="100%">
                <AmountEditable
                  label="Amount"
                  emptyStart
                  amount={values.amount}
                  margin="normal"
                  disabled={isSubmitting}
                  onBlur={() => setFieldTouched("amount")}
                  onChange={(x) => {
                    setFieldValue("amount", x);
                    setTimeout(() => setFieldTouched("amount"), 2);
                  }}
                  error={errors.amount && touched.amount}
                  helperText={touched.amount && errors.amount}
                />
              </Box>

              <Box mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isNeededBy}
                      onChange={() => {
                        setFieldValue("isNeededBy", !values.isNeededBy);
                        setFieldTouched("isNeededBy");
                      }}
                      name="isNeededBy"
                      color="secondary"
                    />
                  }
                  label={
                    values.isNeededBy
                      ? "This goal is needed by the following date:"
                      : "This goal is needed by a specific date."
                  }
                />
              </Box>

              {values.isNeededBy && (
                <Box mb={2}>
                  <DatePicker
                    label="Needed By"
                    value={values.by}
                    format={"MMM DD, YYYY"}
                    margin="normal"
                    autoOk
                    onChange={(date) => {
                      setFieldValue("by", date.format("YYYY-MM-DD"));
                    }}
                    variant="inline"
                    disabled={isSubmitting}
                    error={errors.by && touched.by}
                    helperText={touched.by && errors.by}
                  />
                </Box>
              )}

              <Box hidden={!errors.global || errors.global === ""}>
                <Alert severity="error" onClose={() => setFieldError("global", "")}>
                  {errors.global}
                </Alert>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  cancel();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting || !isValid}>
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default EditGoal;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { deepCopy } from "app/util";
import { Alert } from "@material-ui/lab";
import moment from "moment";

const validate = (values) => {
  var errors = {};

  console.log(values)

  return errors;
};

const EditEvent = ({ open, cancel, submit, planDate, selectedEvents: inputSelectedEvents }) => {
  const theme = useTheme();
  const [selectedEvents, updateSelectedEvents] = useState(deepCopy(inputSelectedEvents));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const accounts = useSelector((state) => state.sidebar.accounts);
  const selectedAccount = useSelector((state) => state.sidebar.accountBalance?.account);
  const selectedPayPeriod = useSelector((state) => state.sidebar.payPeriod?.name);

  useEffect(() => {
    updateSelectedEvents(deepCopy(inputSelectedEvents));
  }, [inputSelectedEvents, updateSelectedEvents]);

  const submitHandler = (values, { resetForm, setSubmitting, setErrors }) => {
    const submitValue = selectedEvents.map((event) => ({
      payPeriod: selectedPayPeriod,
      account: values.account,
      date: values.planDate !== "TODAY" ? event.date : moment().format("YYYY-MM-DD"),
      description: event.description,
      amounts: event.amounts,
      direction: event.direction,
      cleared: false,
      tags: event.tags,
    }));

    submit(submitValue)
      .then(() => {
        resetForm();
        cancel();
      })
      .catch((ex) => {
        console.log(ex);
        setSubmitting(false);
        setErrors({ global: ex });
      });
  };

  return (
    <Formik
      initialValues={{ account: selectedAccount, planDate }}
      enableReinitialize
      validate={validate}
      onSubmit={submitHandler}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        resetForm,
        setFieldError,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="xs" fullWidth aria-labelledby="edit-dialog-title">
          <DialogTitle id="edit-dialog-title">Add Transactions</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box mb={3}>
                <Typography variant="body1">
                  You are about to add{" "}
                  <strong>
                    {selectedEvents.length} transaction{selectedEvents.length > 1 ? "s" : ""}
                  </strong>{" "}
                  to the following account:
                </Typography>
              </Box>
              <Box display="flex" mb={4}>
                <Box width="50%">
                  <FormControl color="secondary">
                    <InputLabel id="account-label">Account</InputLabel>
                    <Select
                      name="account"
                      labelId="account-label"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.account && touched.account}
                      value={values.account}
                    >
                      {accounts.map(({ name: account }) => (
                        <MenuItem value={account} key={`et-${account}`}>
                          {account}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box mb={3}>
                <Typography variant="body1">
                  ...using:
                </Typography>
              </Box>
              <Box display="flex" mb={2}>
                <Box width="50%">
                  <FormControl color="secondary">
                    <InputLabel id="account-label">Date</InputLabel>
                    <Select
                      name="planDate"
                      labelId="date-label"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.planDate && touched.planDate}
                      value={values.planDate}
                    >
                      <MenuItem value="PLAN_DATE">
                        The plan's dates
                      </MenuItem>
                      <MenuItem value="TODAY">
                        Today's date
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box hidden={!errors.global || errors.global === ""}>
                <Alert severity="error" onClose={() => setFieldError("global", "")}>
                  {errors.global}
                </Alert>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
              <Button
                onClick={() => {
                  resetForm();
                  cancel();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default EditEvent;

import { reducer as snackReducer } from 'components/SnackMessage'

const DEFAULT_STATE = {
  start: 0,
  total: 0,
  notifications: [],
  notification: null,
  error: "",
  snack: { type: "", message: "" },
  returnPage: "/",
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "RETURN_PAGE": {
      return {
        ...state,
        returnPage: action.payload
      }
    }
    case "NOTIFICATIONS_FULFILLED": {
      return {
        ...state,
        ...action.payload,
        notifications: action.payload?.notifications?.notifications || [],
        start: action.payload?.notifications?.start || 0,
        total: action.payload?.notifications?.counts?.total || 0,
        error: ""
      }
    }
    case "UPDATE_NOTIFICATION_FULFILLED": {
      return {
        ...state,
        notifications: state.notifications.map(n => {
          if (n.id === action.payload.updateNotification.notification.id) {
            return action.payload.updateNotification.notification
          } else {
            return n
          }
        }),
        error: ""
      }
    }
    case "NOTIFICATIONS_REJECTED": {
      return { 
        ...state,
        error: action.payload || "Unable to retrieve notifications"
      }
    }
    default:
      return snackReducer(state, action)
  }
}
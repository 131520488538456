export const savingsQuery = `query { 
  savings  {
    funds {
      category
      balance
      goal
      difference
      recommendation
      by
    }
    accounts {
      name
      active
      categories {
        category
        balance
      }
      balance
    }
    total
  }
  parentCategory (parentCategoryType: SAVINGS) {
    categories {
      name
    }
  }
}`

export const updateSavingsBalances = `mutation {
  updateSavingsBalances {
    total
  }
}`

export const removeSavingsGoal = `mutation removeSavingsGoal($category: String!) {
  removeSavingsGoal (category: $category)
}`

export const queryGoal = `query savingsGoal ($category: String!) {
  savingsGoal (category: $category) {
      category
      amount
      by
  }
}
`

export const queryGoalCategories = `query {
  parentCategory (parentCategoryType: SAVINGS) {
    categories {
      name
    }
  }
}
`

export const updateGoalMutation = `mutation updateSavingsGoalMutation($input: UpdateSavingsGoalInput!) {
  updateSavingsGoal(input: $input) {
    category
    balance
    goal
    difference
    recommendation
    by
  }
}
`

export const createGoalMutation = `mutation createSavingsGoalMutation($input: CreateSavingsGoalInput!) {
  createSavingsGoal(input: $input) {
    category
    balance
    goal
    difference
    recommendation
    by
  }
}
`

import { reducer as snackReducer } from "components/SnackMessage";

const DEFAULT_STATE = {
  planner: {
    events: [],
    unusedEvents: [],
    unusedYearlyEvents: [],
    filteredEvents: [],
    filteredUnusedEvents: [],
    filteredUnusedYearlyEvents: [],
    selectedIds: [],
  },
  filter: "",
  error: "",
  snack: { type: "", message: "" },
};

const reduceAmount = (x) => x.reduce((p, c) => p + c.amount, 0);

const filterEvents = (events, filter) =>
  events.filter((x) => {
    return (
      filter === "" ||
      x.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
      x.amounts[0].category.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  });

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "PLANNER_CLEAR": {
      return {
        ...state,
        planner: DEFAULT_STATE.planner,
      };
    }
    case "PLANNER_FULFILLED": {
      const planner = action?.payload?.planner;

      var balance = 0;
      const events = planner.events.map((x) => {
        balance += reduceAmount(x.amounts) * (x.direction === "DEBIT" ? -1 : 1);
        return { ...x, id: `${x.id}-${x.date}`, eventId: x.id, balance };
      });

      return {
        ...state,
        planner: {
          ...planner,
          ...action?.payload?.settings,
          events,
          filteredEvents: filterEvents(events, state.filter),
          filteredUnusedEvents: filterEvents(planner.unusedEvents, state.filter),
          filteredUnusedYearlyEvents: filterEvents(planner.unusedYearlyEvents, state.filter),
          selectedIds: [],
        },
      };
    }
    case "PLANNER_REJECTED": {
      return { ...state, error: action?.payload };
    }
    case "EVENT_NEW_FILTER": {
      const planner = state.planner;
      return {
        ...state,
        filter: action.payload,
        planner: {
          ...planner,
          filteredEvents: filterEvents(planner.events, action.payload),
          filteredUnusedEvents: filterEvents(planner.unusedEvents, action.payload),
          filteredUnusedYearlyEvents: filterEvents(planner.unusedYearlyEvents, action.payload),
        },
      };
    }
    case "REMOVE_PLANNED_EVENT_REJECTED": {
      return { ...state, snack: { type: "error", message: action?.payload } };
    }
    case "PLANNER_SELECT": {
      const id = action.payload.id;
      const value = action.payload.value;

      const events = state.planner.events.map((t) => {
        if (t.id === id) {
          return {
            ...t,
            selected: value,
          };
        }
        return t;
      });

      const selectedEvents = events.filter((t) => t.selected);
      const selectedIds = selectedEvents.map((t) => t.id);

      return {
        ...state,
        planner: {
          ...state.planner,
          events,
          filteredEvents: filterEvents(events, state.filter),
          selectedIds,
        },
      };
    }
    case "PLANNER_SELECTALL": {
      if (state.planner.events.count === 0) {
        return state;
      }

      const value = !state.planner.events[0].selected;

      const events = state.planner.events.map((t) => {
        return {
          ...t,
          selected: value,
        };
      });

      const selectedIds = value ? events.map((t) => t.id) : [];

      return {
        ...state,
        planner: {
          ...state.planner,
          events,
          filteredEvents: filterEvents(events, state.filter),
          selectedIds,
        },
      };
    }
    default:
      return snackReducer(state, action);
  }
};

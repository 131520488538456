import { Box, IconButton, LinearProgress, Paper, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { query } from "app/api";
import Confirmation from "components/Confirmation";

import AddPartnerLogin from "./AddPartnerLogin";

const DEFAULT_STATE = {
  partnerLogin: null,
  partnerLoginRequest: null,
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "PARTNERLOGIN_FULFILLED": {
      return {
        ...state,
        partnerLogin: action?.payload?.partnerLogin?.login,
        partnerLoginRequest: action?.payload?.partnerLogin?.request,
      };
    }
    case "REMOVE_PARTNERLOGIN_FULFILLED": {
      return {
        ...state,
        partnerLogin: null,
      };
    }
    default: {
      return state;
    }
  }
};

const ManagePartnerLogin = () => {
  const dispatch = useDispatch();
  //const history = useHistory();
  const [error, setError] = useState(null);
  const state = useSelector((state) => ({ ...state.stateManager.partnerlogin, ...state.partnerlogin }));
  const auth = useSelector((state) => ({ ...state.stateManager.auth, ...state.auth }));

  const [removePartnerLoginConfirmationOpen, setRemovePartnerLoginConfirmationOpen] = useState(false);
  const [removePartnerLoginRequestConfirmationOpen, setRemovePartnerLoginRequestConfirmationOpen] = useState(false);
  const [addPartnerLoginOpen, setAddPartnerLoginOpen] = useState(false);

  useEffect(() => {
    if (!state.isFetching && state.isDirty) {
      dispatch({
        type: "PARTNERLOGIN",
        payload: query(
          auth,
          `query partnerLogin { partnerLogin { login { login firstName lastName }  request { email } } }`
        ),
      });
    }
  }, [dispatch, state, auth]);

  const startAddPartnerLogin = () => {
    setAddPartnerLoginOpen(true);
  };

  const submitAddPartnerLogin = (values) => {
    return dispatch({
      type: "CREATE_PARTNERLOGIN",
      payload: query(auth, `mutation createPartnerLogin ($email: String!) { createPartnerLogin(email: $email) }`, {
        email: values.email,
      }),
    }).then(() => {
      setAddPartnerLoginOpen(false);
      dispatch({ type: "PARTNERLOGIN_DIRTY" });
      dispatch({ type: "SET_SNACK", success: "Complete" });
    });
  };

  const removePartnerLoginRequest = () => {
    setRemovePartnerLoginRequestConfirmationOpen(true);
  };

  const removePartnerLoginRequestConfirmationSubmit = () => {
    dispatch({
      type: "REMOVE_PARTNERLOGINREQUEST",
      payload: query(auth, `mutation removePartnerLoginRequest { removePartnerLoginRequest }`),
    })
      .then(() => {
        setRemovePartnerLoginRequestConfirmationOpen(false);
        dispatch({ type: "PARTNERLOGIN_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex || "Unable to remove partner login request." });
      });
  };

  const removePartnerLogin = () => {
    setRemovePartnerLoginConfirmationOpen(true);
  };

  const removePartnerLoginConfirmationSubmit = () => {
    dispatch({
      type: "REMOVE_PARTNERLOGIN",
      payload: query(auth, `mutation removePartnerLogin { removePartnerLogin }`),
    })
      .then(() => {
        setRemovePartnerLoginConfirmationOpen(false);
        dispatch({ type: "PARTNERLOGIN_DIRTY" });
        dispatch({ type: "SET_SNACK", success: "Complete" });
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex || "Unable to remove partner login." });
      });
  };

  return (
    <Paper>
      <Box mx={8} mb={3} display="flex" flexDirection="column" justifyContent="center">
        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Partner Login
          </Typography>

          <Box mb={2}>
            <Typography variant="body2">
              A partner login allows someone else to log into your account with all the same rights and privleges as
              yourself. This is useful if you would like to give your spouse or partner the ability to log into your
              account separately. With a partner login, the things you do, such as entering transactions are tracked
              separately so you can see who's doing what.
            </Typography>
          </Box>
          {state.isFetching && <LinearProgress />}
          {!state.isFetching && !state.isDirty && !state.partnerLogin && !state.partnerLoginRequest && (
            <Button startIcon={<Add />} color="primary" variant="contained" onClick={() => startAddPartnerLogin()}>
              Add Partner Login
            </Button>
          )}
          {state.partnerLoginRequest && (
            <Paper elevation={3} style={{ border: "1px solid #888" }}>
              <Box p={2}>
                <Typography variant="body2" gutterBottom>
                  You have sent a request to:
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="body1">{`${state.partnerLoginRequest.email}`}</Typography>
                  </Box>
                  <Box>
                    <IconButton onClick={() => removePartnerLoginRequest(state.partnerLoginRequest.email)}>
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Paper>
          )}
          {state.partnerLogin && (
            <Paper elevation={3} style={{ border: "1px solid #888" }}>
              <Box p={2}>
                <Typography variant="body2" gutterBottom>
                  You have enabled a partner login:
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="body1">
                      {`${state.partnerLogin.firstName} ${state.partnerLogin.lastName}`}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton onClick={() => removePartnerLogin(state.partnerLogin.login)}>
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Paper>
          )}
        </Box>

        {error && (
          <Alert severity="error" variant="filled" onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <AddPartnerLogin
          open={addPartnerLoginOpen}
          cancel={() => setAddPartnerLoginOpen(false)}
          submit={submitAddPartnerLogin}
        />

        <Confirmation
          open={removePartnerLoginConfirmationOpen}
          cancel={() => setRemovePartnerLoginConfirmationOpen(false)}
          submit={removePartnerLoginConfirmationSubmit}
          title={"Remove Partner Login"}
          message={
            "Are you sure you want to remove this partner login?  Removing the login will make it available to be used for a separate Budgetocity™ account."
          }
        />

        <Confirmation
          open={removePartnerLoginRequestConfirmationOpen}
          cancel={() => setRemovePartnerLoginRequestConfirmationOpen(false)}
          submit={removePartnerLoginRequestConfirmationSubmit}
          title={"Remove Partner Login Request"}
          message={
            "Are you sure you want to remove this partner login request?"
          }
        />
      </Box>
    </Paper>
  );
};

export default ManagePartnerLogin;

import useAnalytics from "app/useAnalytics";

const Analytics = () => {
  const analytics = useAnalytics();

  analytics.page();
  return <></>;
};

export default Analytics;

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Close, Delete, DraftsOutlined, Mail, Sync } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { query } from "app/api";
import { goBack } from "app/util";
import { useAuthorization } from "auth";
import Confirmation from "components/Confirmation";
import FetchingProgress from "components/FetchingProgress";
import Loading from "components/Loading";
import SingleLayout from "components/SingleLayout";
import SnackMessage from "components/SnackMessage";
import moment from "moment";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import {
  queryNotifications,
  removeNotification,
  removeNotifications,
  updateNotification,
  updateNotifications,
} from "./queries";

const useStyles = makeStyles((theme) => ({
  avatarRead: {
    color: theme.palette.getContrastText(grey["A400"]),
    backgroundColor: grey["A400"],
  },
  avatarUnread: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
  },
  textRead: {
    backgroundColor: theme.palette.getContrastText(grey[900]),
    fontWeight: theme.typography.fontWeightLight,
  },
  textUnread: {
    backgroundColor: theme.palette.getContrastText(grey[900]),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "120%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const PAGE_SIZE = 5;

const Notifications = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const state = useSelector((state) => ({ ...state.stateManager.notifications, ...state.notifications }));

  const [notification, setNotification] = useState({});
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationSubmit, setConfirmationSubmit] = useState(() => {});
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const classes = useStyles();

  const notificationAvailable = useAuthorization("Notification", "Partner");

  const refresh = async () => {
    const result = await dispatch({
      type: "NOTIFICATIONS",
      payload: query(auth, queryNotifications, { start: state.start, count: PAGE_SIZE }),
    });

    if (result?.value?.notifications?.count === 0) {
      const start = state.start - PAGE_SIZE;
      await dispatch({
        type: "NOTIFICATIONS",
        payload: query(auth, queryNotifications, { start: start > 0 ? start : 0, count: PAGE_SIZE }),
      });
    }
  };

  const updatePage = (page) => {
    dispatch({
      type: "NOTIFICATIONS",
      payload: query(auth, queryNotifications, { start: page * PAGE_SIZE, count: PAGE_SIZE }),
    });
  };

  const showNotification = async (notification) => {
    setNotification(notification);
    setShowNotificationDialog(true);
    await dispatch({
      type: "UPDATE_NOTIFICATION",
      payload: query(auth, updateNotification, { input: { id: notification.id, read: true } }),
    });
  };

  const markAllReadConfirmation = () => {
    setConfirmationOpen(true);
    setConfirmationMessage("Are you sure you want to mark all notifications read?");
    setConfirmationSubmit(() => markAllRead());
  };

  const markAllRead = () => async () => {
    await dispatch({
      type: "UPDATE_NOTIFICATIONS",
      payload: query(auth, updateNotifications, { read: true }),
    })
      .then(() => {
        setConfirmationOpen(false);
        refresh();
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      });
  };

  const removeAllConfirmation = () => {
    setConfirmationOpen(true);
    setConfirmationMessage("Are you sure you want to remove all notifications?");
    setConfirmationSubmit(() => removeAll());
  };

  const removeAll = () => async () => {
    await dispatch({
      type: "REMOVE_NOTIFICATIONS",
      payload: query(auth, removeNotifications),
    })
      .then(() => {
        setConfirmationOpen(false);
        refresh();
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      });
  };

  const deleteNotificationConfirmation = (id) => {
    setConfirmationOpen(true);
    setConfirmationMessage("Are you sure you want to remove this notification?");
    setConfirmationSubmit(() => deleteNotification(id));
  };

  const deleteNotification = (id) => async () => {
    await dispatch({
      type: "REMOVE_NOTIFICATION",
      payload: query(auth, removeNotification, { id }),
    })
      .then(() => {
        setConfirmationOpen(false);
        refresh();
      })
      .catch((ex) => {
        dispatch({ type: "SET_SNACK", error: ex });
      });
  };

  return (
    <SingleLayout title="Notifications" fullWidth maxWidth="sm">
      <Loading error={state.error}>
        <Box display="flex" mt={2} mb={1}>
          <Box flexGrow={1} ml={1}>
            {!fullScreen && (
              <Typography variant="h4" gutterBottom>
                Notifications
              </Typography>
            )}
          </Box>
          <Box mr={1} mt={1}>
            <Tooltip title="Mark All Read">
              <IconButton size="small" variant="contained" onClick={() => markAllReadConfirmation()}>
                <DraftsOutlined />
              </IconButton>
            </Tooltip>
          </Box>
          <Box mr={1} mt={1}>
            <Tooltip title="Remove All">
              <IconButton size="small" variant="contained" onClick={() => removeAllConfirmation()}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
          <Box mr={1} mt={1}>
            <Tooltip title="Refresh">
              <IconButton size="small" variant="contained" onClick={() => refresh()}>
                <Sync />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Paper hidden={state.error !== ""}>
          {notificationAvailable && state?.total > 0 && (
            <Box p={3}>
              <List component="nav">
                {state.notifications.map((n, i) => (
                  <ListItem key={`notification${i}`} button onClick={() => showNotification(n)}>
                    <ListItemAvatar>
                      <Avatar className={n.read ? classes.avatarRead : classes.avatarUnread}>
                        {n.read ? <DraftsOutlined /> : <Mail />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={n.subject}
                      secondary={moment(Number(n.date)).format("dddd, MMMM Do YYYY, h:mm a")}
                      primaryTypographyProps={{ style: n.read ? {} : { fontWeight: "bold" } }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => deleteNotificationConfirmation(n.id)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Pagination
                page={Math.ceil(state.start / PAGE_SIZE) + 1}
                count={Math.ceil(state.total / PAGE_SIZE)}
                shape="rounded"
                onChange={(_, page) => updatePage(page - 1)}
              />
            </Box>
          )}

          {(!notificationAvailable || state?.total === 0) && <Box p={3}>You have no notifications available.</Box>}
          <FetchingProgress isFetching={state.isDirty || state.isFetching} />
        </Paper>
        <Box mt={2} display="flex">
          <Box flexGrow={1}></Box>
          <Box mr={{ xs: 2, md: 0 }}>
            <Button color="secondary" variant="contained" onClick={() => goBack(history, state.returnPage)}>
              Close
            </Button>
          </Box>
        </Box>

        <Dialog
          open={showNotificationDialog}
          aria-labelledby="notification-dialog-title"
          aria-describedby="notification-dialog-description"
        >
          <DialogTitle id="notification-dialog-title">
            {notification?.subject}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setShowNotificationDialog(false)}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="notification-dialog-description" component="div">
              <ReactMarkdown
                children={notification?.body}
                rehypePlugins={[rehypeRaw]}
                components={{
                  a: ({ node, ...props }) => <Link {...props}></Link>,
                  center: ({ node, ...props }) => (
                    <Box display="flex" justifyContent="center">
                      {props.children}
                    </Box>
                  ),
                  button: ({ node, ...props }) => (
                    <Box mx={0.5} display="inline-block">
                      <Button {...props} onClick={() => history.push(props.onClick)}></Button>
                    </Box>
                  ),
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowNotificationDialog(false)} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Confirmation
          open={confirmationOpen}
          cancel={() => setConfirmationOpen(false)}
          submit={confirmationSubmit}
          message={confirmationMessage}
        />

        <SnackMessage dispatch={dispatch} snack={state.snack} />
      </Loading>
    </SingleLayout>
  );
};

export default Notifications;

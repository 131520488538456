import { CssBaseline } from "@material-ui/core";
import { blueGrey, green, grey, indigo, lime, red } from "@material-ui/core/colors";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";

import "./overrides.css";

const defaultTheme = createMuiTheme();

export const defaultRed = defaultTheme.palette.error.main;
export const defaultYellow = defaultTheme.palette.warning.main;
export const defaultGreen = defaultTheme.palette.success.main;

const LightCalendarTheme = React.lazy(() => import("./LightCalendar"));
const DarkCalendarTheme = React.lazy(() => import("./DarkCalendar"));

const commonTheme = {
  /* Y THO?? */
  MuiLink: {
    root: {
      cursor: "pointer"
    }
  },
  MuiAutocomplete: {
    groupLabel: {
      fontSize: "1.25em",
      marginTop: "4px",
    },
  },
  MuiSelect: {
    root: {
      "&.setupTitle": {
        fontSize: "1.5rem",
        fontWeight: "400",
        lineHeight: "1.167",
        letterSpacing: "0em",
      },
    },
  },
  MuiTableContainer: {
    root: {
      "overflow-x": "visible",
      "&.spending": {
        marginBottom: defaultTheme.spacing(6),
      },
      "&.editPlanner": {
        "overflow-y": "visible",
      },
    },
  },
  MuiTable: {
    root: {
      "&.sticky": {
        position: "relative",
      },
    },
  },
  MuiTableBody: {
    root: {},
  },
  MuiDialogContent: {
    root: {
      "&.editPlanner": {
        paddingTop: 0,
        [defaultTheme.breakpoints.down("md")]: {
          padding: 0,
        },
      },
    },
  },
  MuiTableRow: {
    root: {
      "&.total .MuiTableCell-root": {
        fontStyle: "italic",
        paddingTop: defaultTheme.spacing(2),
        paddingBottom: defaultTheme.spacing(2),
      },
    },
  },
  MuiTableCell: {
    head: {
      fontSize: "1.1em",
      padding: defaultTheme.spacing(2, 0, 2, 2),
      "&:last-child": {
        paddingRight: defaultTheme.spacing(2),
      },
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1.3em",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.4em",
      },
    },
    root: {
      "&.sticky": {
        position: "sticky",
        zIndex: 9,
        [defaultTheme.breakpoints.up("xs")]: {
          top: "156px",
        },
        [defaultTheme.breakpoints.up("md")]: {
          top: "64px",
        },
      },
      "&.sticky2": {
        position: "sticky",
        zIndex: 9,
        top: "128px",
      },
      "&.small": {
        width: "1%",
      },
      "&.center": {
        textAlign: "center",
      },
      "&.right": {
        textAlign: "right",
      },
      "&.savings.date": {
        width: "90px",
        minWidth: "90px",
        paddingRight: 0,
      },
      "&.transaction.date": {
        width: "75px",
        minWidth: "75px",
        paddingRight: 0,
      },
      "&.transaction.amount": {
        width: "1%",
        textAlign: "right",
        paddingRight: 0,
      },
      "&.transaction.enteredBy": {
        [defaultTheme.breakpoints.up("xs")]: {
          display: "none",
        },
        [defaultTheme.breakpoints.up("sm")]: {
          display: "table-cell",
          width: "1%",
        },
      },
      "&.transaction.type": {
        width: "1%",
        [defaultTheme.breakpoints.up("xs")]: {
          display: "none",
        },
        [defaultTheme.breakpoints.up("sm")]: {
          display: "table-cell",
        },
      },
      "&.transaction.period": {
        [defaultTheme.breakpoints.up("xs")]: {
          display: "none",
        },
        [defaultTheme.breakpoints.up("sm")]: {
          display: "table-cell",
        },
      },
      "&.transaction.cleared": {
        textAlign: "center",
        width: "1%",
      },
      "&.transaction.menu": {
        width: "1%",
      },
      "&.spendingStatus": {
        width: "1%",
        paddingRight: 0,
      },
      "&.spendingAmount": {
        textAlign: "right",
        width: "1%",
        paddingLeft: defaultTheme.spacing(0.5),
        paddingRight: defaultTheme.spacing(1),
      },
      "&.spendingRemaining": {
        textAlign: "right",
        width: "1%",
        paddingLeft: defaultTheme.spacing(0),
        paddingRight: defaultTheme.spacing(0.5),
      },
      "&.spendingMenu": {
        textAlign: "right",
        width: "1%",
        paddingLeft: defaultTheme.spacing(0),
        paddingRight: defaultTheme.spacing(0),
      },
      "&.editPlanner.day": {
        width: 1,
      },
    },
    body: {
      padding: defaultTheme.spacing(1, 0, 1, 2),
      "&:last-child": {
        paddingRight: defaultTheme.spacing(2),
      },
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1.1em",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.2em",
      },
    },
  },
};

const lightTheme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: {
      light: grey[600],
      main: grey[800],
      dark: grey[900],
      contrastText: grey[50],
    },
    background: {
      default: grey[300],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  overrides: {
    ...commonTheme,
    MuiAutocomplete: {
      ...commonTheme.MuiAutocomplete,
      paper: {
        background: grey[100],
      },
      groupLabel: {
        ...commonTheme.MuiAutocomplete.groupLabel,
        backgroundColor: grey[200],
        color: "#000",
      },
    },
    MuiSvgIcon: {
      root: {
        "&.shortCut": {
          color: "#ff9800"
        },
        "&.noShortCut": {
          color: "#ccc"
        }
      }
    },
    MuiChip: {
      root: {
        "&.warning": {
          color: "rgb(102, 60, 0)",
          backgroundColor: "rgb(255, 244, 229)",
          border: "1px solid #ff9800"
        },
        "&.warning .MuiChip-icon": {
          color: "#ff9800",
        },
        "&.info": {
          color: "rgb(13, 60, 97)",
          backgroundColor: "rgb(232, 244, 253)",
          border: "1px solid #2196f3"
        },
        "&.info .MuiChip-icon": {
          color: "#2196f3",
        },
      },
    },
    MuiPaper: {
      root: {
        "& .payPeriodNav": {
          backgroundColor: "#fff",
          zIndex: 50,
        },
        "&.stickyTopButtons": {
          backgroundColor: grey[300],
          border: 0,
          boxShadow: "none",
          borderRadius: 0,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: blueGrey[50],
        color: "#222",
      },
    },
    MuiTableRow: {
      ...commonTheme.MuiTableRow,
      root: {
        ...commonTheme.MuiTableRow.root,
        "&.uncleared": {
          backgroundColor: grey[100],
          "& .MuiTableCell-body": {
            color: grey[600],
          },
          fontStyle: "italic",
          "&:hover": {
            background: grey[300],
          },
        },
        "&.oldEvent": {
          backgroundColor: grey[300],
          "& .MuiTableCell-body": {
            color: grey[900],
          },
        },
        "&.income.UNEXPECTED": {
          background: "#fee",
          "&:hover": {
            background: "#edd",
          },
        },
        "&.income.MISSING": {
          background: "#ffd",
          "&:hover": {
            background: "#eec",
          },
        },
        "&.spending.MISSING": {
          background: "#fee",
          "&:hover": {
            background: "#edd",
          },
        },
        "&.spending.RECOMMENDED": {
          background: "#dfc",
          "&:hover": {
            background: "#efd",
          },
        },
        "&.spending.UNNECESSARY": {
          background: "#ffd",
          "&:hover": {
            background: "#eec",
          },
        },
      },
    },
    MuiTableCell: {
      ...commonTheme.MuiTableCell,
      head: {
        ...commonTheme.MuiTableCell.head,
        backgroundColor: grey[200],
      },
      body: {
        ...commonTheme.MuiTableCell.body,
        "&.green": {
          backgroundColor: "#dfd",
          color: green[500],
        },
        "&.yellow": {
          backgroundColor: "#ffd",
          color: "#880",
        },
        "&.red": {
          backgroundColor: "#fdd",
          color: "#800",
        },
      },
    },
  },
});

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: indigo[300],
      main: indigo[400],
      dark: indigo[500],
      contrastText: grey[50],
    },
    secondary: {
      light: grey[200],
      main: grey[300],
      dark: grey[500],
      contrastText: grey[900],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  overrides: {
    ...commonTheme,
    MuiAvatar: {
      root: {
        "& img": {
          width: "103%",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        "&.shortCut": {
          color: "#ff9800"
        },
        "&.noShortCut": {
          color: "#ccc"
        }
      }
    },
    MuiChip: {
      ...commonTheme.MuiChip,
      outlined: {
        backgroundColor: grey[900],
      },
      root: {
        "&.warning": {
          color: "rgb(255, 213, 153)",
          backgroundColor: "rgb(25, 15, 0)",
          border: "1px solid #ff9800"
        },
        "&.warning .MuiChip-icon": {
          color: "#ff9800",
        },
        "&.info": {
          color: "rgb(166, 213, 250)",
          backgroundColor: "rgb(3, 14, 24)",
          border: "1px solid #2196f3"
        },
        "&.info .MuiChip-icon": {
          color: "#2196f3",
        },
      },
    },
    MuiAutocomplete: {
      ...commonTheme.MuiAutocomplete,
      paper: {
        background: grey[900],
      },
      groupLabel: {
        ...commonTheme.MuiAutocomplete.groupLabel,
        backgroundColor: grey[700],
        color: "#fff",
      },
    },
    MuiPaper: {
      root: {
        "& .payPeriodNav": {
          backgroundColor: grey[800],
          zIndex: 50,
        },
        "&.stickyTopButtons": {
          backgroundColor: "#303030",
          border: 0,
          boxShadow: "none",
          borderRadius: 0,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: grey[800],
        color: "#fff",
      },
    },
    MuiTableRow: {
      ...commonTheme.MuiTableRow,
      root: {
        ...commonTheme.MuiTableRow.root,
        "&.uncleared": {
          backgroundColor: "#212121",
          "& .MuiTableCell-body": {
            color: grey[400],
          },
          fontStyle: "italic",
          "&:hover": {
            background: "#383838",
          },
        },
        "&.uncleared.unmatched": {
          backgroundColor: "#322121",
          "&:hover": {
            backgroundColor: "#453838",
          },
        },
        "&.cleared.unmatched": {
          backgroundColor: "#544242",
          "&:hover": {
            backgroundColor: "#644242",
          },
        },
        "&.oldEvent": {
          backgroundColor: grey[900],
          "& .MuiTableCell-body": {
            color: grey[400],
          },
        },
        "&.income.UNEXPECTED": {
          backgroundColor: "#613d36",
          "&:hover": {
            background: "#875449",
          },
        },
        "&.income.MISSING": {
          backgroundColor: "#656e24",
          "&:hover": {
            background: "#7c8729",
          },
        },
        "&.spending.MISSING": {
          backgroundColor: "#613d36",
          "&:hover": {
            background: "#875449",
          },
        },
        "&.spending.RECOMMENDED": {
          background: "#366136",
          "&:hover": {
            background: "#528752",
          },
        },
        "&.spending.UNNECESSARY": {
          backgroundColor: "#656e24",
          "&:hover": {
            background: "#7c8729",
          },
        },
      },
    },
    MuiTableCell: {
      ...commonTheme.MuiTableCell,
      head: {
        ...commonTheme.MuiTableCell.head,
        backgroundColor: grey[700],
      },
      body: {
        ...commonTheme.MuiTableCell.body,
        "&.green": {
          backgroundColor: "#377035",
          color: green[100],
        },
        "&.yellow": {
          backgroundColor: "#656e24",
          color: lime["A100"],
        },
        "&.red": {
          backgroundColor: "#613d36",
          color: red[100],
        },
      },
    },
  },
});

const Theme = ({ children }) => {
  const brightTheme = useSelector((state) => state.auth.brightTheme);
  return (
    <MuiThemeProvider theme={brightTheme ? lightTheme : darkTheme}>
      <React.Suspense fallback={<></>}>{brightTheme ? <LightCalendarTheme /> : <DarkCalendarTheme />}</React.Suspense>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default Theme;

import { Box, Button, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { query } from "app/api";

const DEFAULT_STATE = {
  removeEntireAccount: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SUBSCRIPTIONRMA_FULFILLED": {
      return {
        ...state,
        removeEntireAccount: !!action?.payload?.subscription?.removeEntireAccount
      };
    }
    default: {
      return state;
    }
  }
};

const ManageRemoveEntireAccount = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const state = useSelector((state) => ({ ...state.stateManager.subscriptionrma, ...state.subscriptionrma }));
  const auth = useSelector((state) => ({ ...state.stateManager.auth, ...state.auth }));

  useEffect(() => {
    if (!state.isFetching && state.isDirty) {
      dispatch({
        type: "SUBSCRIPTIONRMA",
        payload: query(auth, `query { subscription { removeEntireAccount }}`, {})
      }).catch((ex) => {
        setError(ex);
      });
    }
    // eslint-disable-next-line
  }, [dispatch, state]);

  const submitRemoveEntireAccount = async (cancel) => {
    await query(auth, `mutation($cancel: Boolean) { removeEntireAccount(cancel: $cancel)}`, { cancel }).catch((ex) => {
      setError(ex);
    });

    dispatch({ type: "SUBSCRIPTIONRMA_DIRTY" });
  };

  return (
    <Paper>
      <Box mx={8} mb={3} display="flex" flexDirection="column" justifyContent="center">
        <Box my={3}>
          <Typography variant="h5">Delete Your Budgetocity™ Account</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" paragraph gutterBottom>
            We'd hate to see you go, but you have the right to delete your account.
          </Typography>
          <Typography variant="body1" paragraph gutterBottom>
            If you decide to delete your account, we will mark your account for deletion.  We remove all accounts marked for deletion at the top of the hour.  If you change your mind before then, you can cancel the deletion of your account.
          </Typography>
          <Typography variant="body1" paragraph gutterBottom>
            Otherwise, we will remove all your login, your account data, remove any <strong>links</strong> to your bank accounts, and we will cancel the remainder of any subscriptions.
          </Typography>
        </Box>
        <Box mb={3}>
          {!state.removeEntireAccount && (
            <Button variant="contained" color="primary" onClick={() => submitRemoveEntireAccount(false)}>
              Delete Account
            </Button>
          )}
          {state.removeEntireAccount && (
            <Button variant="contained" color="primary" onClick={() => submitRemoveEntireAccount(true)}>
              Cancel Account Deletion
            </Button>
          )}
        </Box>

        {error && (
          <Alert severity="error" variant="filled" onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
      </Box>
    </Paper>
  );
};

export default ManageRemoveEntireAccount;

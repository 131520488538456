import {
  Avatar,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SwipeableDrawer,
  useTheme,
} from "@material-ui/core";
import {
  AccountBalanceOutlined,
  Brightness3,
  Brightness7,
  CardMembershipOutlined,
  CategoryTwoTone,
  ChevronRight,
  DateRange,
  ExitToAppOutlined,
  LockOutlined,
  SettingsOutlined,
  SupervisorAccount,
} from "@material-ui/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { baseUrl, query } from "app/api";
import { logout, useAuthorizationWrite } from "auth";

import BadgeAvatars from "./BadgeAvatars";
import Confirmation from "./Confirmation";

export const createStripePortalMutation = `mutation {
  createPortal
}`;

const NavHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const auth = useSelector((state) => state.auth);

  const header = useSelector((state) => ({
    ...state.header,
    brightTheme: state.auth.brightTheme,
  }));

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationSubmit, setConfirmationSubmit] = useState(() => {});
  const [iOSOpen, setiOSOpen] = useState(false);

  const [upgradeOpen, setUpgradeOpen] = useState(false);

  const canUpdateSettings = useAuthorizationWrite("Settings");
  const canUpdateSecurity = useAuthorizationWrite("Security");
  const canUpdateCategories = useAuthorizationWrite("Categories");
  const canUpdateAccount = useAuthorizationWrite("Account");
  const profilesAvailable = auth.login === auth.subscriber;

  const ProfileClickItem = ({ selected, text, onClick, avatar }) => {
    const fontWeight = selected ? "bolder" : "light";
    const avatarBorder = selected
      ? { border: `2px solid ${theme.palette.text.primary}` }
      : {};

    return (
      <ListItem button key={text} onClick={onClick}>
        <ListItemAvatar>
          <Avatar
            alt={`${text}`}
            src={avatar}
            variant="circular"
            style={avatarBorder}
          />
        </ListItemAvatar>
        <ListItemText
          primary={text}
          primaryTypographyProps={{ style: { fontWeight } }}
        />
      </ListItem>
    );
  };

  const MenuClickItem = ({ text, onClick, icon, id }) => {
    return (
      <ListItem
        id={id}
        button
        key={text}
        onClick={() => {
          dispatch({ type: "CLOSE_NAV_SETTINGS" });
          onClick();
        }}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  const navigateWithReturn = (destination) => {
    dispatch({ type: "RETURN_PAGE", payload: history.location.pathname });
    history.push(destination);
  };

  const viewProfile = (profile, canSelect) => {
    if (!canSelect) {
      setUpgradeOpen(true);
      return;
    }

    if (profile !== auth.profile && canSelect) {
      setConfirmationSubmit(() => switchToProfile(profile));
      setConfirmationOpen(true);
      return;
    }
  };

  const switchToProfile = (profile) => () => {
    dispatch({ type: "CLOSE_NAV_SETTINGS" });
    sessionStorage.setItem(
      `${baseUrl.cookieScope}.${auth.login}.profile`,
      profile
    );
    window.location = "/";
  };

  const customerPortal = () => {
    if (window?.webkit) {
      setiOSOpen(true);
    } else {
      query(auth, createStripePortalMutation)
        .then((response) => {
          if (response.createPortal) {
            window.location.href = response.createPortal;
            return;
          } else {
            history.push("/pricing");
          }
        })
        .catch((ex) => {
          console.log(ex || "Unable to connect to Stripe Portal");
        });
    }
  };

  return (
    <>
      <SwipeableDrawer
        open={header.settingsOpen}
        variant="temporary"
        anchor="right"
        onOpen={() => dispatch({ type: "OPEN_NAV_SETTINGS" })}
        onClose={() => dispatch({ type: "CLOSE_NAV_SETTINGS" })}
      >
        <List style={{ minWidth: theme.spacing(32) }}>
          <ListItem
            key="budgetocity"
            style={{
              marginBottom: theme.spacing(header.profileAvatar ? 1.5 : 0),
            }}
          >
            <ListItemAvatar>
              <BadgeAvatars
                alt={`${auth.firstName} ${auth.lastName}`}
                loginAvatar={header.loginAvatar}
                profileAvatar={header.profileAvatar}
                variant="circular"
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${auth.firstName} ${auth.lastName}`}
              id="customerName"
            />
            <ListItemSecondaryAction>
              <Box hidden={!header.settingsOpen}>
                <IconButton
                  edge="end"
                  aria-label="Collapse Sidebar"
                  onClick={() => dispatch({ type: "TOGGLE_NAV_SETTINGS" })}
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <List>
            {auth.profileSubscriber !== auth.subscriber && (
              <ListItem>
                <ListItemText
                  primary={
                    <div>
                      Viewing: <strong>{auth.profileSubscriber}</strong>
                    </div>
                  }
                />
              </ListItem>
            )}

            {header.profileShortcuts.length > 1 && (
              <>
                {header.profileShortcuts.map((p, i) => (
                  <ProfileClickItem
                    selected={auth.profile === p.profile}
                    key={`key${i}`}
                    text={
                      p.subscriber === auth.subscriber
                        ? p.name
                        : `${p.firstName} ${p.lastName}`
                    }
                    onClick={() => viewProfile(p.profile, p.canSelect)}
                    avatar={p.avatar}
                  />
                ))}

                <Divider />
              </>
            )}

            {profilesAvailable &&
              (auth?.features?.profiles ||
                header?.profiles?.otherProfiles?.length > 0) && (
                <MenuClickItem
                  text="Profiles"
                  onClick={() => navigateWithReturn("/profiles")}
                  icon={<SupervisorAccount />}
                />
              )}

            <MenuClickItem
              dispatch={dispatch}
              text={
                auth?.features?.profiles && profilesAvailable
                  ? "Profile Settings"
                  : "Settings"
              }
              onClick={() => navigateWithReturn("/settings")}
              icon={<SettingsOutlined />}
            />

            {canUpdateCategories && (
              <MenuClickItem
                dispatch={dispatch}
                text="Categories"
                onClick={() => navigateWithReturn("/categories")}
                icon={<CategoryTwoTone />}
              />
            )}
            {canUpdateAccount && (
              <MenuClickItem
                dispatch={dispatch}
                text="Accounts"
                onClick={() => navigateWithReturn("/accounts")}
                icon={<AccountBalanceOutlined />}
              />
            )}
            {canUpdateSettings && (
              <MenuClickItem
                dispatch={dispatch}
                text="Budget Frequency"
                onClick={() => navigateWithReturn("/budget-frequency")}
                icon={<DateRange />}
              />
            )}
            {canUpdateSecurity && (
              <MenuClickItem
                dispatch={dispatch}
                text="Security"
                onClick={() => navigateWithReturn("/security")}
                icon={<LockOutlined />}
              />
            )}
            {canUpdateSecurity && (
              <MenuClickItem
                dispatch={dispatch}
                text="Subscription"
                onClick={() => customerPortal()}
                icon={<CardMembershipOutlined />}
              />
            )}
            {header.adminLink && header.adminLink !== "" && (
              <MenuClickItem
                dispatch={dispatch}
                text="Admin"
                onClick={() => (window.location = header.adminLink)}
                icon={<SupervisorAccount />}
              />
            )}
            <MenuClickItem
              dispatch={dispatch}
              text={!header.brightTheme ? "Light Mode" : "Dark Mode"}
              onClick={() =>
                dispatch({ type: "BRIGHT_THEME", payload: !header.brightTheme })
              }
              icon={!header.brightTheme ? <Brightness7 /> : <Brightness3 />}
            />
            <Divider />
            <MenuClickItem
              dispatch={dispatch}
              text="Logout"
              id="logout"
              onClick={() => logout()}
              icon={<ExitToAppOutlined />}
            />
          </List>
        </List>

        <Confirmation
          open={confirmationOpen}
          cancel={() => setConfirmationOpen(false)}
          submit={confirmationSubmit}
          title={"Switching Profiles"}
          message={
            'Are you sure you want to switch to this profile?  If, "Yes," the application will reload.'
          }
        />

        <Confirmation
          open={upgradeOpen}
          cancel={() => setUpgradeOpen(false)}
          submit={() => customerPortal()}
          title={"Profile Not Available"}
          message={
            "You're current subscription does not support multiple profiles.  Would you like to update your subscription to access this profile?"
          }
        />
      </SwipeableDrawer>

      <Dialog
        open={iOSOpen}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          Subscription Management
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            To manage your subscription, go to Budgetocity.com
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setiOSOpen(false)} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NavHeader;

import { AppBar, Box, Button, Tab } from "@material-ui/core"
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab"
import { query } from "app/api"
import { goBack } from "app/util"
import Loading from "components/Loading"
import SingleLayout from "components/SingleLayout"
import SnackMessage from "components/SnackMessage"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import AccountLinkTab from "./AccountLinkTab"
import AccountTab from "./AccountTab"
import { queryAccounts, queryAccountsPremium } from "./queries"

const Accounts = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const auth = useSelector(state => state.auth)
  const state = useSelector(state => ({...state.stateManager.accounts, ...state.accounts}))

  const [selectedTab, setSelectedTab] = useState("1")

  const [editAccountWaiting, setEditAccountWaiting] = useState(false)

  useEffect(() => {
    if (!state.isFetching && state.isDirty) {
      dispatch({ type: "ACCOUNTS", payload: query(auth, auth?.features?.accountLink ? queryAccountsPremium : queryAccounts)})
    }
  }, [dispatch, auth, state])


  return (
    <SingleLayout fullWidth title="Accounts" maxWidth="md">
      <Loading loading={state.isDirty||state.isFetching||editAccountWaiting}>
        {state.error &&
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        }
        
        {!state.error && !auth?.features?.accountLink &&
          <AccountTab setEditAccountWaiting={setEditAccountWaiting} />
        }

        {!state.error && auth?.features?.accountLink && 
          <TabContext value={selectedTab}>
            <AppBar position="static">
              <TabList onChange={(_, tab) => setSelectedTab(tab)} aria-label="Accounts and Links">
                <Tab label="Accounts" value="1" />
                <Tab label="Account Link" value="2" />
              </TabList>
            </AppBar>
            <TabPanel value="1">
              <AccountTab setEditAccountWaiting={setEditAccountWaiting} />
            </TabPanel>
            <TabPanel value="2">
              <AccountLinkTab />
            </TabPanel>
          </TabContext>
        }
        
        <Box display="flex">
          <Box flexGrow={1}></Box>
          <Box m={{ xs: 2, md: 2 }} mb={{ xs: 8, md: 2 }}>
            <Button onClick={() => goBack(history, state.returnPage)} color="secondary">
              Done
            </Button>
          </Box>
        </Box>

        <SnackMessage dispatch={dispatch} snack={state.snack} />

      </Loading>
    </SingleLayout>
  )
}

export default Accounts
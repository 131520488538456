import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React from "react"

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SNACK": {
      if (action.success) {
        return {
          ...state,
          snack: { type: "success", message: action.success }
        }
      }
      if (action.warning) {
        return {
          ...state,
          snack: { type: "warning", message: action.warning }
        }
      }
      if (action.error) {
        return {
          ...state,
          snack: { type: "error", message: action.error }
        }
      }
      return state
    }
    case "CLEAR_SNACK": {
      return { ...state, snack: { type: "", message: "" } }
    }
    default:
      return state
  }
}

const SnackMessage = ({dispatch, snack}) => {
  if (snack.message === "") {
    return <React.Fragment/>
  } else {
    return (
      <Snackbar open={snack.message !== ""} autoHideDuration={snack.type==="success"?2000:10000} onClose={()=>dispatch({ type: "CLEAR_SNACK"})}>
        <Alert variant="filled" severity={snack.type} onClose={()=>dispatch({ type: "CLEAR_SNACK"})}>{snack.message}</Alert>
      </Snackbar>
    )
  }
}

export default SnackMessage
import { faFilter, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown, ErrorOutline, FlagTwoTone } from "@material-ui/icons";
import { useAuthorizationWrite } from "auth";
import Amount, { formatAmount } from "components/Amount";
import AmountEditable from "components/AmountEditable";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import { Balance, CarryOverSpending, Clear, Ignore, UsePlan } from "./common";

const TableSpendingSection = ({ budget, updateBudgetItem, listTransactions, carryOverSpendingItem }) => {
  const canUpdateBudget = useAuthorizationWrite("Budget");

  return (
    <React.Fragment>
      <Box ml={1} mt={3} hidden={budget.spending.length === 0}>
        <Typography variant="h4" gutterBottom>
          Spending
        </Typography>
      </Box>
      {budget.spending[0] &&
        budget.spending.map((parent, h) => (
          <TableContainer component={Paper} key={`parent-${h}`} className="spending">
            <Table className="sticky">
              <TableHead>
                <TableRow>
                  <TableCell className="sticky spendingDescription">
                    {parent.name}
                    <Hidden xsDown>{` - ${parent.percentage}%`}</Hidden>
                  </TableCell>
                  <TableCell className="sticky spendingStatus"></TableCell>
                  <TableCell className="sticky spendingAmount">Budget</TableCell>
                  <TableCell className="sticky spendingRemaining">Remaining</TableCell>
                  <TableCell className="sticky spendingMenu"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parent.categories.map((row, i) => {
                  var itemColor = "";

                  if (row.status !== "NONE") {
                    itemColor = "";
                  } else if (row.remaining < 0) {
                    itemColor = "red";
                  } else if (row.remaining > 0) {
                    itemColor = "green";
                  } else {
                    itemColor = "yellow";
                  }

                  return (
                    <TableRow key={`spending${h}-${i}`} hover className={`spending ${row.status}`}>
                      <TableCell className="spendingDescription">{row.category}</TableCell>
                      <TableCell className="spendingStatus">
                        {row.status === "RECOMMENDED" && (
                          <PopupState variant="popover" popupId={`spending-menu-${h}-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <FontAwesomeIcon icon={faPiggyBank} />
                                </IconButton>
                                {canUpdateBudget && (
                                  <Menu {...bindMenu(popupState)}>
                                    <UsePlan
                                      popupState={popupState}
                                      type="Recommendation"
                                      category={row.category}
                                      amount={row.recommendation}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                    <Ignore
                                      popupState={popupState}
                                      type="Recommendation"
                                      category={row.category}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        )}
                        {row.status === "MISSING" && (
                          <PopupState variant="popover" popupId={`spending-menu-${h}-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <ErrorOutline />
                                </IconButton>
                                {canUpdateBudget && (
                                  <Menu {...bindMenu(popupState)}>
                                    <UsePlan
                                      popupState={popupState}
                                      category={row.category}
                                      amount={row.plannedAmount}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                    <Ignore
                                      popupState={popupState}
                                      type="Plan"
                                      category={row.category}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        )}
                        {row.status === "UNNECESSARY" && (
                          <PopupState variant="popover" popupId={`spending-menu-${h}-${i}`}>
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                  <FlagTwoTone />
                                </IconButton>
                                {canUpdateBudget && (
                                  <Menu {...bindMenu(popupState)}>
                                    {row.plannedAmount > 0
                                      ? [
                                          <UsePlan
                                            popupState={popupState}
                                            key={1}
                                            category={row.category}
                                            amount={row.plannedAmount}
                                            updateBudgetItem={updateBudgetItem}
                                          />,
                                          <Ignore
                                            popupState={popupState}
                                            key={2}
                                            type="Plan"
                                            category={row.category}
                                            updateBudgetItem={updateBudgetItem}
                                          />,
                                        ]
                                      : [
                                          <Clear
                                            popupState={popupState}
                                            key={1}
                                            category={row.category}
                                            updateBudgetItem={updateBudgetItem}
                                          />,
                                          <Ignore
                                            popupState={popupState}
                                            key={2}
                                            type="Plan"
                                            category={row.category}
                                            updateBudgetItem={updateBudgetItem}
                                          />,
                                        ]}
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        )}
                      </TableCell>
                      <TableCell className="spendingAmount">
                        {canUpdateBudget ? (
                          <AmountEditable
                            id={`spending-amount-${h}-${i}`}
                            amount={row.amount}
                            onChange={(amount) => updateBudgetItem(row.category, amount)}
                          />
                        ) : (
                          <Amount amount={row.amount} />
                        )}
                      </TableCell>
                      <TableCell className={`spendingRemaining ${itemColor}`}>
                        <Amount amount={row.remaining} redBad={false} />
                      </TableCell>
                      <TableCell className="spendingMenu">
                        <PopupState variant="popover" popupId={`spending-menu-${h}-${i}`}>
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton variant="contained" size="small" {...bindTrigger(popupState)}>
                                <ArrowDropDown />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    listTransactions(row.category);
                                  }}
                                >
                                  <ListItemIcon>
                                    <FontAwesomeIcon icon={faFilter} />
                                  </ListItemIcon>
                                  <ListItemText primary="List Transactions" />
                                </MenuItem>
                                {canUpdateBudget && row.remaining !== 0 && (
                                  <Balance
                                    popupState={popupState}
                                    category={row.category}
                                    amount={row.amount - row.remaining}
                                    updateBudgetItem={updateBudgetItem}
                                  />
                                )}
                                {canUpdateBudget && (
                                  <CarryOverSpending
                                    popupState={popupState}
                                    category={row.category}
                                    carryOverSpendingItem={carryOverSpendingItem}
                                  />
                                )}
                                {canUpdateBudget && row.amount > 0 && (
                                  <Clear
                                    popupState={popupState}
                                    category={row.category}
                                    updateBudgetItem={updateBudgetItem}
                                  />
                                )}
                                {canUpdateBudget && row.plannedAmount > 0 && row.amount !== row.plannedAmount && (
                                  <UsePlan
                                    popupState={popupState}
                                    key={1}
                                    category={row.category}
                                    amount={row.plannedAmount}
                                    updateBudgetItem={updateBudgetItem}
                                  />
                                )}
                                {canUpdateBudget &&
                                  row.plannedAmount > 0 &&
                                  row.amount !== row.plannedAmount &&
                                  row.status !== "NONE" && (
                                    <Ignore
                                      popupState={popupState}
                                      key={2}
                                      type="Plan"
                                      category={row.category}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  )}
                                {canUpdateBudget && row.recommendation > 0 && row.amount !== row.recommendation && (
                                  <UsePlan
                                    popupState={popupState}
                                    key={1}
                                    type="Recommendation"
                                    category={row.category}
                                    amount={row.recommendation}
                                    updateBudgetItem={updateBudgetItem}
                                  />
                                )}
                                {canUpdateBudget &&
                                  row.recommendation > 0 &&
                                  row.amount !== row.recommendation &&
                                  row.status !== "NONE" && (
                                    <Ignore
                                      popupState={popupState}
                                      key={2}
                                      type="Recommendation"
                                      category={row.category}
                                      updateBudgetItem={updateBudgetItem}
                                    />
                                  )}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow className="total">
                  <TableCell className="spendingDescription">Total</TableCell>
                  <TableCell className="spendingStatus"></TableCell>
                  <TableCell className="spendingAmount">{formatAmount(parent.amount)}</TableCell>
                  <TableCell className="spendingRemaining">{formatAmount(parent.remaining)}</TableCell>
                  <TableCell className="spendingMenu"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </React.Fragment>
  );
};

export default TableSpendingSection;

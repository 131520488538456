import React from "react";
import { useDispatch, useSelector } from "react-redux";

import SingleLayout from "components/SingleLayout";
import SnackMessage from "components/SnackMessage";

import ManageIdentity from "./ManageIdentity";
import ManageMFA from "./ManageMFA";
import ManagePassword from "./ManagePassword";
import ManageLinkedIdentities from "./ManageLinkedIdentities";
import ManageRemoveEntireAccount from "./ManageRemoveEntireAccount";

const Security = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.settings);

  return (
    <SingleLayout fullWidth title="Security">
      <ManagePassword />
      <ManageIdentity />
      <ManageLinkedIdentities />
      <ManageMFA />
      <ManageRemoveEntireAccount />
      <SnackMessage dispatch={dispatch} snack={state.snack} />
    </SingleLayout>
  );
};

export default Security;


const DEFAULT_STATE = {
  startDate: null,
  error: null
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SETUP_FULFILLED": {
      return {
        ...state,
        startDate: action.payload?.payPeriod?.startDate,
        error: ""
      }
    }
    case "SETUP_REJECTED": {
      return {
        ...state,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}
import moment from "moment"

import { reducer as snackReducer } from 'components/SnackMessage'

const DEFAULT_STATE = {
  hasFuturePayPeriods: false,
  isFirst: false,
  settings: {
    budgetFrequency: "MONTHLY",
    startDate: "1970-01-01",
    nextStartDate: "1970-01-01",
    day: 1,
  },
  isRemoving: false,
  error: null,
  returnPage: "/",
  snack: { type: "", message: "" }
}

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "RETURN_PAGE": {
      return {
        ...state,
        returnPage: action.payload
      }
    }
    case "BUDGETFREQUENCY_FULFILLED": {
      const payPeriod = action?.payload?.payPeriod
      return {
        ...state,
        settings: {
          budgetFrequency: action?.payload?.settings?.budgetFrequency,
          startDate: payPeriod.startDate,
          nextStartDate: moment(payPeriod.startDate).add(payPeriod.duration, "days").format("YYYY-MM-DD")
        },
        isFirst: payPeriod.isFirst,
        hasFuturePayPeriods: action?.payload?.settings?.hasFuturePayPeriods,
        error: null
      }
    }    
    case "UPDATE_BUDGETFREQUENCY_FULFILLED": {
      const payPeriod = action?.payload?.updateBudgetFrequency?.payPeriod

      return {
        ...state,
        settings: {
          budgetFrequency: action?.payload?.updateBudgetFrequency?.budgetFrequency,
          startDate: payPeriod.startDate,
          nextStartDate: moment(payPeriod.startDate).add(payPeriod.duration, "days").format("YYYY-MM-DD")
        },
        error: null
      }
    }
    case "REMOVE_FUTUREPAYPERIODS_PENDING": {
      return {
        ...state,
        isRemoving: true
      }
    }
    case "REMOVE_FUTUREPAYPERIODS_FULFILLED": {
      return {
        ...state,
        isRemoving: false
      }
    }
    case "PAYPERIODS_REJECTED":
    case "REMOVE_FUTUREPAYPERIODS_REJECTED":
    case "UPDATE_BUDGETFREQUENCY_REJECTED": {
      return {
        ...state,
        error: action?.payload,
        isRemoving: false
      }
    }
    default:
      return snackReducer(state, action)
  }
}
import { validAccount } from "@budgetocity-internal/validation-library"

const validate = (values) => {
  var errors = {}

  if (!validAccount(values.name).passed) {
    errors = {...errors, name: "Please enter a valid account name"}
  }

  if (values.startingBalance < 0) {
    errors = {...errors, startingBalance: "Starting Balance must be positive"}
  }

  if (values.buffer < 0) {
    errors = {...errors, startingBalance: "Buffer must be positive"}
  }

  return errors
}

export default validate
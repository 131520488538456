import moment from "moment"

const validate = values => {
  var errors = {}

  if (values.amount === "") {
    errors = {...errors, amount: "Please enter a valid amount"}
  }

  if (values.isNeededBy && moment(values.by).diff(moment())  <= 0) {
    errors = {...errors, by: "The \"Needed By\" date must be in the future"}
  }

  return errors
}

export default validate
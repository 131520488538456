import { applyMiddleware, combineReducers, createStore } from "redux";
import logging from "redux-logger";
import { createPromise } from "redux-promise-middleware";
import thunk from "redux-thunk";

import stateManager from "app/stateManager";
import { reducer as auth } from "auth/index";
import { reducer as header } from "components/Navigation";
import { reducer as sidebar } from "components/Sidebar/reducer";
import { reducer as products } from "pages/misc/Pricing/reducer";
import { reducer as setup } from "pages/misc/Setup/reducer";
import { reducer as budget } from "pages/product/Budget/reducer";
import { reducer as notifications } from "pages/product/Notifications/reducer";
import { reducer as planner } from "pages/product/Planner/reducer";
import { reducer as savings } from "pages/product/Savings/reducer";
import { reducer as searchcriteria} from "pages/product/Search/searchCriteriaReducer";
import { reducer as searchresults} from "pages/product/Search/searchResultsReducer";
import { reducer as summary } from "pages/product/Summary/reducer";
import { reducer as tracking } from "pages/product/Tracking/reducer";
import { reducer as accounts } from "pages/settings/Accounts/reducer";
import { reducer as budgetfrequency } from "pages/settings/BudgetFrequency/reducer";
import { reducer as categories } from "pages/settings/Categories/reducer";
import { reducer as profiles } from "pages/settings/Profiles/reducer";
import { reducer as mfa } from "pages/settings/Security/ManageMFA";
import { reducer as partnerlogin } from "pages/settings/Security/ManagePartnerLogin";
import { reducer as settings } from "pages/settings/Settings/reducer";
import { reducer as subscriptionrma } from "pages/settings/Security/ManageRemoveEntireAccount";

const states = {
  sidebar,
  header,
  summary,
  notifications,
  planner,
  budget,
  savings,
  tracking,
  settings,
  profiles,
  categories,
  accounts,
  budgetfrequency,
  searchcriteria,
  searchresults,
  setup,
  products,
  mfa,
  partnerlogin,
  subscriptionrma
};

const promise = createPromise({ types: { fulfilled: "success" } });
const middleware = [promise, thunk];
if (process.env.NODE_ENV !== "production") {
  middleware.push(logging);
}

const store = createStore(
  combineReducers({
    ...{ stateManager: stateManager(Object.keys(states)) },
    ...{ auth },
    ...states,
  }),
  applyMiddleware.apply(applyMiddleware, middleware)
);

export default store;

import { Box, Button, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { authPost } from "app/api";

const ManageLinkedIdentities = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => ({ ...state.stateManager.auth, ...state.auth }));
  const [error, setError] = useState(null);

  const unlinkGoogle = async () => {
    dispatch({
      type: "FEDERATION_UNLINK",
      payload: authPost(auth, "/federation/unlink")
    }).catch(ex => {
      setError("Failed to unlink Google");
    });
  };

  if ((auth?.identities?.length || 0) === 0) {
    return <></>;
  }

  return (
    <Paper>
      <Box mx={8} mb={3} display="flex" flexDirection="column" justifyContent="center">
        <Box mt={3} mb={2}>
          <Typography variant="h5" gutterBottom>
            Your Social Logins
          </Typography>
        </Box>

        <Box mb={4}>
          <Button color="primary" variant="contained" onClick={() => unlinkGoogle()}>
            Unlink Google
          </Button>
        </Box>

        <Box hidden={!error}>
          <Alert severity="error" variant="filled" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Box>
      </Box>
    </Paper>
  );
};

export default ManageLinkedIdentities;

import { Backdrop, Box, createMuiTheme, CssBaseline, ThemeProvider, Typography } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import React from 'react'

const theme = createMuiTheme({
  palette: {
    primary: {main: "#fff"}
  },
})

const StopPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop open={true}>
        <Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Warning color="primary"/>
            </Box>
            <Box>
              <Typography color="primary" variant="h5">
                There was a problem logging you in.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography color="secondary" variant="h5">
              Halting login process!
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </ThemeProvider>
  )
}

export default StopPage
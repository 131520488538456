import { Typography } from '@material-ui/core'
import React from 'react'

import { defaultGreen, defaultRed, defaultYellow } from 'components/Theme'

export const rawFormat = x => (x / 100.0).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")

export const formatAmount = amount => {
  const dollarSign = ("$")

  if (amount < 0) {
    return "(" + dollarSign + rawFormat(-1 * amount) + ")"
  }

  return dollarSign + rawFormat(amount)
}

const pickColor = (amount, zeroWarning=false, redBad=false, greenGood=false) => {
  if (amount < 0 && redBad) {
    return defaultRed
  }

  if (amount > 0 && greenGood) {
    return defaultGreen
  }
   
  if (amount === 0 && zeroWarning) {
    return defaultYellow
  }
  return null
}

const Amount = ({amount, bold=false, zeroWarning=false, redBad=true, greenGood=false, style={}}) => {
  const fontWeight = bold ? "bold" : "regular"

  const displayAmount = formatAmount(amount)
  const color = pickColor(amount, zeroWarning, redBad, greenGood)
  
  return <Typography style={{...style, fontWeight, color}}>{displayAmount}</Typography>
}

export default Amount
import { faArrowRight, faBalanceScale, faCheckDouble, faEraser, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon, ListItemText, MenuItem } from "@material-ui/core"
import { useAuthorizationWrite } from "auth"
import { formatAmount } from "components/Amount"
import React from "react"

export const UsePlan = React.forwardRef(
  ({popupState, updateBudgetItem, category, amount, type="Plan"}, ref) => {
    const canUpdateBudget = useAuthorizationWrite("Budget")
    return (
      <MenuItem innerRef={ref} onClick={() => {canUpdateBudget && updateBudgetItem(category, amount); popupState.close()}}>
        <ListItemIcon><FontAwesomeIcon icon={faCheckDouble} /></ListItemIcon>
        <ListItemText primary={`${canUpdateBudget ? "Use ": ""}${type}: ${formatAmount(amount)}`}/>
      </MenuItem>
    )
  }
)

export const Ignore = React.forwardRef(
  ({popupState, updateBudgetItem, type, category}, ref) => {
    const canUpdateBudget = useAuthorizationWrite("Budget")

    return canUpdateBudget &&
      <MenuItem innerRef={ref} onClick={() => {updateBudgetItem(category, undefined, true); popupState.close()}}>
        <ListItemIcon><FontAwesomeIcon icon={faEyeSlash} /></ListItemIcon>
        <ListItemText primary={`Ignore ${type}`}/>
      </MenuItem>
  }
)

export const Clear = React.forwardRef(
  ({popupState, updateBudgetItem, category}, ref) => {
    const canUpdateBudget = useAuthorizationWrite("Budget")

    return canUpdateBudget && 
      <MenuItem innerRef={ref} onClick={() => { updateBudgetItem(category, 0); popupState.close()}}>
        <ListItemIcon><FontAwesomeIcon icon={faEraser} /></ListItemIcon>
        <ListItemText primary="Clear"/>
      </MenuItem>
  }
)

export const Balance = React.forwardRef(
  ({popupState, updateBudgetItem, category, amount}, ref) => {
    const canUpdateBudget = useAuthorizationWrite("Budget")

    return canUpdateBudget && 
      <MenuItem innerRef={ref} onClick={() => {updateBudgetItem(category, amount); popupState.close()}}>
        <ListItemIcon><FontAwesomeIcon icon={faBalanceScale} /></ListItemIcon>
        <ListItemText primary="Balance"/>
      </MenuItem>
  }
)

export const CarryOver = React.forwardRef(
  ({popupState, updateBudgetItem, category, amount}, ref) => {
    const canUpdateBudget = useAuthorizationWrite("Budget")

    return (
      <MenuItem innerRef={ref} onClick={() => {canUpdateBudget && updateBudgetItem(category, amount); popupState.close()}}>
        <ListItemIcon><FontAwesomeIcon icon={faArrowRight} /></ListItemIcon>
        <ListItemText primary={`Carry Over: ${formatAmount(amount)}`}/>
      </MenuItem>
    )
  }
)

export const CarryOverSpending = React.forwardRef(
  ({popupState, carryOverSpendingItem, category}, ref) => {
    const canUpdateBudget = useAuthorizationWrite("Budget")

    return canUpdateBudget && 
      <MenuItem innerRef={ref} onClick={() => {carryOverSpendingItem(category); popupState.close()}}>
        <ListItemIcon><FontAwesomeIcon icon={faArrowRight} /></ListItemIcon>
        <ListItemText primary={`Carry Over`}/>
      </MenuItem>
  }
)

export const summaryQuery = `query { 
  summary {
    budgetRemaining {
      category
      amount
      remaining
      percentage
    }
    alerts {
      category
      amount
      remaining  
    }
  }
  payPeriod {
    name
    prettyRange
    startDate
    endDate
  }
}`

export const balancesQuery = `query {
  accountBalances {
    account
    currentBalance
  }
  budgetBalance {
    difference
  }
}`

export const querySummaryForEdit = `query { 
  summary {
    budgetRemaining {
      category
    }
  }
  categories {
    name
    type
    categories {
      name
    }
  }

}`

export const updateSummaryMutation = `mutation ($summary: [String!]!) {
  updateSummary(summary: $summary) {
    summary
  }
}`